@charset "UTF-8";
/*-----------------------------------------------------------

  Template Name: Tero - Interior Design Html5 & Css3 Web Site Template
  Template URI: https://www.templatemonster.com/authors/garantiwebt/
  Description: Tero - Interior Design Html5 & Css3 Web Site Template
  Author: adem özdamar
  Author URI: https://www.templatemonster.com/authors/garantiwebt/
  Version: 1.0.0

-------------------------------------------------------------*/
/*
0 - 600   : Telefon
600 - 900 : Tablet
900-1200  : Table büyük versiyon
1000 ve üzeri: Büyük masaüstü
1200 - 1800  : Normal stiller için
*/
@import url("https://fonts.googleapis.com/css2?family=Barlow&amp;display=swap");
.h-yazi-ortalama {
  text-align: center; }
  @media only screen and (max-width: 40em) {
    .h-yazi-ortalama {
      text-align: center;
      margin-left: 0;
      padding: 3rem; } }
  @media only screen and (max-width: 56.25em) {
    .h-yazi-ortalama {
      text-align: center;
      margin-left: 0;
      padding: 3rem; } }

.h-yazi-solda {
  text-align: left; }

.h-yazi-ozel {
  text-align: center;
  margin-left: -120px; }

.h-yazi-margin-kucuk {
  margin-bottom: 1.5rem !important; }

.h-yazi-margin-kucuk {
  margin-bottom: -1rem; }

.h-yazi-margin-kucuk1 {
  margin-bottom: -1rem; }

.buton-ortalama-blog {
  margin-top: 20rem;
  text-align: center; }

.buton-ortalama-team {
  margin-top: 13rem;
  text-align: center; }

.h-yazi-margin-orta {
  margin-bottom: 4rem !important; }
  @media only screen and (max-width: 56.25em) {
    .h-yazi-margin-orta {
      margin-bottom: 3rem !important; } }

.h-yazi-margin-buyuk {
  margin-bottom: 3rem !important; }
  @media only screen and (max-width: 56.25em) {
    .h-yazi-margin-buyuk {
      margin-bottom: -5rem !important; } }

.h-yazi-margin-buyuk-2 {
  margin-bottom: 12rem !important; }
  @media only screen and (max-width: 56.25em) {
    .h-yazi-margin-buyuk-2 {
      margin-bottom: -5rem !important; } }

.h-yazi-margin-buyuk-3 {
  margin-top: -2.5rem; }
  @media only screen and (max-width: 56.25em) {
    .h-yazi-margin-buyuk-3 {
      margin-top: -2.5rem; } }

.h-yazi-margin-buyuk-4 {
  margin-bottom: 12rem; }
  @media only screen and (max-width: 56.25em) {
    .h-yazi-margin-buyuk-4 {
      margin-bottom: 3rem; } }

.h-yazi-margin-orta-2 {
  margin-bottom: -5rem !important; }
  @media only screen and (max-width: 56.25em) {
    .h-yazi-margin-orta-2 {
      margin-bottom: 8rem !important; } }

.h-yazi-margin-orta-3 {
  margin-bottom: 1rem !important;
  text-align: center; }
  @media only screen and (max-width: 56.25em) {
    .h-yazi-margin-orta-3 {
      margin-bottom: 3rem !important;
      text-align: center; } }

.h-yazi-margin-client {
  margin-bottom: 8rem !important;
  text-align: center; }
  @media only screen and (max-width: 56.25em) {
    .h-yazi-margin-client {
      margin-bottom: 3rem !important;
      text-align: center; } }

.h-yazi-margin-orta-4 {
  margin-bottom: 1rem !important; }
  @media only screen and (max-width: 56.25em) {
    .h-yazi-margin-orta-4 {
      margin-bottom: -3rem !important; } }

.h-yazi-margin-5 {
  margin-top: 10rem; }
  @media only screen and (max-width: 56.25em) {
    .h-yazi-margin-5 {
      margin-top: -20rem; } }

.h-yazi-margin-50 {
  margin-top: 0rem; }
  @media only screen and (max-width: 56.25em) {
    .h-yazi-margin-50 {
      margin-top: 1rem; } }

.h-yazi-margin-4 {
  margin-top: 2rem;
  text-align: center; }
  @media only screen and (max-width: 56.25em) {
    .h-yazi-margin-4 {
      margin-top: 1rem;
      text-align: center; } }

.h-yazi-margin-altta {
  margin-top: 40.5rem; }
  @media only screen and (max-width: 56.25em) {
    .h-yazi-margin-altta {
      margin-top: 50rem; } }

.h-yazi-margin-ozel {
  margin-bottom: 4rem !important; }
  @media only screen and (max-width: 56.25em) {
    .h-yazi-margin-ozel {
      margin-bottom: 3rem !important; } }

.h-yazi-margin-ustte-buyuk {
  margin-top: 8rem; }

.h-yazi-margin-ustte-enbuyuk {
  margin-top: 10rem; }
  @media only screen and (max-width: 56.25em) {
    .h-yazi-margin-ustte-enbuyuk {
      margin-top: 1rem; } }

.h-yazi-margin-kucuk-1 {
  margin-top: -1rem; }
  @media only screen and (max-width: 812px) {
    .h-yazi-margin-kucuk-1 {
      margin-left: 11rem; } }
  @media only screen and (max-width: 375px) {
    .h-yazi-margin-kucuk-1 {
      margin-left: 16rem; } }
  @media only screen and (max-width: 56.25em) {
    .h-yazi-margin-kucuk-1 {
      margin-top: 1rem;
      text-align: center; } }

.h-yazi-margin-kucuk-21 {
  margin-top: -1rem; }
  @media only screen and (max-width: 56.25em) {
    .h-yazi-margin-kucuk-21 {
      margin-top: 1rem;
      text-align: center;
      width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap; } }

.h-yazi-margin-5 {
  margin-top: 5rem;
  text-align: center; }
  @media only screen and (max-width: 56.25em) {
    .h-yazi-margin-5 {
      margin-top: -15rem;
      text-align: center; } }

.bosluk-kaldir {
  margin-top: -2rem; }
  @media only screen and (max-width: 56.25em) {
    .bosluk-kaldir {
      margin-top: -2rem; } }

.bosluk-kaldir2 {
  margin-top: -4rem; }
  @media only screen and (max-width: 56.25em) {
    .bosluk-kaldir2 {
      margin-top: -2rem; } }

.h-yazi-margin-st {
  margin-top: 5rem;
  text-align: center; }
  @media only screen and (max-width: 56.25em) {
    .h-yazi-margin-st {
      margin-top: 5rem;
      text-align: center; } }

.h-yazi-margin-55 {
  margin-top: -1rem;
  text-align: center;
  padding: 35px; }
  @media only screen and (max-width: 56.25em) {
    .h-yazi-margin-55 {
      margin-top: -15rem;
      text-align: center; } }

.h-yazi-margin-6 {
  margin-top: 5rem;
  margin-bottom: 5rem; }
  @media only screen and (max-width: 56.25em) {
    .h-yazi-margin-6 {
      margin-top: -2rem; } }

a {
  text-decoration: none;
  color: #fff; }

.container {
  padding: 0rem;
  margin: 0 auto; }

@media only screen and (max-width: 56.25em) {
  .bosluk {
    margin-top: -40rem; } }
@media only screen and (max-width: 40em) {
  .bosluk {
    margin-top: -40rem; } }

.bosluk1 {
  margin-top: 13rem; }
  @media only screen and (max-width: 56.25em) {
    .bosluk1 {
      margin-top: 10rem; } }
  @media only screen and (max-width: 40em) {
    .bosluk1 {
      margin-top: 10rem; } }

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  font-size: 62.5%;
  scroll-behavior: smooth; }
  @media only screen and (max-width: 75em) {
    html {
      font-size: 56.25%; } }
  @media only screen and (max-width: 56.25em) {
    html {
      font-size: 50%; } }
  @media only screen and (max-width: 40em) {
    html {
      font-size: 30%; } }
  @media only screen and (min-width: 112.5em) {
    html {
      font-size: 75%; } }

body {
  box-sizing: border-box;
  padding: 3rem;
  background: #fff; }
  @media only screen and (max-width: 56.25em) {
    body {
      padding: 0; } }

::selection {
  background-color: #292929;
  color: #fff; }

::-webkit-scrollbar-thumb {
  background: #292929; }

::-webkit-scrollbar {
  background: #fff;
  width: .8rem;
  height: .8rem; }

#pointer-dot {
  left: 0;
  top: 0;
  margin-top: -.5rem;
  margin-left: -.3rem;
  width: 0;
  height: 0;
  border: 2.5px solid #b29a7b;
  position: fixed;
  border-radius: .4rem;
  pointer-events: none;
  transition: border-color 0.5s;
  z-index: 9999; }

#pointer-ring {
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  padding: 1.5rem;
  border: .2rem solid #f1f1f1;
  position: fixed;
  border-radius: 10rem;
  pointer-events: none;
  z-index: 9999; }

.hakkimizda-bolumu-anasayfa {
  padding: 0rem 0 6rem;
  background-color: #fff;
  margin-top: -6rem; }
  @media only screen and (max-width: 56.25em) {
    .hakkimizda-bolumu-anasayfa {
      padding: 99rem 0 1rem;
      margin-top: -50rem; } }
  @media only screen and (max-width: 56.25em) {
    .hakkimizda-bolumu-anasayfa {
      padding: 18rem 0 14rem; } }

.hakkimizda-bolumu-a {
  padding: 4rem 0 10rem;
  background-color: #fff;
  margin-top: -6rem; }
  @media only screen and (max-width: 56.25em) {
    .hakkimizda-bolumu-a {
      padding: 99rem 0 1rem;
      margin-top: -50rem; } }
  @media only screen and (max-width: 56.25em) {
    .hakkimizda-bolumu-a {
      padding: 18rem 0 14rem; } }

.quality-bolumu-anasayfa {
  padding: 1rem 0 6rem;
  background-color: #fff;
  margin-top: -6rem; }
  @media only screen and (max-width: 56.25em) {
    .quality-bolumu-anasayfa {
      padding: 99rem 0 1rem;
      margin-top: -50rem; } }
  @media only screen and (max-width: 56.25em) {
    .quality-bolumu-anasayfa {
      padding: 0rem 0 44rem;
      margin-top: -24rem; } }

.team-section {
  padding: 1rem 0 6rem;
  background-color: rgba(255, 255, 255, 0);
  margin-top: -9rem; }
  @media only screen and (max-width: 56.25em) {
    .team-section {
      padding: 99rem 0 1rem;
      margin-top: -50rem; } }
  @media only screen and (max-width: 56.25em) {
    .team-section {
      padding: 0rem 0 44rem;
      margin-top: -24rem; } }

.hakkimizda-bolumu-hakkimizda {
  padding: 5rem 0 1rem;
  background-color: #fff; }
  @media only screen and (max-width: 56.25em) {
    .hakkimizda-bolumu-hakkimizda {
      padding: 0rem 0;
      margin-top: -55rem; } }
  @media only screen and (max-width: 56.25em) {
    .hakkimizda-bolumu-hakkimizda {
      padding: 0rem 0;
      margin-top: -75rem; } }

.ozellika {
  padding: 5rem 0 10rem;
  background-color: #fff; }
  @media only screen and (max-width: 56.25em) {
    .ozellika {
      padding: 10rem 0 21rem;
      margin-top: -25rem; } }
  @media only screen and (max-width: 56.25em) {
    .ozellika {
      padding: 10rem 0 21rem;
      margin-top: -25rem; } }

.count-bolumu {
  padding: 0rem 0 0rem;
  background-color: #141414; }
  @media only screen and (max-width: 56.25em) {
    .count-bolumu {
      padding: 99rem 0 1rem;
      margin-top: -50rem; } }
  @media only screen and (max-width: 56.25em) {
    .count-bolumu {
      padding: 0rem 0 1rem;
      margin-top: -55rem; } }

.hata-404 {
  padding: 5rem 0 5rem;
  background-color: #fff; }
  @media only screen and (max-width: 56.25em) {
    .hata-404 {
      padding: 0rem 0;
      margin-top: 20vh; } }
  @media only screen and (max-width: 56.25em) {
    .hata-404 {
      padding: 0rem 0;
      margin-top: -20vh; } }

.hizmetler-alani {
  padding: 6rem 0 5rem;
  background-image: linear-gradient(to right bottom, #fff, #fbfbfb); }
  @media only screen and (max-width: 56.25em) {
    .hizmetler-alani {
      padding: 10rem 0 17rem;
      margin-top: -20rem; } }

.hizmetler-aciklama {
  padding: 6rem 0 5rem;
  background-image: linear-gradient(to right bottom, #fff, #fbfbfb); }
  @media only screen and (max-width: 56.25em) {
    .hizmetler-aciklama {
      padding: 10rem 0 17rem; } }

  @media only screen and (max-width: 56.25em) {
    .services1 {
      padding: 1rem 0; } }

.hizmetlerr-bolumu {
  padding: 4rem 0 6rem;
  background-color: #fff;
  margin-top: -6rem; }
  @media only screen and (max-width: 56.25em) {
    .hizmetlerr-bolumu {
      padding: 99rem 0 1rem;
      margin-top: -37rem; } }
  @media only screen and (max-width: 56.25em) {
    .hizmetlerr-bolumu {
      padding: 37rem 0 14rem; } }

.services-it {
  padding: 5rem 0 5rem;
  background: #ffffff; }
  @media only screen and (max-width: 56.25em) {
    .services-it {
      padding: 1rem 0; } }

.services-s {
  padding: 5rem 0 5rem;
  background: #ffffff; }
  @media only screen and (max-width: 56.25em) {
    .services-s {
      padding: 1rem 0; } }

.services2 {
  padding: 5rem 0 5rem;
  background-color: #e72121; }
  @media only screen and (max-width: 56.25em) {
    .services2 {
      padding: 10rem 0; } }

.services3 {
  padding: 0rem 0 5rem;
  background-color: #ffffff; }
  @media only screen and (max-width: 56.25em) {
    .services3 {
      padding: 10rem 0; } }

.services4 {
  padding: 5rem 0 5rem;
  background-color: #ffffff; }
  @media only screen and (max-width: 56.25em) {
    .services4 {
      padding: 10rem 0; } }

.banner-sc {
  padding: 14rem 0 30rem;
  background-color: #ffffff; }
  @media only screen and (max-width: 56.25em) {
    .banner-sc {
      padding: 1rem 0; } }

.yorumlar-alani {
  padding: 5rem 0 5rem;
  background-color: #fff; }
  @media only screen and (max-width: 56.25em) {
    .yorumlar-alani {
      padding: 10rem 0; } }

.post-alani-sayfa {
  padding: 5rem 0 7rem;
  background: #fff; }
  @media only screen and (max-width: 56.25em) {
    .post-alani-sayfa {
      padding: 10rem 0; } }

.services-top {
  padding: 8rem 0 8rem;
  background-color: #fff; }
  @media only screen and (max-width: 56.25em) {
    .services-top {
      padding: 1rem 0;
      margin-top: -10rem; } }

.info-top {
  padding: 8rem 0 4rem;
  background-color: #fff; }
  @media only screen and (max-width: 56.25em) {
    .info-top {
      padding: 1rem 0;
      margin-top: -10rem; } }

.analysis-section {
  padding: 8rem 0 8rem;
  background-color: #fff; }
  @media only screen and (max-width: 56.25em) {
    .analysis-section {
      padding: 1rem 0; } }

.info-section-2 {
  padding: 13rem 0 8rem;
  background-color: #fff; }
  @media only screen and (max-width: 56.25em) {
    .info-section-2 {
      padding: 1rem 0; } }

.paketler-alani {
  padding: 6rem 0 11rem;
  background-color: #fff; }
  @media only screen and (max-width: 56.25em) {
    .paketler-alani {
      padding: 1rem 0; } }

.paketler-alani-about {
  padding: 6rem 0 5rem;
  background-color: #fff; }
  @media only screen and (max-width: 56.25em) {
    .paketler-alani-about {
      padding: 1rem 0; } }

.yorumlar-alani-sayfa {
  padding: 5rem 0 8rem;
  background: #ffffff; }
  @media only screen and (max-width: 56.25em) {
    .yorumlar-alani-sayfa {
      padding: 10rem 0; } }

.ekip-alani-sayfa {
  padding: 0rem 0 1rem; }
  @media only screen and (max-width: 56.25em) {
    .ekip-alani-sayfa {
      padding: 10rem 0; } }

.news-alani-sayfa {
  padding: 5rem 0 9rem;
  background: #fff; }
  @media only screen and (max-width: 56.25em) {
    .news-alani-sayfa {
      padding: 10rem 0; } }

@media only screen and (max-width: 40em) {
  img.haber-gorsel {
    width: 250px;
    text-align: center; } }
@media only screen and (max-width: 56.25em) {
  img.haber-gorsel {
    text-align: center; } }

.yorumlar-alani {
  position: relative;
  padding: 5rem 0 5rem;
  background-image: linear-gradient(to right bottom, #ffffff, #fbfbfb);
  background-size: cover; }
  @media only screen and (max-width: 56.25em) {
    .yorumlar-alani {
      padding: 5rem 0 0rem; } }

.form-alani {
  padding: 40rem 0;
  background-image: linear-gradient(to right bottom, #33c4e7, #0c52aa);
  margin-top: -30vh; }

.projeler {
  position: relative;
  padding: 5rem 0 5rem;
  background-image: linear-gradient(to right bottom, #ffffff, #fbfbfb);
  background-size: cover; }
  @media only screen and (max-width: 56.25em) {
    .projeler {
      padding: 15rem 0 16rem; } }

.projeler-sayfa-alani {
  padding: 5rem 0 5rem;
  /* background: url(../404.html) no-repeat; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  /*margin-top: -10rem;*/ }
  @media only screen and (max-width: 56.25em) {
    .projeler-sayfa-alani {
      padding: 15rem 0 16rem; } }

.markalar {
  padding: 6rem 0 6rem;
  background: url(../img/client-bg.png) no-repeat; }
  @media only screen and (max-width: 56.25em) {
    .markalar {
      padding: 15rem 0 16rem; } }

.markalar-siyah {
  padding: 6rem 0 6rem;
  background: #fff;
  background-size: cover;
  display: inline-block;
  width: 100%; }
  @media only screen and (max-width: 56.25em) {
    .markalar-siyah {
      padding: 15rem 0 16rem; } }

.markalarw {
  padding: 3rem 0 2rem;
  background: #fff;
  background-size: cover;
  display: inline-block;
  width: 100%; }
  @media only screen and (max-width: 56.25em) {
    .markalarw {
      padding: 15rem 0 16rem; } }

.bottom {
  padding: 5rem 0 1rem;
  background-image: linear-gradient(to right bottom, #fffefe, #ffffff);
  background-size: cover;
  display: inline-block;
  width: 100%; }
  @media only screen and (max-width: 56.25em) {
    .bottom {
      padding: 15rem 0 16rem; } }

.hizmetler-detay-sayfasi-alani {
  padding: 20rem 0 5rem;
  margin-top: -20vh;
  background-color: #fff; }
  @media only screen and (max-width: 56.25em) {
    .hizmetler-detay-sayfasi-alani {
      padding: 0rem 0rem 20rem;
      margin-top: 20vh; } }
  @media only screen and (max-width: 56.25em) {
    .hizmetler-detay-sayfasi-alani {
      padding: 0rem 0rem 20rem;
      margin-top: -20vh; } }

.proje-detay-sayfasi-alani {
  padding: 5rem 0 1rem;
  /*margin-top:-20vh;*/
  background-color: #fff; }
  @media only screen and (max-width: 56.25em) {
    .proje-detay-sayfasi-alani {
      padding: 40rem 0rem 5rem;
      margin-top: 20vh; } }
  @media only screen and (max-width: 56.25em) {
    .proje-detay-sayfasi-alani {
      padding: 40rem 0rem 5rem;
      margin-top: -20vh; } }

.iletisim-icon-alani {
  padding: 20rem 0rem 1rem;
  margin-top: -20vh;
  background-color: #fff; }
  @media only screen and (max-width: 56.25em) {
    .iletisim-icon-alani {
      padding: 15rem 0;
      margin-top: 20vh; } }
  @media only screen and (max-width: 56.25em) {
    .iletisim-icon-alani {
      padding: 15rem 0;
      margin-top: -8vh; } }

.iletisim-form-alani {
  padding: 20rem 0 10rem;
  margin-top: -20vh;
  background-color: #fff; }
  @media only screen and (max-width: 56.25em) {
    .iletisim-form-alani {
      padding: 0rem 0;
      margin-top: 20vh; } }
  @media only screen and (max-width: 56.25em) {
    .iletisim-form-alani {
      padding: 0rem 0;
      margin-top: -20vh; } }

span.date {
  font-size: 1.4rem;
  font-weight: 700;
  color: #141414;
  text-align: center; }
  @media only screen and (max-width: 40em) {
    span.date {
      font-size: 2.8rem; } }
  @media (min-width: 600px) and (max-width: 900px) {
    span.date {
      font-size: 3.5rem; } }
  @media (min-width: 900px) and (max-width: 1200px) {
    span.date {
      font-size: 2rem; } }

span.category {
  font-size: 1.4rem;
  font-weight: 700;
  color: #141414;
  text-align: center; }
  @media only screen and (max-width: 40em) {
    span.category {
      font-size: 2.8rem; } }
  @media (min-width: 600px) and (max-width: 900px) {
    span.category {
      font-size: 3.5rem; } }
  @media (min-width: 900px) and (max-width: 1200px) {
    span.category {
      font-size: 2rem; } }

span.tt {
  font-size: 1.4rem;
  font-weight: 700;
  color: #141414;
  text-align: center; }
  @media only screen and (max-width: 40em) {
    span.tt {
      font-size: 2.8rem; } }
  @media (min-width: 600px) and (max-width: 900px) {
    span.tt {
      font-size: 3.5rem; } }
  @media (min-width: 900px) and (max-width: 1200px) {
    span.tt {
      font-size: 2rem; } }

.datesection {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }

/* Efektler */
@keyframes solahareket {
  0% {
    opacity: 0;
    transform: translateX(-10rem); }
  80% {
    transform: translateX(1rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }
@keyframes sagahareket {
  0% {
    opacity: 0;
    transform: translateX(10rem); }
  80% {
    transform: translateX(-1rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }
@keyframes butonefekt {
  0% {
    opacity: 0;
    transform: translateY(3rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }
.jssorl-009-spin img {
  animation-name: jssorl-009-spin;
  animation-duration: 1.6s;
  animation-iteration-count: infinite;
  animation-timing-function: linear; }

@keyframes jssorl-009-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }
/*jssor slider bullet skin 132 css*/
.jssorb132 {
  position: absolute; }

.jssorb132 .i {
  position: absolute;
  cursor: pointer; }

.jssorb132 .i .b {
  fill: #fff;
  fill-opacity: 0.8;
  stroke: #DE6FAA;
  stroke-width: 1600;
  stroke-miterlimit: 10;
  stroke-opacity: 0.7; }

.jssorb132 .i:hover .b {
  fill: #DE6FAA;
  fill-opacity: .7;
  stroke: #fff;
  stroke-width: 2000;
  stroke-opacity: 0.8; }

.jssorb132 .iav .b {
  fill: #DE6FAA;
  stroke: #fff;
  stroke-width: 2400;
  fill-opacity: 0.8;
  stroke-opacity: 1; }

.jssorb132 .i.idn {
  opacity: 0.3; }

.jssora051 {
  display: block;
  position: absolute;
  cursor: pointer; }

.jssora051 .a {
  fill: none;
  stroke: #fff;
  stroke-width: 360;
  stroke-miterlimit: 10; }

.jssora051:hover {
  opacity: .8; }

.jssora051.jssora051dn {
  opacity: .5; }

.jssora051.jssora051ds {
  opacity: .3;
  pointer-events: none; }

/* 
Animasyonlar
*/
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite; }

.animated.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s; }

.animated.bounceIn,
.animated.bounceOut {
  -webkit-animation-duration: .75s;
  animation-duration: .75s; }

.animated.flipOutX,
.animated.flipOutY {
  -webkit-animation-duration: .75s;
  animation-duration: .75s; }

@-webkit-keyframes bounce {
  0%,
    20%,
    53%,
    80%,
    100% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  40%,
    43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0); }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0); }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0); } }
@keyframes bounce {
  0%,
    20%,
    53%,
    80%,
    100% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  40%,
    43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0); }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0); }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0); } }
.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom; }

@-webkit-keyframes flash {
  0%,
    50%,
    100% {
    opacity: 1; }
  25%,
    75% {
    opacity: 0; } }
@keyframes flash {
  0%,
    50%,
    100% {
    opacity: 1; }
  25%,
    75% {
    opacity: 0; } }
.flash {
  -webkit-animation-name: flash;
  animation-name: flash; }

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }
@keyframes pulse {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }
.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse; }

@-webkit-keyframes rubberBand {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1); }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1); }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1); }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1); }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1); }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }
@keyframes rubberBand {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1); }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1); }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1); }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1); }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1); }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }
.rubberBand {
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand; }

@-webkit-keyframes shake {
  0%,
    100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  10%,
    30%,
    50%,
    70%,
    90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0); }
  20%,
    40%,
    60%,
    80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0); } }
@keyframes shake {
  0%,
    100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  10%,
    30%,
    50%,
    70%,
    90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0); }
  20%,
    40%,
    60%,
    80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0); } }
.shake {
  -webkit-animation-name: shake;
  animation-name: shake; }

@-webkit-keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg); }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg); }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg); }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg); }
  100% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg); } }
@keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg); }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg); }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg); }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg); }
  100% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg); } }
.swing {
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing; }

@-webkit-keyframes tada {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  10%,
    20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg); }
  30%,
    50%,
    70%,
    90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg); }
  40%,
    60%,
    80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg); }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }
@keyframes tada {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  10%,
    20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg); }
  30%,
    50%,
    70%,
    90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg); }
  40%,
    60%,
    80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg); }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }
.tada {
  -webkit-animation-name: tada;
  animation-name: tada; }

@-webkit-keyframes wobble {
  0% {
    -webkit-transform: none;
    transform: none; }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg); }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg); }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg); }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg); }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg); }
  100% {
    -webkit-transform: none;
    transform: none; } }
@keyframes wobble {
  0% {
    -webkit-transform: none;
    transform: none; }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg); }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg); }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg); }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg); }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg); }
  100% {
    -webkit-transform: none;
    transform: none; } }
.wobble {
  -webkit-animation-name: wobble;
  animation-name: wobble; }

@-webkit-keyframes jello {
  11.1% {
    -webkit-transform: none;
    transform: none; }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg); }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg); }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg); }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg); }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg); }
  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg); }
  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg); }
  100% {
    -webkit-transform: none;
    transform: none; } }
@keyframes jello {
  11.1% {
    -webkit-transform: none;
    transform: none; }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg); }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg); }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg); }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg); }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg); }
  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg); }
  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg); }
  100% {
    -webkit-transform: none;
    transform: none; } }
.jello {
  -webkit-animation-name: jello;
  animation-name: jello;
  -webkit-transform-origin: center;
  transform-origin: center; }

@-webkit-keyframes bounceIn {
  0%,
    20%,
    40%,
    60%,
    80%,
    100% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03); }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97); }
  100% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }
@keyframes bounceIn {
  0%,
    20%,
    40%,
    60%,
    80%,
    100% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03); }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97); }
  100% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }
.bounceIn {
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn; }

@-webkit-keyframes bounceInDown {
  0%,
    60%,
    75%,
    90%,
    100% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0); }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0); }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0); }
  100% {
    -webkit-transform: none;
    transform: none; } }
@keyframes bounceInDown {
  0%,
    60%,
    75%,
    90%,
    100% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0); }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0); }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0); }
  100% {
    -webkit-transform: none;
    transform: none; } }
.bounceInDown {
  -webkit-animation-name: bounceInDown;
  animation-name: bounceInDown; }

@-webkit-keyframes bounceInLeft {
  0%,
    60%,
    75%,
    90%,
    100% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0); }
  100% {
    -webkit-transform: none;
    transform: none; } }
@keyframes bounceInLeft {
  0%,
    60%,
    75%,
    90%,
    100% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0); }
  100% {
    -webkit-transform: none;
    transform: none; } }
.bounceInLeft {
  -webkit-animation-name: bounceInLeft;
  animation-name: bounceInLeft; }

@-webkit-keyframes bounceInRight {
  0%,
    60%,
    75%,
    90%,
    100% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0); }
  100% {
    -webkit-transform: none;
    transform: none; } }
@keyframes bounceInRight {
  0%,
    60%,
    75%,
    90%,
    100% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0); }
  100% {
    -webkit-transform: none;
    transform: none; } }
.bounceInRight {
  -webkit-animation-name: bounceInRight;
  animation-name: bounceInRight; }

@-webkit-keyframes bounceInUp {
  0%,
    60%,
    75%,
    90%,
    100% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0); }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
@keyframes bounceInUp {
  0%,
    60%,
    75%,
    90%,
    100% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0); }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
.bounceInUp {
  -webkit-animation-name: bounceInUp;
  animation-name: bounceInUp; }

@-webkit-keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9); }
  50%,
    55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  100% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); } }
@keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9); }
  50%,
    55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  100% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); } }
.bounceOut {
  -webkit-animation-name: bounceOut;
  animation-name: bounceOut; }

@-webkit-keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); }
  40%,
    45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); } }
@keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); }
  40%,
    45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); } }
.bounceOutDown {
  -webkit-animation-name: bounceOutDown;
  animation-name: bounceOutDown; }

@-webkit-keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); } }
@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); } }
.bounceOutLeft {
  -webkit-animation-name: bounceOutLeft;
  animation-name: bounceOutLeft; }

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); } }
@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); } }
.bounceOutRight {
  -webkit-animation-name: bounceOutRight;
  animation-name: bounceOutRight; }

@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0); }
  40%,
    45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0); }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); } }
@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0); }
  40%,
    45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0); }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); } }
.bounceOutUp {
  -webkit-animation-name: bounceOutUp;
  animation-name: bounceOutUp; }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn; }

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }
@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown; }

@-webkit-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }
@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    transform: translateY(-2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }
.fadeInDownBig {
  -webkit-animation-name: fadeInDownBig;
  animation-name: fadeInDownBig; }

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); } }
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }
.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft; }

@-webkit-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); } }
@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }
.fadeInLeftBig {
  -webkit-animation-name: fadeInLeftBig;
  animation-name: fadeInLeftBig; }

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    transform: translateX(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); } }
@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }
.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight; }

@-webkit-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); } }
@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    transform: translateX(2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }
.fadeInRightBig {
  -webkit-animation-name: fadeInRightBig;
  animation-name: fadeInRightBig; }

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }
.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp; }

@-webkit-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }
@keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    transform: translateY(2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }
.fadeInUpBig {
  -webkit-animation-name: fadeInUpBig;
  animation-name: fadeInUpBig; }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }
@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }
.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut; }

@-webkit-keyframes fadeOutDown {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px); } }
@keyframes fadeOutDown {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px); } }
.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown; }

@-webkit-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px); } }
@keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    transform: translateY(2000px); } }
.fadeOutDownBig {
  -webkit-animation-name: fadeOutDownBig;
  animation-name: fadeOutDownBig; }

@-webkit-keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px); } }
@keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    transform: translateX(-20px); } }
.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft; }

@-webkit-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); } }
@keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    transform: translateX(-2000px); } }
.fadeOutLeftBig {
  -webkit-animation-name: fadeOutLeftBig;
  animation-name: fadeOutLeftBig; }

@-webkit-keyframes fadeOutRight {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    transform: translateX(20px); } }
@keyframes fadeOutRight {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px); } }
.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight; }

@-webkit-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px); } }
@keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    transform: translateX(2000px); } }
.fadeOutRightBig {
  -webkit-animation-name: fadeOutRightBig;
  animation-name: fadeOutRightBig; }

@-webkit-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px); } }
@keyframes fadeOutUp {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px); } }
.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp; }

@-webkit-keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px); } }
@keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    transform: translateY(-2000px); } }
.fadeOutUpBig {
  -webkit-animation-name: fadeOutUpBig;
  animation-name: fadeOutUpBig; }

@-webkit-keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  40% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  50% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  100% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; } }
@keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  40% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  50% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  100% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; } }
.animated.flip {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-animation-name: flip;
  animation-name: flip; }

@-webkit-keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
  100% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }
@keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
  100% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }
.flipInX {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
  animation-name: flipInX; }

@-webkit-keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg); }
  100% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }
@keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg); }
  100% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }
.flipInY {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInY;
  animation-name: flipInY; }

@-webkit-keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1; }
  100% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; } }
@keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1; }
  100% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; } }
.flipOutX {
  -webkit-animation-name: flipOutX;
  animation-name: flipOutX;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important; }

@-webkit-keyframes flipOutY {
  0% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1; }
  100% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0; } }
@keyframes flipOutY {
  0% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1; }
  100% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0; } }
.flipOutY {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipOutY;
  animation-name: flipOutY; }

@-webkit-keyframes lightSpeedIn {
  0% {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0; }
  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1; }
  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
    opacity: 1; }
  100% {
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }
@keyframes lightSpeedIn {
  0% {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0; }
  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1; }
  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
    opacity: 1; }
  100% {
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }
.lightSpeedIn {
  -webkit-animation-name: lightSpeedIn;
  animation-name: lightSpeedIn;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out; }

@-webkit-keyframes lightSpeedOut {
  0% {
    opacity: 1; }
  100% {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0; } }
@keyframes lightSpeedOut {
  0% {
    opacity: 1; }
  100% {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0; } }
.lightSpeedOut {
  -webkit-animation-name: lightSpeedOut;
  animation-name: lightSpeedOut;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in; }

@-webkit-keyframes rotateIn {
  0% {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }
@keyframes rotateIn {
  0% {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }
.rotateIn {
  -webkit-animation-name: rotateIn;
  animation-name: rotateIn; }

@-webkit-keyframes rotateInDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }
@keyframes rotateInDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }
.rotateInDownLeft {
  -webkit-animation-name: rotateInDownLeft;
  animation-name: rotateInDownLeft; }

@-webkit-keyframes rotateInDownRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }
@keyframes rotateInDownRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }
.rotateInDownRight {
  -webkit-animation-name: rotateInDownRight;
  animation-name: rotateInDownRight; }

@-webkit-keyframes rotateInUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }
@keyframes rotateInUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }
.rotateInUpLeft {
  -webkit-animation-name: rotateInUpLeft;
  animation-name: rotateInUpLeft; }

@-webkit-keyframes rotateInUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -90deg);
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }
@keyframes rotateInUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -90deg);
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }
.rotateInUpRight {
  -webkit-animation-name: rotateInUpRight;
  animation-name: rotateInUpRight; }

@-webkit-keyframes rotateOut {
  0% {
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1; }
  100% {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0; } }
@keyframes rotateOut {
  0% {
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1; }
  100% {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0; } }
.rotateOut {
  -webkit-animation-name: rotateOut;
  animation-name: rotateOut; }

@-webkit-keyframes rotateOutDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1; }
  100% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; } }
@keyframes rotateOutDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1; }
  100% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; } }
.rotateOutDownLeft {
  -webkit-animation-name: rotateOutDownLeft;
  animation-name: rotateOutDownLeft; }

@-webkit-keyframes rotateOutDownRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1; }
  100% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }
@keyframes rotateOutDownRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1; }
  100% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }
.rotateOutDownRight {
  -webkit-animation-name: rotateOutDownRight;
  animation-name: rotateOutDownRight; }

@-webkit-keyframes rotateOutUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1; }
  100% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }
@keyframes rotateOutUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1; }
  100% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }
.rotateOutUpLeft {
  -webkit-animation-name: rotateOutUpLeft;
  animation-name: rotateOutUpLeft; }

@-webkit-keyframes rotateOutUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1; }
  100% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0; } }
@keyframes rotateOutUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1; }
  100% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0; } }
.rotateOutUpRight {
  -webkit-animation-name: rotateOutUpRight;
  animation-name: rotateOutUpRight; }

@-webkit-keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  20%,
    60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  40%,
    80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1; }
  100% {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0; } }
@keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  20%,
    60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  40%,
    80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1; }
  100% {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0; } }
.hinge {
  -webkit-animation-name: hinge;
  animation-name: hinge; }

@-webkit-keyframes rollIn {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }
@keyframes rollIn {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }
.rollIn {
  -webkit-animation-name: rollIn;
  animation-name: rollIn; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes rollOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg); } }
@keyframes rollOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg); } }
.rollOut {
  -webkit-animation-name: rollOut;
  animation-name: rollOut; }

@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }
@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }
.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn; }

@-webkit-keyframes zoomInStable {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  33.333% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  66.666666% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }
@keyframes zoomInStable {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  33.333% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  66.666666% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }
.zoomInStable {
  -webkit-animation-name: zoomInStable;
  animation-name: zoomInStable; }

@-webkit-keyframes zoomInDown {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }
@keyframes zoomInDown {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }
.zoomInDown {
  -webkit-animation-name: zoomInDown;
  animation-name: zoomInDown; }

@-webkit-keyframes zoomInLeft {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }
@keyframes zoomInLeft {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }
.zoomInLeft {
  -webkit-animation-name: zoomInLeft;
  animation-name: zoomInLeft; }

@-webkit-keyframes zoomInRight {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }
@keyframes zoomInRight {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }
.zoomInRight {
  -webkit-animation-name: zoomInRight;
  animation-name: zoomInRight; }

@-webkit-keyframes zoomInUp {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }
@keyframes zoomInUp {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }
.zoomInUp {
  -webkit-animation-name: zoomInUp;
  animation-name: zoomInUp; }

@-webkit-keyframes zoomOut {
  0% {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  100% {
    opacity: 0; } }
@keyframes zoomOut {
  0% {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  100% {
    opacity: 0; } }
.zoomOut {
  -webkit-animation-name: zoomOut;
  animation-name: zoomOut; }

@-webkit-keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  100% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }
@keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  100% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }
.zoomOutDown {
  -webkit-animation-name: zoomOutDown;
  animation-name: zoomOutDown; }

@-webkit-keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center; } }
@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center; } }
.zoomOutLeft {
  -webkit-animation-name: zoomOutLeft;
  animation-name: zoomOutLeft; }

@-webkit-keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    transform-origin: right center; } }
@keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    transform-origin: right center; } }
.zoomOutRight {
  -webkit-animation-name: zoomOutRight;
  animation-name: zoomOutRight; }

@-webkit-keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  100% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }
@keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  100% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }
.zoomOutUp {
  -webkit-animation-name: zoomOutUp;
  animation-name: zoomOutUp; }

@-webkit-keyframes slideInDown {
  0% {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
@keyframes slideInDown {
  0% {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown; }

@-webkit-keyframes slideInLeft {
  0% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
@keyframes slideInLeft {
  0% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft; }

@-webkit-keyframes slideInRight {
  0% {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible; }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
@keyframes slideInRight {
  0% {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible; }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight; }

@-webkit-keyframes slideInUp {
  0% {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible; }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
@keyframes slideInUp {
  0% {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible; }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp; }

@-webkit-keyframes slideOutDown {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  100% {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }
@keyframes slideOutDown {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  100% {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }
.slideOutDown {
  -webkit-animation-name: slideOutDown;
  animation-name: slideOutDown; }

@-webkit-keyframes slideOutLeft {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  100% {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }
@keyframes slideOutLeft {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  100% {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }
.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft; }

@-webkit-keyframes slideOutRight {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  100% {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }
@keyframes slideOutRight {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  100% {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }
.slideOutRight {
  -webkit-animation-name: slideOutRight;
  animation-name: slideOutRight; }

@-webkit-keyframes slideOutUp {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  100% {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }
@keyframes slideOutUp {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  100% {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }
.slideOutUp {
  -webkit-animation-name: slideOutUp;
  animation-name: slideOutUp; }

@keyframes anime {
  from {
    opacity: 0;
    transform: scaleY(0);
    -webkit-transform: scaleY(0);
    -moz-transform: scaleY(0);
    -ms-transform: scaleY(0);
    -o-transform: scaleY(0); }
  to {
    opacity: 1;
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    -moz-transform: scaleY(1); } }
@-webkit-keyframes anime {
  from {
    opacity: 0;
    transform: scaleY(0);
    -webkit-transform: scaleY(0);
    -moz-transform: scaleY(0);
    -ms-transform: scaleY(0);
    -o-transform: scaleY(0); }
  to {
    opacity: 1;
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    -moz-transform: scaleY(1); } }
@-moz-keyframes anime {
  from {
    opacity: 0;
    transform: scaleY(0);
    -webkit-transform: scaleY(0);
    -moz-transform: scaleY(0);
    -ms-transform: scaleY(0);
    -o-transform: scaleY(0); }
  to {
    opacity: 1;
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    -moz-transform: scaleY(1); } }
@-o-keyframes anime {
  from {
    opacity: 0;
    transform: scaleY(0);
    -webkit-transform: scaleY(0);
    -moz-transform: scaleY(0);
    -ms-transform: scaleY(0);
    -o-transform: scaleY(0); }
  to {
    opacity: 1;
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    -moz-transform: scaleY(1); } }
@-ms-keyframes anime {
  from {
    opacity: 0;
    transform: scaleY(0);
    -webkit-transform: scaleY(0);
    -moz-transform: scaleY(0);
    -ms-transform: scaleY(0);
    -o-transform: scaleY(0); }
  to {
    opacity: 1;
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    -moz-transform: scaleY(1); } }
body {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.7;
  color: #616161; }

.header__slider-alani {
  position: absolute;
  top: 40%;
  left: 40%;
  transform: translate(-30%, -30%);
  text-align: center; }

.golge {
  font-size: 9.2rem;
  color: #212529;
  line-height: 42px;
  font-weight: 900;
  letter-spacing: -.05em;
  -webkit-text-fill-color: #FFF;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #2a2a2a;
  white-space: nowrap;
  opacity: .1 !important;
  text-align: center; }
  @media only screen and (max-width: 40em) {
    .golge {
      margin-top: 86rem; } }
  @media only screen and (max-width: 375px) {
    .golge {
      margin-top: 102rem; } }
  @media (min-width: 645px) and (max-width: 764px) {
    .golge {
      margin-top: 70rem; } }
  @media (min-width: 768px) and (max-width: 900px) {
    .golge {
      margin-top: 57rem; } }
  @media (min-width: 901px) and (max-width: 998px) {
    .golge {
      margin-top: 28rem; } }
  @media (min-width: 1000px) and (max-width: 1024px) {
    .golge {
      margin-top: 20rem; } }
  @media (min-width: 1025px) and (max-width: 1088px) {
    .golge {
      margin-top: -20rem; } }
  @media (min-width: 1090px) and (max-width: 1190px) {
    .golge {
      margin-top: -20rem; } }
  @media (min-width: 1191px) and (max-width: 1350px) {
    .golge {
      margin-top: -15rem; } }

.divider {
  opacity: .5;
  height: auto;
  max-width: 100%;
  vertical-align: top; }

.alan {
  margin-top: -80px; }
  @media only screen and (max-width: 1024px) {
    .alan {
      display: none; } }
  @media only screen and (max-width: 56.25em) {
    .alan {
      display: none; } }

.alanb {
  margin-top: -30px; }
  @media only screen and (max-width: 1024px) {
    .alanb {
      display: none; } }
  @media only screen and (max-width: 56.25em) {
    .alanb {
      display: none; } }

.slider-yazi-icerikleri {
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 2rem;
  backface-visibility: hidden; }
  .slider-yazi-icerikleri--1 {
    display: block;
    font-size: 6rem;
    font-weight: 400;
    letter-spacing: 3rem;
    animation-name: solahareket;
    animation-duration: 1s;
    animation-timing-function: ease-in-out; }
    @media only screen and (max-width: 40em) {
      .slider-yazi-icerikleri--1 {
        letter-spacing: 1rem;
        font-size: 5rem; } }
  .slider-yazi-icerikleri--2 {
    display: block;
    font-size: 1.9rem;
    font-weight: 700;
    letter-spacing: 1rem;
    animation: sagahareket 1s ease-out; }
    @media only screen and (max-width: 40em) {
      .slider-yazi-icerikleri--2 {
        letter-spacing: .5rem; } }

.h2-baslik-anasayfa {
  font-size: 4rem;
  font-weight: 900;
  text-transform: uppercase;
  display: inline-block;
  background-image: linear-gradient(to right, #33c4e7, #0c52aa);
  /* -webkit-background-clip: text; */
  color: transparent;
  letter-spacing: .2rem;
  transition: all .2s;
  border-bottom: 0.1rem solid #33c4e7; }
  @media only screen and (max-width: 56.25em) {
    .h2-baslik-anasayfa {
      font-size: 6rem; } }
  @media only screen and (max-width: 40em) {
    .h2-baslik-anasayfa {
      font-size: 4.7rem; } }
  .h2-baslik-anasayfa:hover {
    transform: skewY(2deg) skewX(15deg) scale(1.1);
    /*text-shadow: .5rem 1rem 2rem rgba($siyah-renk,.2);*/ }

.h2-baslik-anasayfa-ozel {
  font-size: 4rem;
  font-weight: 600;
  display: inline-block;
  background-image: linear-gradient(to right, #000000, #000000);
  /* -webkit-background-clip: text; */
  color: transparent;
  line-height: 1.3em; }
  @media only screen and (max-width: 56.25em) {
    .h2-baslik-anasayfa-ozel {
      font-size: 5rem;
      margin-top: 11rem; } }

.h2-baslik-anasayfa-ozel-services {
  font-size: 3rem;
  font-weight: 700;
  display: inline-block;
  background-image: linear-gradient(to right, #000000, #000000);
  /* -webkit-background-clip: text; */
  color: transparent;
  line-height: 1.3em; }
  @media only screen and (max-width: 56.25em) {
    .h2-baslik-anasayfa-ozel-services {
      font-size: 5rem;
      margin-top: 11rem; } }

.h2-baslik-anasayfa-ozel-blog {
  font-size: 3rem;
  font-weight: 700;
  display: inline-block;
  background-image: linear-gradient(to right, #000000, #000000);
  /* -webkit-background-clip: text; */
  color: transparent;
  line-height: 1.3em; }
  @media only screen and (max-width: 56.25em) {
    .h2-baslik-anasayfa-ozel-blog {
      font-size: 5rem;
      margin-top: 11rem; } }

.h2-baslik-anasayfa-ozel1 {
  font-size: 3rem;
  font-weight: 600;
  display: inline-block;
  color: #000000;
  line-height: 1.3em; }
  @media only screen and (max-width: 56.25em) {
    .h2-baslik-anasayfa-ozel1 {
      font-size: 5rem;
      margin-top: 11rem; } }

.h2-baslik-anasayfa-blog {
  font-size: 2.4rem;
  font-weight: 900;
  text-transform: uppercase;
  display: inline-block;
  background-image: linear-gradient(to right, #292929, #000000);
  /* -webkit-background-clip: text; */
  color: transparent;
  letter-spacing: .1rem;
  padding: 14px 15px 14px 18px; }
  @media only screen and (max-width: 56.25em) {
    .h2-baslik-anasayfa-blog {
      font-size: 5rem;
      margin-top: 11rem; } }

.h2-baslik-ahb {
  font-size: 2.5rem;
  font-weight: 900;
  text-transform: uppercase;
  background-image: linear-gradient(to right, #292929, #000000);
  /* -webkit-background-clip: text; */
  color: transparent;
  letter-spacing: .1rem;
  text-align: center; }
  @media only screen and (max-width: 56.25em) {
    .h2-baslik-ahb {
      font-size: 5rem; } }

.h2-baslik-bottom {
  font-size: 1.6rem;
  font-weight: 900;
  text-transform: uppercase;
  display: inline-block;
  background-image: linear-gradient(to right, #292929, #000000);
  /* -webkit-background-clip: text; */
  color: transparent;
  letter-spacing: .1rem; }
  @media only screen and (max-width: 56.25em) {
    .h2-baslik-bottom {
      font-size: 3rem; } }

.h2-baslik-404 {
  font-size: 5rem;
  font-weight: 900;
  text-transform: uppercase;
  display: inline-block;
  background-image: linear-gradient(to right, #292929, #000000);
  /* -webkit-background-clip: text; */
  color: transparent;
  letter-spacing: .2rem;
  margin-bottom: 3rem; }
  @media only screen and (max-width: 56.25em) {
    .h2-baslik-404 {
      font-size: 7rem; } }

.h2-baslik-footer {
  font-size: 3rem;
  font-weight: 900;
  text-transform: uppercase;
  display: inline-block;
  background-image: linear-gradient(to right, #fff, #fff);
  /* -webkit-background-clip: text; */
  color: transparent;
  letter-spacing: .2rem;
  margin-bottom: 3rem; }
  @media only screen and (max-width: 56.25em) {
    .h2-baslik-footer {
      font-size: 7rem; } }

.h2-baslik-projeler-ozel {
  font-size: 3rem;
  font-weight: 900;
  text-transform: uppercase;
  display: inline-block;
  background-image: linear-gradient(to right, #259cb9, #33c4e7);
  /* -webkit-background-clip: text; */
  color: transparent;
  letter-spacing: .2rem;
  margin-bottom: 3rem; }
  @media only screen and (max-width: 56.25em) {
    .h2-baslik-projeler-ozel {
      font-size: 7rem; } }

.h2-baslik-iletisim-ozel {
  font-size: 4rem;
  font-weight: 900;
  text-transform: uppercase;
  display: inline-block;
  background-image: linear-gradient(to right, #292929, #000000);
  /* -webkit-background-clip: text; */
  color: transparent;
  letter-spacing: .2rem;
  margin-bottom: 3rem; }
  @media only screen and (max-width: 56.25em) {
    .h2-baslik-iletisim-ozel {
      font-size: 7rem;
      margin-top: -90px; } }

.h2-baslik-popup {
  font-size: 2.3rem;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
  background-image: linear-gradient(to right, #000000, #000000);
  /* -webkit-background-clip: text; */
  color: transparent;
  letter-spacing: .2rem;
  transition: all .2s;
  margin-bottom: 3rem;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }
  @media only screen and (max-width: 56.25em) {
    .h2-baslik-popup {
      margin-top: .1rem;
      font-size: 5rem; } }

.paragraf-slider {
  font-size: 2rem;
  font-weight: 300;
  color: #616161;
  text-align: left; }
  .paragraf-slider:not(:last-child) {
    margin-bottom: 2rem;
    margin-top: -.8rem;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }
  @media only screen and (max-width: 56.25em) {
    .paragraf-slider {
      font-size: 2.1rem; } }

.paragraf-popup {
  font-size: 1.8rem;
  font-weight: 300;
  color: #616161;
  text-align: left; }
  .paragraf-popup:not(:last-child) {
    margin-bottom: 2rem;
    margin-top: -.8rem;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }
  @media only screen and (max-width: 56.25em) {
    .paragraf-popup {
      font-size: 4rem; } }

.baslik-3 {
  font-size: 1.8rem;
  font-weight: 700;
  color: #000;
  text-align: center; }
  @media only screen and (max-width: 56.25em) {
    .baslik-3 {
      font-size: 3.9rem;
      text-align: center;
      /*padding: 1rem;*/ } }
  @media (min-width: 641px) and (max-width: 820px) {
    .baslik-3 {
      margin-top: 10rem; } }

.baslik-3s {
  font-size: 1.8rem;
  font-weight: 600;
  color: #1c437f;
  text-align: left; }
  @media only screen and (max-width: 56.25em) {
    .baslik-3s {
      font-size: 3.9rem;
      text-align: center;
      /*padding: 1rem;*/ } }
  @media (min-width: 641px) and (max-width: 820px) {
    .baslik-3s {
      margin-top: 10rem; } }

.baslik-3white {
  font-size: 1.6rem;
  font-weight: 700;
  color: #fff;
  text-align: center; }
  @media only screen and (max-width: 56.25em) {
    .baslik-3white {
      font-size: 3.9rem;
      text-align: center;
      /*padding: 1rem;*/ } }

.baslik-3whitec {
  font-size: 4rem;
  font-weight: 700;
  color: #fff;
  text-align: center; }
  @media only screen and (max-width: 56.25em) {
    .baslik-3whitec {
      font-size: 10rem;
      text-align: center;
      /*padding: 1rem;*/ } }

.baslik-sol {
  font-size: 1.8rem;
  font-weight: 700;
  color: #ffffff;
  text-align: center; }
  @media only screen and (max-width: 56.25em) {
    .baslik-sol {
      font-size: 3.9rem;
      text-align: center;
      /*padding: 1rem;*/ } }
  @media (min-width: 641px) and (max-width: 820px) {
    .baslik-sol {
      margin-top: 10rem; } }

.baslik-orta {
  font-size: 1.8rem;
  font-weight: 700;
  color: #000000;
  text-align: center; }
  @media only screen and (max-width: 56.25em) {
    .baslik-orta {
      font-size: 3.9rem;
      text-align: center;
      /*padding: 1rem;*/ } }
  @media (min-width: 641px) and (max-width: 820px) {
    .baslik-orta {
      margin-top: 10rem; } }

.baslik-sol-beyaz {
  font-size: 1.8rem;
  font-weight: 700;
  color: #ffffff;
  text-align: left; }
  @media only screen and (max-width: 56.25em) {
    .baslik-sol-beyaz {
      font-size: 3.9rem;
      text-align: left;
      /*padding: 1rem;*/ } }

.baslik-3-1 {
  font-size: 1.8rem;
  font-weight: 700;
  color: #000;
  text-align: left; }
  @media only screen and (max-width: 56.25em) {
    .baslik-3-1 {
      font-size: 3.9rem;
      /*padding: 1rem;*/ } }

.baslik-3-h {
  font-size: 2.8rem;
  font-weight: 700;
  color: #292929;
  text-align: left; }
  @media only screen and (max-width: 56.25em) {
    .baslik-3-h {
      font-size: 3.9rem;
      /*padding: 1rem;*/ } }

.baslik-33 {
  font-size: 1.8rem;
  font-weight: 700;
  color: #292929;
  text-align: center; }
  @media only screen and (max-width: 56.25em) {
    .baslik-33 {
      font-size: 3.9rem;
      /*padding: 1rem;*/ } }

.baslik-star {
  font-size: 3.8rem;
  font-weight: 700;
  color: #292929;
  text-align: center; }
  @media only screen and (max-width: 56.25em) {
    .baslik-star {
      font-size: 3.9rem;
      /*padding: 1rem;*/ } }

.baslik-3-2 {
  font-size: 1.8rem;
  font-weight: 700;
  text-align: center;
  color: #fff; }
  @media only screen and (max-width: 56.25em) {
    .baslik-3-2 {
      font-size: 3.9rem;
      text-align: center;
      /*padding: 1rem;*/ } }

h2.h3-baslik-anasayfa-beyaz {
  width: 100%;
  display: block;
  font-size: 50px;
  font-weight: 800;
  color: #fff;
  text-align: center; }

.paragraf {
  font-size: 1.4rem;
  font-weight: 400;
  color: #616161; }
  .paragraf a {
    color: #0c52aa; }
  .paragraf:not(:last-child) {
    margin-bottom: 1.4rem; }
  @media only screen and (max-width: 56.25em) {
    .paragraf {
      font-size: 3.4rem; } }

.paragraf-info {
  font-size: 1.4rem;
  font-weight: 400;
  color: #616161; }
  .paragraf-info a {
    color: #0c52aa; }
  .paragraf-info:not(:last-child) {
    margin-bottom: 1.4rem;
    text-align: left; }
  @media only screen and (max-width: 56.25em) {
    .paragraf-info {
      font-size: 3.4rem;
      text-align: center; } }

.paragraf-orta {
  font-size: 1.4rem;
  font-weight: 400;
  color: #616161;
  text-align: center; }
  .paragraf-orta a {
    color: #0c52aa; }
  .paragraf-orta:not(:last-child) {
    margin-bottom: 1.4rem; }
  @media only screen and (max-width: 56.25em) {
    .paragraf-orta {
      font-size: 3.4rem; } }

.paragraf-sol-beyaz {
  font-size: 1.4rem;
  font-weight: 400;
  color: #ffffff; }
  .paragraf-sol-beyaz a {
    color: #0c52aa; }
  .paragraf-sol-beyaz:not(:last-child) {
    margin-bottom: 1.4rem; }
  @media only screen and (max-width: 56.25em) {
    .paragraf-sol-beyaz {
      font-size: 3.4rem; } }

.paragraf-sol-beyaz-orta {
  font-size: 1.4rem;
  font-weight: 400;
  color: #ffffff;
  text-align: center; }
  .paragraf-sol-beyaz-orta a {
    color: #0c52aa; }
  .paragraf-sol-beyaz-orta:not(:last-child) {
    margin-bottom: 1.4rem; }
  @media only screen and (max-width: 56.25em) {
    .paragraf-sol-beyaz-orta {
      font-size: 3.4rem; } }

.paragraf-sol-s {
  font-size: 1.4rem;
  font-weight: 400;
  color: #ffffff;
  text-align: left; }
  .paragraf-sol-s a {
    color: #0c52aa; }
  .paragraf-sol-s:not(:last-child) {
    margin-bottom: 1.4rem; }
  @media only screen and (max-width: 56.25em) {
    .paragraf-sol-s {
      font-size: 3.4rem; } }

.paragraf-ahp {
  font-size: 1.4rem;
  font-weight: 400;
  color: #616161;
  text-align: center; }
  .paragraf-ahp a {
    color: #0c52aa; }
  .paragraf-ahp:not(:last-child) {
    margin-bottom: 1.4rem; }
  @media only screen and (max-width: 56.25em) {
    .paragraf-ahp {
      font-size: 3.4rem; } }

.paragraf-pdetay {
  font-size: 1.8rem;
  font-weight: 300;
  color: #616161; }
  .paragraf-pdetay a {
    color: #0c52aa; }
  .paragraf-pdetay:not(:last-child) {
    margin-bottom: 2rem; }
  @media only screen and (max-width: 56.25em) {
    .paragraf-pdetay {
      font-size: 3.2rem;
      margin-bottom: -20rem; } }

.paragraf-404 {
  font-size: 1.8rem;
  font-weight: 300;
  color: #616161;
  line-height: 4rem; }
  .paragraf-404 a {
    color: #292929; }
  .paragraf-404:not(:last-child) {
    margin-bottom: 2rem; }
  @media only screen and (max-width: 56.25em) {
    .paragraf-404 {
      font-size: 3.4rem; } }

.proje-detay-paragraf {
  font-size: 1.8rem;
  font-weight: 300;
  padding: 0rem 15rem 0rem 15rem; }
  .proje-detay-paragraf:not(:last-child) {
    margin-bottom: 2rem; }
  @media only screen and (max-width: 56.25em) {
    .proje-detay-paragraf {
      padding: 0rem 5rem 0rem 5rem;
      font-size: 3.4rem; } }

.h3-baslik-hizmet {
  font-size: 2rem; }
  .h3-baslik-hizmet__paragraf {
    font-size: 2.4rem;
    font-weight: 300;
    color: #259cb9;
    text-align: left; }
    .h3-baslik-hizmet__paragraf:not(:last-child) {
      margin-bottom: 5rem; }
      @media only screen and (max-width: 56.25em) {
        .h3-baslik-hizmet__paragraf:not(:last-child) {
          margin-bottom: -9rem;
          text-align: center; } }
    @media only screen and (max-width: 56.25em) {
      .h3-baslik-hizmet__paragraf {
        font-size: 4rem; } }
  @media only screen and (max-width: 56.25em) {
    .h3-baslik-hizmet {
      font-size: 4rem; } }

.h2-baslik-hizmetler {
  font-size: 2.7rem;
  font-weight: 900;
  text-transform: uppercase;
  display: inline-block;
  background-image: linear-gradient(to right, #259cb9, #33c4e7);
  /* -webkit-background-clip: text; */
  color: transparent;
  letter-spacing: .2rem;
  margin-bottom: 1rem; }
  .h2-baslik-hizmetler__paragraf {
    font-size: 2.4rem;
    font-weight: 300;
    color: #259cb9;
    text-align: left; }
    .h2-baslik-hizmetler__paragraf:not(:last-child) {
      margin-bottom: 5rem; }
      @media only screen and (max-width: 56.25em) {
        .h2-baslik-hizmetler__paragraf:not(:last-child) {
          margin-bottom: -9rem;
          text-align: center; } }
    @media only screen and (max-width: 56.25em) {
      .h2-baslik-hizmetler__paragraf {
        font-size: 3.5rem; } }
  @media only screen and (max-width: 56.25em) {
    .h2-baslik-hizmetler {
      font-size: 7rem; } }

.h2-baslik-hizmetler-4 {
  font-size: 2.6rem;
  font-weight: 900;
  text-transform: uppercase;
  display: inline-block;
  background-image: linear-gradient(to right, #fff, #fff);
  /* -webkit-background-clip: text; */
  color: transparent;
  letter-spacing: .1rem;
  text-align: center; }
  .h2-baslik-hizmetler-4__paragraf {
    font-size: 1.6rem;
    font-weight: 400;
    color: #fff;
    text-align: center;
    margin-bottom: 3rem;
    text-align: center; }
    .h2-baslik-hizmetler-4__paragraf:not(:last-child) {
      margin-bottom: 3rem; }
      @media only screen and (max-width: 56.25em) {
        .h2-baslik-hizmetler-4__paragraf:not(:last-child) {
          margin-bottom: -9rem; } }
    @media only screen and (max-width: 56.25em) {
      .h2-baslik-hizmetler-4__paragraf {
        font-size: 3.5rem;
        text-align: center;
        padding: 3rem; } }
  @media only screen and (max-width: 56.25em) {
    .h2-baslik-hizmetler-4 {
      font-size: 5rem; } }

.h2-baslik-hizmetler-2 {
  font-size: 3.4rem;
  font-weight: 600;
  display: inline-block;
  color: #000000;
  text-align: center; }
  .h2-baslik-hizmetler-2__paragraf {
    font-size: 1.6rem;
    font-weight: 400;
    color: #616161;
    text-align: center;
    margin-bottom: 3rem;
    text-align: center; }
    .h2-baslik-hizmetler-2__paragraf:not(:last-child) {
      margin-bottom: -1rem; }
      @media only screen and (max-width: 56.25em) {
        .h2-baslik-hizmetler-2__paragraf:not(:last-child) {
          margin-bottom: -14rem; } }
    @media only screen and (max-width: 56.25em) {
      .h2-baslik-hizmetler-2__paragraf {
        font-size: 3.5rem;
        text-align: center;
        padding: 3rem; } }
  @media only screen and (max-width: 56.25em) {
    .h2-baslik-hizmetler-2 {
      font-size: 5rem;
      margin-top: 6rem; } }

.h2-baslik-hizmetler-21 {
  font-size: 3.4rem;
  font-weight: 600;
  display: inline-block;
  color: #ffffff;
  text-align: center; }
  .h2-baslik-hizmetler-21__paragraf {
    font-size: 1.6rem;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    margin-bottom: 3rem;
    text-align: center; }
    .h2-baslik-hizmetler-21__paragraf:not(:last-child) {
      margin-bottom: -1rem; }
      @media only screen and (max-width: 56.25em) {
        .h2-baslik-hizmetler-21__paragraf:not(:last-child) {
          margin-bottom: -2rem; } }
    @media only screen and (max-width: 56.25em) {
      .h2-baslik-hizmetler-21__paragraf {
        font-size: 3.5rem;
        text-align: center;
        padding: 3rem; } }
  @media only screen and (max-width: 56.25em) {
    .h2-baslik-hizmetler-21 {
      font-size: 5rem;
      margin-top: 6rem; } }

.h2-baslik-hizmetler-212 {
  font-size: 3.4rem;
  font-weight: 600;
  display: inline-block;
  color: #ffffff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center; }
  .h2-baslik-hizmetler-212__paragraf {
    font-size: 1.6rem;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    margin-bottom: 3rem;
    text-align: center; }
    .h2-baslik-hizmetler-212__paragraf:not(:last-child) {
      margin-bottom: -1rem; }
      @media only screen and (max-width: 56.25em) {
        .h2-baslik-hizmetler-212__paragraf:not(:last-child) {
          margin-bottom: -2rem; } }
    @media only screen and (max-width: 56.25em) {
      .h2-baslik-hizmetler-212__paragraf {
        font-size: 3.5rem;
        text-align: center;
        padding: 3rem; } }
  @media only screen and (max-width: 56.25em) {
    .h2-baslik-hizmetler-212 {
      font-size: 5rem;
      margin-top: 6rem; } }

.h2-baslik-hizmetler-2-s {
  font-size: 3rem;
  font-weight: 700;
  display: inline-block;
  color: #ffffff;
  letter-spacing: .1rem;
  text-align: left;
  width: 100%;
  display: flex;
  justify-content: left;
  flex-wrap: wrap; }
  .h2-baslik-hizmetler-2-s__paragraf {
    font-size: 1.6rem;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    margin-bottom: 3rem;
    text-align: center; }
    .h2-baslik-hizmetler-2-s__paragraf:not(:last-child) {
      margin-bottom: -1rem; }
      @media only screen and (max-width: 56.25em) {
        .h2-baslik-hizmetler-2-s__paragraf:not(:last-child) {
          margin-bottom: -2rem; } }
    @media only screen and (max-width: 56.25em) {
      .h2-baslik-hizmetler-2-s__paragraf {
        font-size: 3.5rem;
        text-align: center;
        padding: 3rem; } }
  @media only screen and (max-width: 56.25em) {
    .h2-baslik-hizmetler-2-s {
      font-size: 5rem;
      margin-top: 6rem; } }

.h2-baslik-hizmetler-5 {
  font-size: 2.6rem;
  font-weight: 900;
  text-transform: uppercase;
  display: inline-block;
  background-image: linear-gradient(to right, #292929, #000000);
  /* -webkit-background-clip: text; */
  color: transparent;
  letter-spacing: .1rem;
  text-align: center; }
  .h2-baslik-hizmetler-5__paragraf {
    font-size: 1.6rem;
    font-weight: 400;
    color: #616161;
    text-align: center;
    margin-bottom: 3rem;
    text-align: center; }
    .h2-baslik-hizmetler-5__paragraf:not(:last-child) {
      margin-bottom: 2rem; }
      @media only screen and (max-width: 56.25em) {
        .h2-baslik-hizmetler-5__paragraf:not(:last-child) {
          margin-bottom: -2rem; } }
    @media only screen and (max-width: 56.25em) {
      .h2-baslik-hizmetler-5__paragraf {
        font-size: 3.5rem;
        text-align: center;
        padding: 3rem; } }
  @media only screen and (max-width: 56.25em) {
    .h2-baslik-hizmetler-5 {
      font-size: 5rem; } }

.h2-baslik-hizmetler-3 {
  font-size: 2.6rem;
  font-weight: 900;
  text-transform: uppercase;
  display: inline-block;
  background-image: linear-gradient(to right, #292929, #000000);
  /* -webkit-background-clip: text; */
  color: transparent;
  letter-spacing: .1rem; }
  .h2-baslik-hizmetler-3__paragraf {
    font-size: 1.6rem;
    font-weight: 400;
    color: #616161;
    text-align: center;
    margin-bottom: 1rem; }
    @media only screen and (max-width: 56.25em) {
      .h2-baslik-hizmetler-3__paragraf:not(:last-child) {
        margin-bottom: -5rem;
        text-align: center;
        align-items: center;
        clear: both;
        margin: auto; } }
    @media only screen and (max-width: 56.25em) {
      .h2-baslik-hizmetler-3__paragraf {
        font-size: 3.5rem;
        text-align: left;
        padding: 3rem; } }
  @media only screen and (max-width: 56.25em) {
    .h2-baslik-hizmetler-3 {
      font-size: 5rem; } }

ul.hizmetdetay {
  padding-left: 2rem;
  line-height: 4rem;
  font-size: 1.5rem;
  font-weight: 400;
  color: #797979; }
  @media only screen and (max-width: 56.25em) {
    ul.hizmetdetay {
      padding-left: 2rem;
      line-height: 8rem;
      font-size: 3rem; } }
  @media only screen and (max-width: 40em) {
    ul.hizmetdetay {
      padding-left: 2rem;
      line-height: 8rem;
      font-size: 3rem; } }

summary {
  font-size: 1.7rem;
  font-weight: 600;
  background-color: #fafafa;
  background-image: radial-gradient(circle at 40% 91%, rgba(251, 251, 251, 0.04) 0%, rgba(251, 251, 251, 0.04) 50%, rgba(229, 229, 229, 0.04) 50%, rgba(229, 229, 229, 0.04) 100%), radial-gradient(circle at 66% 97%, rgba(36, 36, 36, 0.04) 0%, rgba(36, 36, 36, 0.04) 50%, rgba(46, 46, 46, 0.04) 50%, rgba(46, 46, 46, 0.04) 100%), radial-gradient(circle at 86% 7%, rgba(40, 40, 40, 0.04) 0%, rgba(40, 40, 40, 0.04) 50%, rgba(200, 200, 200, 0.04) 50%, rgba(200, 200, 200, 0.04) 100%), radial-gradient(circle at 15% 16%, rgba(99, 99, 99, 0.04) 0%, rgba(99, 99, 99, 0.04) 50%, rgba(45, 45, 45, 0.04) 50%, rgba(45, 45, 45, 0.04) 100%), radial-gradient(circle at 75% 99%, rgba(243, 243, 243, 0.04) 0%, rgba(243, 243, 243, 0.04) 50%, rgba(37, 37, 37, 0.04) 50%, rgba(37, 37, 37, 0.04) 100%), linear-gradient(90deg, #33c4e7, #259cb9);
  color: #fff;
  padding: 1rem;
  margin-bottom: 1rem;
  outline: none;
  border-radius: 0.25rem;
  text-align: left;
  cursor: pointer;
  position: relative; }

p {
  text-align: left; }

details[open] summary ~ * {
  animation: sweep .5s ease-in-out; }

@keyframes sweep {
  0% {
    opacity: 0;
    margin-top: -10px; }
  100% {
    opacity: 1;
    margin-top: 0px; } }
details > summary::after {
  position: absolute;
  content: "+";
  right: 20px; }

details[open] > summary::after {
  position: absolute;
  content: "-";
  right: 20px; }

details > summary::-webkit-details-marker {
  display: none; }

details {
  padding: 0px 100px 0px; }
  @media only screen and (max-width: 56.25em) {
    details {
      padding: 0px 10px 0px; } }

.bosluk {
  margin-top: 20rem; }

.bosluk3 {
  margin-top: 3rem; }

.bosluk66 {
  margin-top: 6rem; }

.bosluk1 {
  margin-top: -1rem; }

.bosluk3ps {
  margin-top: -3rem; }

.bosluk04 {
  margin-top: -0.4rem; }

.bosluk3a {
  margin-top: 7rem; }

.bosluk3h {
  margin-top: -4rem; }
  @media only screen and (max-width: 56.25em) {
    .bosluk3h {
      margin-top: 0rem; } }

.boslukicon {
  margin-top: 3rem; }

.bosluk3rh {
  margin-top: -7rem; }
  @media only screen and (max-width: 56.25em) {
    .bosluk3rh {
      margin-top: -15rem; } }

.boslukalt {
  margin-top: -2rem; }
  @media only screen and (max-width: 56.25em) {
    .boslukalt {
      margin-top: 6rem; } }

.bosluk3sh {
  margin-top: -8rem; }
  @media only screen and (max-width: 56.25em) {
    .bosluk3sh {
      margin-top: 0rem; } }

.bosluk3h3 {
  margin-top: -20rem; }
  @media only screen and (max-width: 56.25em) {
    .bosluk3h3 {
      margin-top: 0rem; } }

.boslukhm {
  margin-top: 10rem; }

.bosluk-buyuk {
  margin-top: 18rem; }
  @media only screen and (max-width: 56.25em) {
    .bosluk-buyuk {
      margin-top: 40rem; } }

.bosluk333 {
  margin-top: 1rem; }

@media only screen and (max-width: 56.25em) {
  .bosluk4 {
    margin-top: -15rem; } }

@media only screen and (max-width: 56.25em) {
  .bosluk5 {
    margin-top: -40rem; } }

.bosluk6 {
  margin-top: -40rem; }
  @media only screen and (max-width: 56.25em) {
    .bosluk6 {
      margin-top: -40rem; } }

@media only screen and (max-width: 56.25em) {
  .bosluk7 {
    margin-top: 20rem; } }

.bosluk8 {
  margin-top: 9rem; }
  @media screen and (min-width: 1000px) and (max-width: 1024px) {
    .bosluk8 {
      margin-top: 18rem; } }
  @media screen and (min-width: 800px) and (max-width: 812px) {
    .bosluk8 {
      margin-top: 42rem; } }
  @media screen and (min-width: 760px) and (max-width: 768px) {
    .bosluk8 {
      margin-top: -12rem; } }

.bosluk9 {
  margin-top: 2rem; }

.bosluk10 {
  margin-top: 1rem; }

.h2-baslik-hizmetler-yorum {
  font-size: 3rem;
  font-weight: 700;
  display: inline-block;
  color: #1c437f; }
  .h2-baslik-hizmetler-yorum__yorum {
    font-size: 1.6rem;
    font-weight: 400;
    color: #616161;
    text-align: center;
    margin-bottom: 1rem; }
    .h2-baslik-hizmetler-yorum__yorum:not(:last-child) {
      margin-bottom: -7rem; }
      @media only screen and (max-width: 56.25em) {
        .h2-baslik-hizmetler-yorum__yorum:not(:last-child) {
          margin-bottom: -9rem;
          text-align: center; } }
    @media only screen and (max-width: 56.25em) {
      .h2-baslik-hizmetler-yorum__yorum {
        font-size: 3.5rem;
        text-align: left;
        padding: 3rem; } }
  @media only screen and (max-width: 56.25em) {
    .h2-baslik-hizmetler-yorum {
      font-size: 5rem;
      margin-top: 6rem; } }

.swiper-container {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1; }

.swiper-container-no-flexbox .swiper-slide {
  float: left; }

.swiper-container-vertical > .swiper-wrapper {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform,-webkit-transform;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

.swiper-container-android .swiper-slide, .swiper-wrapper {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.swiper-container-multirow > .swiper-wrapper {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto; }

.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform,-webkit-transform; }

.swiper-slide-invisible-blank {
  visibility: hidden; }

.swiper-container-autoheight, .swiper-container-autoheight .swiper-slide {
  height: auto; }

.swiper-container-autoheight .swiper-wrapper {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-transition-property: height,-webkit-transform;
  transition-property: height,-webkit-transform;
  -o-transition-property: transform,height;
  transition-property: transform,height;
  transition-property: transform,height,-webkit-transform; }

.swiper-container-3d {
  -webkit-perspective: 1200px;
  perspective: 1200px; }

.swiper-container-3d .swiper-cube-shadow, .swiper-container-3d .swiper-slide, .swiper-container-3d .swiper-slide-shadow-bottom, .swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d .swiper-slide-shadow-right, .swiper-container-3d .swiper-slide-shadow-top, .swiper-container-3d .swiper-wrapper {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d; }

.swiper-container-3d .swiper-slide-shadow-bottom, .swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d .swiper-slide-shadow-right, .swiper-container-3d .swiper-slide-shadow-top {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10; }

.swiper-container-3d .swiper-slide-shadow-left {
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }

.swiper-container-3d .swiper-slide-shadow-right {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }

.swiper-container-3d .swiper-slide-shadow-top {
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }

.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }

.swiper-container-wp8-horizontal, .swiper-container-wp8-horizontal > .swiper-wrapper {
  -ms-touch-action: pan-y;
  touch-action: pan-y; }

.swiper-container-wp8-vertical, .swiper-container-wp8-vertical > .swiper-wrapper {
  -ms-touch-action: pan-x;
  touch-action: pan-x; }

.swiper-button-next, .swiper-button-prev {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat; }

.swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
  opacity: .35;
  cursor: auto;
  pointer-events: none; }

.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  left: 10px;
  right: auto; }

.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  right: 10px;
  left: auto; }

.swiper-button-prev.swiper-button-white, .swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-next.swiper-button-white, .swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-prev.swiper-button-black, .swiper-container-rtl .swiper-button-next.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-next.swiper-button-black, .swiper-container-rtl .swiper-button-prev.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-lock {
  display: none; }

.swiper-pagination {
  position: absolute;
  text-align: center;
  -webkit-transition: .3s opacity;
  -o-transition: .3s opacity;
  transition: .3s opacity;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 10; }

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0; }

.swiper-container-horizontal > .swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: 10px;
  left: 0;
  width: 100%; }

.swiper-pagination-bullets-dynamic {
  overflow: hidden;
  font-size: 0; }

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  -webkit-transform: scale(0.33);
  -ms-transform: scale(0.33);
  transform: scale(0.33);
  position: relative; }

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1); }

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1); }

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
  -webkit-transform: scale(0.66);
  -ms-transform: scale(0.66);
  transform: scale(0.66); }

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
  -webkit-transform: scale(0.33);
  -ms-transform: scale(0.33);
  transform: scale(0.33); }

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
  -webkit-transform: scale(0.66);
  -ms-transform: scale(0.66);
  transform: scale(0.66); }

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
  -webkit-transform: scale(0.33);
  -ms-transform: scale(0.33);
  transform: scale(0.33); }

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: .2; }

button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer; }

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #007aff; }

.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0); }

.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 6px 0;
  display: block; }

.swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 8px; }

.swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  display: inline-block;
  -webkit-transition: .2s top,.2s -webkit-transform;
  transition: .2s top,.2s -webkit-transform;
  -o-transition: .2s transform,.2s top;
  transition: .2s transform,.2s top;
  transition: .2s transform,.2s top,.2s -webkit-transform; }

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 4px; }

.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  white-space: nowrap; }

.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  -webkit-transition: .2s left,.2s -webkit-transform;
  transition: .2s left,.2s -webkit-transform;
  -o-transition: .2s transform,.2s left;
  transition: .2s transform,.2s left;
  transition: .2s transform,.2s left,.2s -webkit-transform; }

.swiper-container-horizontal.swiper-container-rtl > .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  -webkit-transition: .2s right,.2s -webkit-transform;
  transition: .2s right,.2s -webkit-transform;
  -o-transition: .2s transform,.2s right;
  transition: .2s transform,.2s right;
  transition: .2s transform,.2s right,.2s -webkit-transform; }

.swiper-pagination-progressbar {
  background: rgba(0, 0, 0, 0.25);
  position: absolute; }

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #007aff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  transform-origin: left top; }

.swiper-container-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  -webkit-transform-origin: right top;
  -ms-transform-origin: right top;
  transform-origin: right top; }

.swiper-container-horizontal > .swiper-pagination-progressbar, .swiper-container-vertical > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0; }

.swiper-container-horizontal > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite, .swiper-container-vertical > .swiper-pagination-progressbar {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0; }

.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #fff; }

.swiper-pagination-progressbar.swiper-pagination-white {
  background: rgba(255, 255, 255, 0.25); }

.swiper-pagination-progressbar.swiper-pagination-white .swiper-pagination-progressbar-fill {
  background: #fff; }

.swiper-pagination-black .swiper-pagination-bullet-active {
  background: #000; }

.swiper-pagination-progressbar.swiper-pagination-black {
  background: rgba(0, 0, 0, 0.25); }

.swiper-pagination-progressbar.swiper-pagination-black .swiper-pagination-progressbar-fill {
  background: #000; }

.swiper-pagination-lock {
  display: none; }

.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  /* -ms-touch-action: none; */
  background: rgba(0, 0, 0, 0.1); }

.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%; }

.swiper-container-vertical > .swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%; }

.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0; }

.swiper-scrollbar-cursor-drag {
  cursor: move; }

.swiper-scrollbar-lock {
  display: none; }

.swiper-zoom-container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center; }

.swiper-zoom-container > canvas, .swiper-zoom-container > img, .swiper-zoom-container > svg {
  max-width: 100%;
  max-height: 100%;
  -o-object-fit: contain;
  object-fit: contain; }

.swiper-slide-zoomed {
  cursor: move; }

.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  -webkit-transform-origin: 50%;
  -ms-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  animation: swiper-preloader-spin 1s steps(12, end) infinite; }

.swiper-lazy-preloader:after {
  display: block;
  content: '';
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: 50%;
  background-size: 100%;
  background-repeat: no-repeat; }

.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E"); }

@-webkit-keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }
@keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }
.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000; }

.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out; }

.swiper-container-fade .swiper-slide {
  pointer-events: none;
  -webkit-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity; }

.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none; }

.swiper-container-fade .swiper-slide-active, .swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto; }

.swiper-container-cube {
  overflow: visible; }

.swiper-container-cube .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
  visibility: hidden;
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  width: 100%;
  height: 100%; }

.swiper-container-cube .swiper-slide .swiper-slide {
  pointer-events: none; }

.swiper-container-cube.swiper-container-rtl .swiper-slide {
  -webkit-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0; }

.swiper-container-cube .swiper-slide-active, .swiper-container-cube .swiper-slide-active .swiper-slide-active {
  pointer-events: auto; }

.swiper-container-cube .swiper-slide-active, .swiper-container-cube .swiper-slide-next, .swiper-container-cube .swiper-slide-next + .swiper-slide, .swiper-container-cube .swiper-slide-prev {
  pointer-events: auto;
  visibility: visible; }

.swiper-container-cube .swiper-slide-shadow-bottom, .swiper-container-cube .swiper-slide-shadow-left, .swiper-container-cube .swiper-slide-shadow-right, .swiper-container-cube .swiper-slide-shadow-top {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

.swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: .6;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  z-index: 0; }

.swiper-container-flip {
  overflow: visible; }

.swiper-container-flip .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1; }

.swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none; }

.swiper-container-flip .swiper-slide-active, .swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto; }

.swiper-container-flip .swiper-slide-shadow-bottom, .swiper-container-flip .swiper-slide-shadow-left, .swiper-container-flip .swiper-slide-shadow-right, .swiper-container-flip .swiper-slide-shadow-top {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

/* .swiper-container-coverflow .swiper-wrapper {
  -ms-perspective: 1200px; } */

.header {
  height: 12vh;
  width: 100%;
  background-size: cover;
  background-position: top;
  position: fixed;
  background: #fff;
  z-index: 2;
  /*position: relative;*/ }
  @media only screen and (max-width: 40em) {
    .header {
      height: 11vh; } }
  @media only screen and (max-width: 390px) {
    .header {
      height: 14vh; } }
  .header__logo-alani {
    position: absolute;
    top: 2rem;
    left: 4rem;
    z-index: 9; }

.header-slider {
  height: 94vh;
  background-size: cover;
  background-position: top;}
  @media only screen and (max-width: 40em) {}
  @media only screen and (max-width: 1024px) {
    .header-slider {
      height: 39vh; } }

.header__slider-alani {
  position: absolute;
  top: 40%;
  left: 42%;
  transform: translate(-30%, -30%);
  text-align: center; }
  @media only screen and (max-width: 40em) {
    .header__slider-alani {
      position: absolute;
      top: 20%;
      left: 35%;
      transform: translate(-30%, -30%);
      text-align: center; } }

.header-area {
  height: 36vh;
  background: #000000;
  background-size: cover;
  background-position: top;
  position: relative; }
  @media only screen and (max-width: 40em) {
    .header-area {
      height: 28vh; } }

.header-area-top {
  position: absolute;
  top: 70%;
  left: 47%;
  transform: translate(-30%, -30%);
  text-align: center;
  z-index: inherit; }
  @media only screen and (max-width: 40em) {
    .header-area-top {
      position: absolute;
      top: 41%;
      left: 40%;
      transform: translate(-30%, -30%);
      text-align: center; } }
  @media only screen and (max-width: 56.25em) {
    .header-area-top {
      position: absolute;
      top: 70%;
      left: 45%;
      transform: translate(-30%, -30%);
      text-align: center; } }

.header-area-top-titles {
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 43rem;
  backface-visibility: hidden; }
  @media only screen and (max-width: 56.25em) {
    .header-area-top-titles {
      font-size: 8rem; } }
  .header-area-top-titles--1 {
    display: block;
    font-size: 4rem;
    font-weight: 900;
    letter-spacing: 0.3rem;
    animation-name: solahareket;
    animation-duration: 1s;
    animation-timing-function: ease-in-out; }
    @media only screen and (max-width: 56.25em) {
      .header-area-top-titles--1 {
        font-size: 4rem; } }
    @media only screen and (max-width: 56.25em) {
      .header-area-top-titles--1 {
        font-size: 4rem; } }
    @media only screen and (max-width: 75em) {
      .header-area-top-titles--1 {
        font-size: 4rem; } }
  .header-area-top-titles--2 {
    display: block;
    font-size: 1.5rem;
    font-weight: 200;
    letter-spacing: 0rem;
    font-weight: 500;
    animation: sagahareket 1s ease-out; }
    @media only screen and (max-width: 56.25em) {
      .header-area-top-titles--2 {
        font-size: 3rem;
        font-weight: 500; } }

.header-area-top-blog {
  position: absolute;
  top: 70%;
  left: 48%;
  transform: translate(-30%, -30%);
  text-align: center; }
  @media only screen and (max-width: 40em) {
    .header-area-top-blog {
      position: absolute;
      top: 41%;
      left: 40%;
      transform: translate(-30%, -30%);
      text-align: center; } }
  @media only screen and (max-width: 56.25em) {
    .header-area-top-blog {
      position: absolute;
      top: 70%;
      left: 45%;
      transform: translate(-30%, -30%);
      text-align: center; } }

.header-contact-area {
  height: 68vh;
  background: #000;
  background-size: cover;
  background-position: top;
  position: relative;
  z-index: inherit; }
  @media only screen and (max-width: 40em) {
    .header-contact-area {
      height: 69vh; } }

.header-contact-area-top {
  position: absolute;
  top: 60%;
  left: 43%;
  transform: translate(-30%, -30%);
  text-align: center; }
  @media only screen and (max-width: 40em) {
    .header-contact-area-top {
      position: absolute;
      top: 41%;
      left: 40%;
      transform: translate(-30%, -30%);
      text-align: center; } }
  @media only screen and (max-width: 56.25em) {
    .header-contact-area-top {
      position: absolute;
      top: 60%;
      left: 40%;
      transform: translate(-30%, -30%);
      text-align: center; } }

.header-contact-area-top-titles {
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 5rem;
  backface-visibility: hidden; }
  @media only screen and (max-width: 56.25em) {
    .header-contact-area-top-titles {
      font-size: 5rem; } }
  .header-contact-area-top-titles--1 {
    display: block;
    font-size: 3rem;
    font-weight: 900;
    letter-spacing: 1rem;
    animation-name: solahareket;
    animation-duration: 1s;
    animation-timing-function: ease-in-out; }
    @media only screen and (max-width: 56.25em) {
      .header-contact-area-top-titles--1 {
        font-size: 4rem; } }
    @media only screen and (max-width: 56.25em) {
      .header-contact-area-top-titles--1 {
        font-size: 4rem; } }
    @media only screen and (max-width: 75em) {
      .header-contact-area-top-titles--1 {
        font-size: 4rem; } }
  .header-contact-area-top-titles--2 {
    display: block;
    font-size: 1.5rem;
    font-weight: 200;
    letter-spacing: 0rem;
    font-weight: 500;
    animation: sagahareket 1s ease-out; }
    @media only screen and (max-width: 56.25em) {
      .header-contact-area-top-titles--2 {
        font-size: 3rem;
        font-weight: 500; } }

.tablolar {
  margin-top: 1rem; }

.tablo {
  max-width: 119rem;
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }
  .tablo:not(:last-child) {
    margin-bottom: 8rem; }
    @media only screen and (max-width: 56.25em) {
      .tablo:not(:last-child) {
        margin-bottom: 6rem;
        margin-top: -16rem; } }
  @media only screen and (max-width: 56.25em) {
    .tablo {
      padding: 20rem 5rem; } }
  .tablo::after {
    content: "";
    display: table;
    clear: both; }
  .tablo [class^="tablo--"] {
    float: left; }
    .tablo [class^="tablo--"]:not(:last-child) {
      margin-right: 6rem; }
      @media only screen and (max-width: 56.25em) {
        .tablo [class^="tablo--"]:not(:last-child) {
          margin-right: 0;
          margin-bottom: 6rem; } }
    @media only screen and (max-width: 56.25em) {
      .tablo [class^="tablo--"] {
        width: 100% !important; } }
  .tablo .tablo--1-ve-2 {
    width: calc((100% - 6rem) / 2); }
  .tablo .tablo--1-ve-3 {
    width: calc((100% - 2 * 6rem) / 3); }
  .tablo .tablo--1-ve-4 {
    width: calc((100% - 3 * 6rem) /4); }
  .tablo .tablo--2-ve-3 {
    width: calc(2* ((100% - 2 * 6rem) /3) + 6rem); }
  .tablo .tablo--2-ve-4 {
    width: calc(2* ((100% - 3 * 6rem) /4) + 6rem); }
  .tablo .tablo--3-ve-4 {
    width: calc(3* ((100% - 3* 6rem) /4) + 2 * 6rem); }

.tabloozellik {
  max-width: 126rem;
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }
  .tabloozellik:not(:last-child) {
    margin-bottom: 8rem; }
    @media only screen and (max-width: 56.25em) {
      .tabloozellik:not(:last-child) {
        margin-bottom: 6rem;
        margin-top: -16rem; } }
  @media only screen and (max-width: 56.25em) {
    .tabloozellik {
      padding: 20rem 5rem; } }
  .tabloozellik::after {
    content: "";
    display: table;
    clear: both; }
  .tabloozellik [class^="tablo--"] {
    float: left; }
    .tabloozellik [class^="tablo--"]:not(:last-child) {
      margin-right: 3rem; }
      @media only screen and (max-width: 56.25em) {
        .tabloozellik [class^="tablo--"]:not(:last-child) {
          margin-right: 0;
          margin-bottom: 6rem; } }
    @media only screen and (max-width: 56.25em) {
      .tabloozellik [class^="tablo--"] {
        width: 100% !important; } }
  .tabloozellik .tablo--1-ve-2 {
    width: calc((100% - 6rem) / 2); }
  .tabloozellik .tablo--1-ve-3 {
    width: calc((100% - 2 * 6rem) / 3); }
  .tabloozellik .tablo--1-ve-4 {
    width: calc((100% - 3 * 6rem) /4); }
  .tabloozellik .tablo--2-ve-3 {
    width: calc(2* ((100% - 2 * 6rem) /3) + 6rem); }
  .tabloozellik .tablo--2-ve-4 {
    width: calc(2* ((100% - 3 * 6rem) /4) + 6rem); }
  .tabloozellik .tablo--3-ve-4 {
    width: calc(3* ((100% - 3* 6rem) /4) + 2 * 6rem); }

.tablohakkimizda {
  max-width: 114rem;
  margin: 0 auto; }
  .tablohakkimizda:not(:last-child) {
    margin-bottom: 8rem; }
    @media only screen and (max-width: 56.25em) {
      .tablohakkimizda:not(:last-child) {
        margin-bottom: -33rem;
        margin-top: -16rem; } }
  @media only screen and (max-width: 56.25em) {
    .tablohakkimizda {
      padding: 20rem 5rem; } }
  .tablohakkimizda::after {
    content: "";
    display: table;
    clear: both; }
  .tablohakkimizda [class^="tablo--"] {
    float: left; }
    .tablohakkimizda [class^="tablo--"]:not(:last-child) {
      margin-right: 6rem; }
      @media only screen and (max-width: 56.25em) {
        .tablohakkimizda [class^="tablo--"]:not(:last-child) {
          margin-right: 0;
          margin-bottom: 6rem; } }
    @media only screen and (max-width: 56.25em) {
      .tablohakkimizda [class^="tablo--"] {
        width: 100% !important; } }
  .tablohakkimizda .tablo--1-ve-2 {
    width: calc((100% - 6rem) / 2); }
  .tablohakkimizda .tablo--1-ve-3 {
    width: calc((100% - 2 * 6rem) / 3); }
  .tablohakkimizda .tablo--1-ve-4 {
    width: calc((100% - 3 * 6rem) /4); }
  .tablohakkimizda .tablo--2-ve-3 {
    width: calc(2* ((100% - 2 * 6rem) /3) + 6rem); }
  .tablohakkimizda .tablo--2-ve-4 {
    width: calc(2* ((100% - 3 * 6rem) /4) + 6rem); }
  .tablohakkimizda .tablo--3-ve-4 {
    width: calc(3* ((100% - 3* 6rem) /4) + 2 * 6rem); }

.tablohizmetler {
  max-width: 121rem;
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }
  @media only screen and (max-width: 1180px) {
    .tablohizmetler {
      max-width: 133rem; } }
  .tablohizmetler:not(:last-child) {
    margin-bottom: 8rem; }
    @media only screen and (max-width: 56.25em) {
      .tablohizmetler:not(:last-child) {
        margin-bottom: -33rem;
        margin-top: -16rem; } }
  @media only screen and (max-width: 56.25em) {
    .tablohizmetler {
      padding: 20rem 5rem; } }
  .tablohizmetler::after {
    content: "";
    display: table;
    clear: both; }
  .tablohizmetler [class^="tablo--"] {
    float: left; }
    .tablohizmetler [class^="tablo--"]:not(:last-child) {
      margin-right: 6rem; }
      @media only screen and (max-width: 56.25em) {
        .tablohizmetler [class^="tablo--"]:not(:last-child) {
          margin-right: 0;
          margin-bottom: 6rem; } }
    @media only screen and (max-width: 56.25em) {
      .tablohizmetler [class^="tablo--"] {
        width: 100% !important; } }
  .tablohizmetler .tablo--1-ve-2 {
    width: calc((100% - 6rem) / 2); }
  .tablohizmetler .tablo--1-ve-3 {
    width: calc((100% - 2 * 6rem) / 3); }
  .tablohizmetler .tablo--1-ve-4 {
    width: calc((100% - 3 * 6rem) /4); }
  .tablohizmetler .tablo--2-ve-3 {
    width: calc(2* ((100% - 2 * 6rem) /3) + 6rem); }
  .tablohizmetler .tablo--2-ve-4 {
    width: calc(2* ((100% - 3 * 6rem) /4) + 6rem); }
  .tablohizmetler .tablo--3-ve-4 {
    width: calc(3* ((100% - 3* 6rem) /4) + 2 * 6rem); }

.tablohizmetlerdetay {
  max-width: 114rem;
  margin: 0 auto; }
  .tablohizmetlerdetay:not(:last-child) {
    margin-bottom: 8rem; }
    @media only screen and (max-width: 56.25em) {
      .tablohizmetlerdetay:not(:last-child) {
        margin-bottom: -33rem;
        margin-top: -16rem; } }
  @media only screen and (max-width: 56.25em) {
    .tablohizmetlerdetay {
      padding: 20rem 5rem; } }
  .tablohizmetlerdetay::after {
    content: "";
    display: table;
    clear: both; }
  .tablohizmetlerdetay [class^="tablo--"] {
    float: left; }
    .tablohizmetlerdetay [class^="tablo--"]:not(:last-child) {
      margin-right: 6rem; }
      @media only screen and (max-width: 56.25em) {
        .tablohizmetlerdetay [class^="tablo--"]:not(:last-child) {
          margin-right: 0;
          margin-bottom: 6rem; } }
    @media only screen and (max-width: 56.25em) {
      .tablohizmetlerdetay [class^="tablo--"] {
        width: 100% !important; } }
  .tablohizmetlerdetay .tablo--1-ve-2 {
    width: calc((100% - 6rem) / 2); }
  .tablohizmetlerdetay .tablo--1-ve-3 {
    width: calc((100% - 2 * 6rem) / 3); }
  .tablohizmetlerdetay .tablo--1-ve-4 {
    width: calc((100% - 3 * 6rem) /4); }
  .tablohizmetlerdetay .tablo--2-ve-3 {
    width: calc(2* ((100% - 2 * 6rem) /3) + 6rem); }
  .tablohizmetlerdetay .tablo--2-ve-4 {
    width: calc(2* ((100% - 3 * 6rem) /4) + 6rem); }
  .tablohizmetlerdetay .tablo--3-ve-4 {
    width: calc(3* ((100% - 3* 6rem) /4) + 2 * 6rem); }

.tabloyorumlar {
  max-width: 114rem;
  margin: 0 auto; }
  .tabloyorumlar:not(:last-child) {
    margin-bottom: 8rem; }
    @media only screen and (max-width: 56.25em) {
      .tabloyorumlar:not(:last-child) {
        margin-bottom: -33rem;
        margin-top: -16rem; } }
  @media only screen and (max-width: 56.25em) {
    .tabloyorumlar {
      padding: 20rem 5rem; } }
  .tabloyorumlar::after {
    content: "";
    display: table;
    clear: both; }
  .tabloyorumlar [class^="tablo--"] {
    float: left; }
    .tabloyorumlar [class^="tablo--"]:not(:last-child) {
      margin-right: 6rem; }
      @media only screen and (max-width: 56.25em) {
        .tabloyorumlar [class^="tablo--"]:not(:last-child) {
          margin-right: 0;
          margin-bottom: 6rem; } }
    @media only screen and (max-width: 56.25em) {
      .tabloyorumlar [class^="tablo--"] {
        width: 100% !important; } }
  .tabloyorumlar .tablo--1-ve-2 {
    width: calc((100% - 6rem) / 2); }
  .tabloyorumlar .tablo--1-ve-3 {
    width: calc((100% - 2 * 6rem) / 3); }
  .tabloyorumlar .tablo--1-ve-4 {
    width: calc((100% - 3 * 6rem) /4); }
  .tabloyorumlar .tablo--2-ve-3 {
    width: calc(2* ((100% - 2 * 6rem) /3) + 6rem); }
  .tabloyorumlar .tablo--2-ve-4 {
    width: calc(2* ((100% - 3 * 6rem) /4) + 6rem); }
  .tabloyorumlar .tablo--3-ve-4 {
    width: calc(3* ((100% - 3* 6rem) /4) + 2 * 6rem); }

.tablo-footer {
  max-width: 149rem;
  margin: 0 auto; }
  .tablo-footer:not(:last-child) {
    margin-bottom: 8rem;
    margin-right: 0rem; }
    @media only screen and (max-width: 56.25em) {
      .tablo-footer:not(:last-child) {
        margin-bottom: 6rem;
        margin-top: -16rem; } }
  @media only screen and (max-width: 56.25em) {
    .tablo-footer {
      padding: 20rem 5rem; } }
  .tablo-footer::after {
    content: "";
    display: table;
    clear: both; }
  .tablo-footer [class^="tablo--"] {
    float: left; }
    .tablo-footer [class^="tablo--"]:not(:last-child) {
      margin-right: 6rem; }
      @media only screen and (max-width: 56.25em) {
        .tablo-footer [class^="tablo--"]:not(:last-child) {
          margin-right: 0;
          margin-bottom: 6rem; } }
    @media only screen and (max-width: 56.25em) {
      .tablo-footer [class^="tablo--"] {
        width: 100% !important; } }
  .tablo-footer .tablo--1-ve-2 {
    width: calc((100% - 6rem) / 2); }
  .tablo-footer .tablo--1-ve-3 {
    width: calc((100% - 2 * 6rem) / 3); }
  .tablo-footer .tablo--1-ve-4 {
    width: calc((100% - 3 * 6rem) /4); }
  .tablo-footer .tablo--2-ve-3 {
    width: calc(2* ((100% - 2 * 6rem) /3) + 6rem); }
  .tablo-footer .tablo--2-ve-4 {
    width: calc(2* ((100% - 3 * 6rem) /4) + 6rem); }
  .tablo-footer .tablo--3-ve-4 {
    width: calc(3* ((100% - 3* 6rem) /4) + 2 * 6rem); }

.tabloservices {
  max-width: 117rem;
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }
  @media only screen and (max-width: 1180px) {
    .tabloservices {
      max-width: 133rem; } }
  .tabloservices:not(:last-child) {
    margin-bottom: 8rem; }
    @media only screen and (max-width: 56.25em) {
      .tabloservices:not(:last-child) {
        margin-bottom: -33rem;
        margin-top: -16rem; } }
  @media only screen and (max-width: 56.25em) {
    .tabloservices {
      padding: 20rem 5rem; } }
  .tabloservices::after {
    content: "";
    display: table;
    clear: both; }
  .tabloservices [class^="tablo--"] {
    float: left; }
    .tabloservices [class^="tablo--"]:not(:last-child) {
      margin-right: 6rem; }
      @media only screen and (max-width: 56.25em) {
        .tabloservices [class^="tablo--"]:not(:last-child) {
          margin-right: 0;
          margin-bottom: 6rem; } }
    @media only screen and (max-width: 56.25em) {
      .tabloservices [class^="tablo--"] {
        width: 100% !important; } }
  .tabloservices .tablo--1-ve-2 {
    width: calc((100% - 6rem) / 2); }
  .tabloservices .tablo--1-ve-3 {
    width: calc((100% - 2 * 6rem) / 3); }
  .tabloservices .tablo--1-ve-4 {
    width: calc((100% - 3 * 6rem) /4); }
  .tabloservices .tablo--2-ve-3 {
    width: calc(2* ((100% - 2 * 6rem) /3) + 6rem); }
  .tabloservices .tablo--2-ve-4 {
    width: calc(2* ((100% - 3 * 6rem) /4) + 6rem); }
  .tabloservices .tablo--3-ve-4 {
    width: calc(3* ((100% - 3* 6rem) /4) + 2 * 6rem); }

/* Slider Buton */
.buton, .buton:link, .buton:visited {
  text-decoration: none;
  padding: 1.5rem 4rem;
  display: inline-block;
  transition: all 2s;
  position: relative;
  font-size: 1.4rem;
  font-weight: 600;
  border-radius: 100px;
  cursor: pointer; }
  @media only screen and (max-width: 56.25em) {
    .buton, .buton:link, .buton:visited {
      font-size: 3.6rem;
      padding: 3.5rem 6rem; } }
.buton:hover {
  transform: translateY(-0.3rem);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  border-radius: 100px;
  color: #666666; }
  .buton:hover::after {
    transform: scaleX(1.4) scaleY(1.6);
    opacity: 0; }
.buton:active, .buton:focus {
  outline: none;
  transform: translateY(-0.1rem);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  border: none; }
.buton--beyaz {
  background-color: #fff;
  color: #777;
  border-radius: 100px; }
  .buton--beyaz::after {
    background-color: #fff; }
.buton--siyah {
  background-color: #444;
  color: #fff; }
  .buton--siyah::after {
    background-color: #444; }
.buton--pembe-slider {
  background-color: #DE6FAA;
  color: #fff;
  margin-top: 22.5rem;
  left: -26rem; }
  @media only screen and (max-width: 1366px) {
    .buton--pembe-slider {
      background-color: #DE6FAA;
      color: #fff;
      margin-top: 29.5rem;
      left: -26rem; } }
  @media only screen and (max-width: 40em) {
    .buton--pembe-slider {
      margin-top: 205.5rem;
      left: -39rem; } }
  @media only screen and (max-width: 75em) {
    .buton--pembe-slider {
      /*left:-15px;*/
      margin-top: 205.5rem;
      left: -40rem; } }
  .buton--pembe-slider::after {
    background-color: #DE6FAA; }
.buton--pembe {
  background-color: #DE6FAA;
  color: #fff; }
  .buton--pembe::after {
    background-color: #DE6FAA; }
.buton--kirmizi {
  background-color: #292929;
  color: #fff;
  border: none; }
  .buton--kirmizi::after {
    background-color: #000000; }
.buton--kirmizislider {
  background-color: #292929;
  color: #fff;
  margin-right: 82rem; }
  .buton--kirmizislider::after {
    background-color: #000000; }
.buton::after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all .4s;
  border-radius: 100px; }
.buton--animasyon {
  animation: butonefekt .5s ease-out .75s;
  animation-fill-mode: backwards; }

.h-buton-link:link, .h-buton-link:visited {
  font-size: 1.7rem;
  color: #777;
  display: inline-block;
  text-decoration: none;
  border-bottom: 0.1rem solid #777;
  padding: .3rem;
  transition: all .2s; }
  @media only screen and (max-width: 56.25em) {
    .h-buton-link:link, .h-buton-link:visited {
      font-size: 4.7rem; } }
.h-buton-link:hover {
  background-color: #000000;
  color: #fff;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
  transform: translateY(-0.2rem); }
.h-buton-link:active {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  transform: translateY(0); }

.buton1, .buton1:link, .buton1:visited {
  text-transform: uppercase;
  text-decoration: none;
  padding: 1.5rem 4rem;
  display: inline-block;
  transition: all 2s;
  position: relative;
  font-size: 1.2rem;
  cursor: pointer;
  text-align: center; }
  @media only screen and (max-width: 56.25em) {
    .buton1, .buton1:link, .buton1:visited {
      font-size: 2.6rem;
      padding: 1.5rem 0rem; } }
  @media only screen and (max-width: 812px) {
    .buton1, .buton1:link, .buton1:visited {
      font-size: 3rem; } }
.buton1:hover {
  transform: translateY(-0.3rem);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2); }
  .buton1:hover::after {
    transform: scaleX(1.4) scaleY(1.6);
    opacity: 0; }
.buton1:active, .buton1:focus {
  outline: none;
  transform: translateY(-0.1rem);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2); }
.buton1--beyaz {
  background-color: #fff;
  color: #777;
  border-radius: 100px; }
  .buton1--beyaz::after {
    background-color: #fff; }
.buton1--siyah {
  background-color: #444;
  color: #fff; }
  .buton1--siyah::after {
    background-color: #444; }
.buton1--pembe-slider {
  background-color: #DE6FAA;
  color: #fff;
  margin-top: 22.5rem;
  left: -26rem; }
  @media only screen and (max-width: 1366px) {
    .buton1--pembe-slider {
      background-color: #DE6FAA;
      color: #fff;
      margin-top: 29.5rem;
      left: -26rem; } }
  @media only screen and (max-width: 40em) {
    .buton1--pembe-slider {
      margin-top: 205.5rem;
      left: -39rem; } }
  @media only screen and (max-width: 75em) {
    .buton1--pembe-slider {
      /*left:-15px;*/
      margin-top: 205.5rem;
      left: -40rem; } }
  .buton1--pembe-slider::after {
    background-color: #DE6FAA; }
.buton1--pembe {
  background-color: #DE6FAA;
  color: #fff; }
  .buton1--pembe::after {
    background-color: #DE6FAA; }
.buton1--kirmizi {
  background-color: #292929;
  color: #fff; }
  .buton1--kirmizi::after {
    background-color: #000000; }
.buton1--kirmizislider {
  background-color: #202020;
  color: #fff; }
  .buton1--kirmizislider::after {
    background-color: #202020; }
.buton1::after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all .4s; }
.buton1--animasyon {
  animation: butonefekt .5s ease-out .75s;
  animation-fill-mode: backwards; }

.buton-contact, .buton-contact:link, .buton-contact:visited {
  text-transform: uppercase;
  text-decoration: none;
  padding: 1.5rem 4rem;
  display: inline-block;
  transition: all 2s;
  position: relative;
  font-size: 1.2rem;
  border-radius: 100px;
  cursor: pointer; }
  @media only screen and (max-width: 56.25em) {
    .buton-contact, .buton-contact:link, .buton-contact:visited {
      font-size: 3.6rem;
      padding: 3.5rem 6rem; } }
.buton-contact:hover {
  transform: translateY(-0.3rem);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  border-radius: 100px;
  color: #ffffff; }
  .buton-contact:hover::after {
    transform: scaleX(1.4) scaleY(1.6);
    opacity: 0; }
.buton-contact:active, .buton-contact:focus {
  outline: none;
  transform: translateY(-0.1rem);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  border: none; }
.buton-contact--beyaz {
  background-color: #fff;
  color: #777;
  border-radius: 100px; }
  .buton-contact--beyaz::after {
    background-color: #fff; }
.buton-contact--siyah {
  background-color: #444;
  color: #fff; }
  .buton-contact--siyah::after {
    background-color: #444; }
.buton-contact--pembe-slider {
  background-color: #DE6FAA;
  color: #fff;
  margin-top: 22.5rem;
  left: -26rem; }
  @media only screen and (max-width: 1366px) {
    .buton-contact--pembe-slider {
      background-color: #DE6FAA;
      color: #fff;
      margin-top: 29.5rem;
      left: -26rem; } }
  @media only screen and (max-width: 40em) {
    .buton-contact--pembe-slider {
      margin-top: 205.5rem;
      left: -39rem; } }
  @media only screen and (max-width: 75em) {
    .buton-contact--pembe-slider {
      /*left:-15px;*/
      margin-top: 205.5rem;
      left: -40rem; } }
  .buton-contact--pembe-slider::after {
    background-color: #DE6FAA; }
.buton-contact--pembe {
  background-color: #DE6FAA;
  color: #fff; }
  .buton-contact--pembe::after {
    background-color: #DE6FAA; }
.buton-contact--kirmizi {
  background-color: #292929;
  color: #fff;
  border: none; }
  .buton-contact--kirmizi::after {
    background-color: #000000; }
.buton-contact--kirmizislider {
  background-color: #292929;
  color: #fff;
  margin-right: 82rem; }
  .buton-contact--kirmizislider::after {
    background-color: #000000; }
.buton-contact::after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all .4s;
  border-radius: 100px; }
.buton-contact--animasyon {
  animation: butonefekt .5s ease-out .75s;
  animation-fill-mode: backwards; }

.h-buton-link:link, .h-buton-link:visited {
  font-size: 1.7rem;
  color: #777;
  display: inline-block;
  text-decoration: none;
  border-bottom: 0.1rem solid #777;
  padding: .3rem;
  transition: all .2s; }
  @media only screen and (max-width: 56.25em) {
    .h-buton-link:link, .h-buton-link:visited {
      font-size: 4.7rem; } }
.h-buton-link:hover {
  background-color: #000000;
  color: #fff;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
  transform: translateY(-0.2rem); }
.h-buton-link:active {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  transform: translateY(0); }

.marka {
  height: 12.5rem;
  margin-left: 4rem;
  filter: alpha(opacity=40);
  opacity: 0.9;
  transition: all .2s ease-out;
  border: 3px solid #f9f9f9;
  border-radius: 0.3rem;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s; }
  @media only screen and (max-width: 56.25em) {
    .marka {
      height: 23.5rem;
      margin-left: 0rem; } }
  .marka:hover {
    height: 12.5rem;
    margin-left: 4rem;
    opacity: 1;
    transform: translateY(-0.3rem); }
    @media only screen and (max-width: 56.25em) {
      .marka:hover {
        height: 23.5rem; } }

.galeri {
  position: relative; }
  .galeri__gorsel {
    width: 100%;
    /*height: 55rem;*/
    outline-offset: 2rem;
    z-index: 10; }
    @media only screen and (max-width: 56.25em) {
      .galeri__gorsel {
        float: left;
        height: 100%; } }
    .galeri__gorsel--3 {
      left: 20%;
      margin-top: -1rem;
      /*top: 10rem;*/
      border-radius: 0.3rem; }
    .galeri__gorsel--8 {
      left: 20%;
      margin-top: -1rem;
      /*top: 10rem;*/ }
      @media only screen and (max-width: 1024px) {
        .galeri__gorsel--8 {
          margin-top: 1rem; } }
      @media only screen and (max-width: 56.25em) {
        .galeri__gorsel--8 {
          margin-top: 60rem; } }
      @media only screen and (max-width: 40em) {
        .galeri__gorsel--8 {
          margin-top: 58rem; } }
    .galeri__gorsel--4 {
      left: 20%;
      margin-top: -5rem;
      /*top: 10rem;*/ }
      @media only screen and (max-width: 56.25em) {
        .galeri__gorsel--4 {
          margin-top: 18rem; } }

.galeri1 {
  position: relative; }
  .galeri1__gorsel {
    width: 100%;
    /*height: 55rem;*/
    outline-offset: 2rem;
    z-index: 11; }
    @media only screen and (max-width: 56.25em) {
      .galeri1__gorsel {
        float: left;
        height: 100%; } }
    .galeri1__gorsel--3 {
      left: 20%;
      margin-top: -1rem;
      /*top: 10rem;*/
      border-radius: 0.3rem; }
    .galeri1__gorsel--8 {
      left: 20%;
      margin-top: -1rem;
      /*top: 10rem;*/ }
      @media only screen and (max-width: 1024px) {
        .galeri1__gorsel--8 {
          margin-top: 1rem; } }
      @media only screen and (max-width: 56.25em) {
        .galeri1__gorsel--8 {
          margin-top: 60rem; } }
      @media only screen and (max-width: 40em) {
        .galeri1__gorsel--8 {
          margin-top: 58rem; } }
    .galeri1__gorsel--4 {
      left: 20%;
      margin-top: -5rem;
      /*top: 10rem;*/ }
      @media only screen and (max-width: 56.25em) {
        .galeri1__gorsel--4 {
          margin-top: 18rem; } }

.galeriprojedetay {
  position: relative; }
  .galeriprojedetay__gorsel {
    width: 100%;
    height: 40rem;
    outline-offset: 2rem;
    z-index: 10;
    transition: all .2s; }
    @media only screen and (max-width: 56.25em) {
      .galeriprojedetay__gorsel {
        float: left;
        height: 100%;
        box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.2); } }
    .galeriprojedetay__gorsel--3 {
      left: 20%;
      margin-top: -1rem;
      /*top: 10rem;*/ }
      @media only screen and (max-width: 56.25em) {
        .galeriprojedetay__gorsel--3 {
          margin-top: .1rem; } }
    .galeriprojedetay__gorsel--4 {
      left: 20%;
      margin-top: -5rem;
      /*top: 10rem;*/ }
      @media only screen and (max-width: 56.25em) {
        .galeriprojedetay__gorsel--4 {
          margin-top: 11rem; } }
  .galeriprojedetay:hover .galeriprojedetay__gorsel:not(:hover) {
    transform: scale(0.95); }

.galeri {
  position: relative; }
  .galeri__popup {
    width: 100%;
    height: 100%;
    outline-offset: 2rem;
    z-index: 10;
    transition: all .2s; }
    @media only screen and (max-width: 56.25em) {
      .galeri__popup {
        float: left;
        height: 100%;
        box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.2); } }
    .galeri__popup--1 {
      margin-top: -1rem;
      /*top: 10rem;*/ }
      @media only screen and (max-width: 56.25em) {
        .galeri__popup--1 {
          margin-top: -1rem; } }
    .galeri__popup--2 {
      margin-top: -1rem;
      /*top: 10rem;*/ }
      @media only screen and (max-width: 56.25em) {
        .galeri__popup--2 {
          margin-top: -26rem; } }

.galeri-404 {
  position: relative; }
  .galeri-404__gorsel {
    width: 100%;
    height: 55rem;
    outline-offset: 2rem;
    z-index: 10;
    transition: all .2s; }
    @media only screen and (max-width: 56.25em) {
      .galeri-404__gorsel {
        float: left;
        height: 100%;
        box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.2); } }
    .galeri-404__gorsel--3 {
      left: 20%;
      margin-top: -8rem;
      /*top: 10rem;*/ }
      @media only screen and (max-width: 56.25em) {
        .galeri-404__gorsel--3 {
          margin-top: -1rem; } }
  .galeri-404:hover .galeri-404__gorsel:not(:hover) {
    transform: scale(0.95); }

.gallery {
  display: grid;
  grid-template-rows: repeat(1, 20vh);
  grid-template-columns: repeat(10, 20.3vh);
  background-color: #fff;
  border-radius: 10px;
  padding: 0.25em;
  cursor: zoom-in; }
  @media only screen and (max-width: 1024px) {
    .gallery {
      display: grid;
      grid-template-rows: repeat(1, 20vh);
      grid-template-columns: repeat(10, 7vh);
      background-color: #fff;
      border-radius: 10px;
      padding: 0.25em;
      cursor: zoom-in; } }
  @media only screen and (max-width: 40em) {
    .gallery {
      display: grid;
      grid-template-rows: repeat(2, 20vh);
      grid-template-columns: repeat(5, 20vw);
      background-color: #fff;
      border-radius: 10px;
      padding: 0.25em;
      cursor: zoom-in; } }
  @media only screen and (max-width: 56.25em) {
    .gallery {
      display: grid;
      grid-template-rows: none;
      grid-template-columns: none;
      background-color: #fff;
      border-radius: 10px;
      padding: 0.25em;
      cursor: zoom-in; } }

.gallery__img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: relative;
  box-shadow: 0 0 0 #0000;
  opacity: 1; }
  .gallery__img:first-child {
    border-radius: 10px 10px 0 0; }
  .gallery__img:last-child {
    border-radius: 0 0 10px 10px; }
  @media (min-width: 40em) and (max-width: 59.99em) {
    .gallery__img:first-child {
      border-radius: 10px 0 0 0; }
    .gallery__img:nth-child(5) {
      border-radius: 0 10px 0 0; }
    .gallery__img:nth-child(6) {
      border-radius: 0 0 10px 0; }
    .gallery__img:last-child {
      border-radius: 0 0 0 10px; } }
  @media (min-width: 60em) {
    .gallery__img:first-child {
      border-radius: 10px 0 0 10px; }
    .gallery__img:last-child {
      border-radius: 0 10px 10px 0; } }
  .gallery__img:hover {
    opacity: 1;
    z-index: 1;
    box-shadow: 1em 1em 1em #0004;
    filter: sepia(0%) hue-rotate(0deg);
    border-radius: 5px;
    width: 300%;
    height: 300%;
    left: -100%;
    top: -100%; }
    @media (min-width: 40em) {
      .gallery__img:hover {
        width: 250%;
        height: 500%;
        left: -75%;
        top: -200%; } }
    @media (min-width: 40em) and (orientation: portrait) {
      .gallery__img:hover {
        width: 300%;
        height: 300%;
        left: -100%;
        top: -100%; } }
    @media (min-width: 60em) {
      .gallery__img:hover {
        width: 350%;
        height: 150%;
        left: -75%;
        top: -25%; }
        .gallery__img:hover ~ img {
          left: 175%; } }
    @media (min-width: 60em) and (orientation: landscape) {
      .gallery__img:hover {
        width: 300%;
        height: 300%;
        left: -75%;
        top: -100%; }
        .gallery__img:hover ~ img {
          left: 100%; } }

.grid-container {
  columns: 4 200px;
  column-gap: 1.5rem;
  width: 90%;
  margin: 0 auto; }
  .grid-container div {
    width: 150px;
    
    margin: 0 1.5rem 1.5rem 0;
    display: inline-block;
    width: 100%;
    height: 40vh;
    border: solid 2px black;
    padding: 5px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    transition: all .25s ease-in-out; }
    /* .grid-container div:nth-child([1]){
      height: 40vh;
    } */
    /* .grid-container div:nth-child(even){
      height: 25vh;
    } */
    /* .grid-container div:hover img {
      filter: grayscale(0); } */
    .grid-container div:hover {
      border-color: #292929; }
    .grid-container div img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px;
      transition: all .25s ease-in-out; }

/* The Modal (background) */
.modal {
  display: none;
  position: fixed;
  z-index: 9999;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black; }

/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  /*width: 90%;*/
  max-width: 1200px; }

/* The Close Button */
.close {
  color: white;
  position: absolute;
  top: 10px;
  right: 25px;
  font-size: 35px;
  font-weight: bold; }

.close:hover,
.close:focus {
  color: #999;
  text-decoration: none;
  cursor: pointer; }

/* Hide the slides by default */
.mySlides {
  display: none; }

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none; }
  @media only screen and (min-width: 700px) and (max-width: 812px) {
    .prev,
    .next {
      margin-top: -50px; } }
  @media only screen and (max-width: 40em) {
    .prev,
    .next {
      margin-top: -207px; } }

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px; }

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8); }

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0; }

/* Caption text */
.caption-container {
  text-align: center;
  background-color: black;
  padding: 2px 16px;
  color: white; }

img.demo {
  opacity: 0.6; }

.active,
.demo:hover {
  opacity: 1; }

img.hover-shadow {
  transition: 0.3s; }

.hover-shadow:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); }

img.ha-car {
  margin-top: -223px; }

img.hm-mask-car {
  margin-top: -143px; }

.hero1 {
  position: relative;
  z-index: 7; }
  .hero1__gorsel {
    width: 100%;
    /*height: 55rem;*/
    outline-offset: 2rem;
    z-index: 7; }
    @media only screen and (max-width: 56.25em) {
      .hero1__gorsel {
        float: left;
        height: 100%; } }
    .hero1__gorsel--3 {
      left: 20%;
      margin-top: -1rem;
      /*top: 10rem;*/
      border-radius: 0.3rem; }
    .hero1__gorsel--8 {
      left: 20%;
      margin-top: -1rem;
      /*top: 10rem;*/ }
      @media only screen and (max-width: 1024px) {
        .hero1__gorsel--8 {
          margin-top: 1rem; } }
      @media only screen and (max-width: 56.25em) {
        .hero1__gorsel--8 {
          margin-top: 60rem; } }
      @media only screen and (max-width: 40em) {
        .hero1__gorsel--8 {
          margin-top: 58rem; } }
    .hero1__gorsel--4 {
      left: 20%;
      margin-top: -5rem;
      /*top: 10rem;*/ }
      @media only screen and (max-width: 56.25em) {
        .hero1__gorsel--4 {
          margin-top: 18rem; } }

.hero2 {
  position: relative;
  z-index: 8; }
  .hero2__gorsel {
    width: 100%;
    /*height: 55rem;*/
    outline-offset: 2rem;
    z-index: 6; }
    @media only screen and (max-width: 56.25em) {
      .hero2__gorsel {
        float: left;
        height: 100%; } }
    .hero2__gorsel--3 {
      left: 20%;
      margin-top: -1rem;
      /*top: 10rem;*/
      border-radius: 0.3rem; }
    .hero2__gorsel--8 {
      left: 20%;
      margin-top: -1rem;
      /*top: 10rem;*/ }
      @media only screen and (max-width: 1024px) {
        .hero2__gorsel--8 {
          margin-top: 1rem; } }
      @media only screen and (max-width: 56.25em) {
        .hero2__gorsel--8 {
          margin-top: 60rem; } }
      @media only screen and (max-width: 40em) {
        .hero2__gorsel--8 {
          margin-top: 58rem; } }
    .hero2__gorsel--4 {
      left: 20%;
      margin-top: -5rem;
      /*top: 10rem;*/ }
      @media only screen and (max-width: 56.25em) {
        .hero2__gorsel--4 {
          margin-top: 18rem; } }

.hero3 {
  position: relative;
  z-index: 5; }
  .hero3__gorsel {
    width: 100%;
    /*height: 55rem;*/
    outline-offset: 2rem;
    z-index: 5; }
    @media only screen and (max-width: 56.25em) {
      .hero3__gorsel {
        float: left;
        height: 100%; } }
    .hero3__gorsel--3 {
      left: 20%;
      margin-top: -1rem;
      /*top: 10rem;*/
      border-radius: 0.3rem; }
    .hero3__gorsel--8 {
      left: 20%;
      margin-top: -1rem;
      /*top: 10rem;*/ }
      @media only screen and (max-width: 1024px) {
        .hero3__gorsel--8 {
          margin-top: 1rem; } }
      @media only screen and (max-width: 56.25em) {
        .hero3__gorsel--8 {
          margin-top: 60rem; } }
      @media only screen and (max-width: 40em) {
        .hero3__gorsel--8 {
          margin-top: 58rem; } }
    .hero3__gorsel--4 {
      left: 20%;
      margin-top: -5rem;
      /*top: 10rem;*/ }
      @media only screen and (max-width: 56.25em) {
        .hero3__gorsel--4 {
          margin-top: 18rem; } }

.hero4 {
  position: relative;
  z-index: 8; }
  .hero4__gorsel {
    width: 100%;
    /*height: 55rem;*/
    outline-offset: 2rem;
    z-index: 11; }
    @media only screen and (max-width: 56.25em) {
      .hero4__gorsel {
        float: left;
        height: 100%; } }
    .hero4__gorsel--3 {
      left: 20%;
      margin-top: -1rem;
      /*top: 10rem;*/
      border-radius: 0.3rem; }
    .hero4__gorsel--8 {
      left: 20%;
      margin-top: -1rem;
      /*top: 10rem;*/ }
      @media only screen and (max-width: 1024px) {
        .hero4__gorsel--8 {
          margin-top: 1rem; } }
      @media only screen and (max-width: 56.25em) {
        .hero4__gorsel--8 {
          margin-top: 60rem; } }
      @media only screen and (max-width: 40em) {
        .hero4__gorsel--8 {
          margin-top: 58rem; } }
    .hero4__gorsel--4 {
      left: 20%;
      margin-top: -5rem;
      /*top: 10rem;*/ }
      @media only screen and (max-width: 56.25em) {
        .hero4__gorsel--4 {
          margin-top: 18rem; } }

.hero5 {
  position: relative;
  z-index: 8; }
  .hero5__gorsel {
    width: 100%;
    /*height: 55rem;*/
    outline-offset: 2rem;
    z-index: 11; }
    @media only screen and (max-width: 56.25em) {
      .hero5__gorsel {
        float: left;
        height: 100%; } }
    .hero5__gorsel--3 {
      left: 20%;
      margin-top: -1rem;
      /*top: 10rem;*/
      border-radius: 0.3rem; }
    .hero5__gorsel--8 {
      left: 20%;
      margin-top: -1rem;
      /*top: 10rem;*/ }
      @media only screen and (max-width: 1024px) {
        .hero5__gorsel--8 {
          margin-top: 1rem; } }
      @media only screen and (max-width: 56.25em) {
        .hero5__gorsel--8 {
          margin-top: 60rem; } }
      @media only screen and (max-width: 40em) {
        .hero5__gorsel--8 {
          margin-top: 58rem; } }
    .hero5__gorsel--4 {
      left: 20%;
      margin-top: -5rem;
      /*top: 10rem;*/ }
      @media only screen and (max-width: 56.25em) {
        .hero5__gorsel--4 {
          margin-top: 18rem; } }

.hero6 {
  position: relative;
  z-index: 8; }
  .hero6__gorsel {
    width: 100%;
    /*height: 55rem;*/
    outline-offset: 2rem;
    z-index: 11; }
    @media only screen and (max-width: 56.25em) {
      .hero6__gorsel {
        float: left;
        height: 100%; } }
    .hero6__gorsel--3 {
      left: 20%;
      margin-top: -1rem;
      /*top: 10rem;*/
      border-radius: 0.3rem; }
    .hero6__gorsel--8 {
      left: 20%;
      margin-top: -1rem;
      /*top: 10rem;*/ }
      @media only screen and (max-width: 1024px) {
        .hero6__gorsel--8 {
          margin-top: 1rem; } }
      @media only screen and (max-width: 56.25em) {
        .hero6__gorsel--8 {
          margin-top: 60rem; } }
      @media only screen and (max-width: 40em) {
        .hero6__gorsel--8 {
          margin-top: 58rem; } }
    .hero6__gorsel--4 {
      left: 20%;
      margin-top: -5rem;
      /*top: 10rem;*/ }
      @media only screen and (max-width: 56.25em) {
        .hero6__gorsel--4 {
          margin-top: 18rem; } }

.hero7 {
  position: relative;
  z-index: 6; }
  .hero7__gorsel {
    width: 100%;
    /*height: 55rem;*/
    outline-offset: 2rem;
    z-index: 11; }
    @media only screen and (max-width: 56.25em) {
      .hero7__gorsel {
        float: left;
        height: 100%; } }
    .hero7__gorsel--3 {
      left: 20%;
      margin-top: -1rem;
      /*top: 10rem;*/
      border-radius: 0.3rem; }
    .hero7__gorsel--8 {
      left: 20%;
      margin-top: -1rem;
      /*top: 10rem;*/ }
      @media only screen and (max-width: 1024px) {
        .hero7__gorsel--8 {
          margin-top: 1rem; } }
      @media only screen and (max-width: 56.25em) {
        .hero7__gorsel--8 {
          margin-top: 60rem; } }
      @media only screen and (max-width: 40em) {
        .hero7__gorsel--8 {
          margin-top: 58rem; } }
    .hero7__gorsel--4 {
      left: 20%;
      margin-top: -5rem;
      /*top: 10rem;*/ }
      @media only screen and (max-width: 56.25em) {
        .hero7__gorsel--4 {
          margin-top: 18rem; } }

.hero8 {
  position: relative;
  z-index: 6; }
  .hero8__gorsel {
    width: 100%;
    /*height: 55rem;*/
    outline-offset: 2rem;
    z-index: 11; }
    @media only screen and (max-width: 56.25em) {
      .hero8__gorsel {
        float: left;
        height: 100%; } }
    .hero8__gorsel--3 {
      left: 20%;
      margin-top: -1rem;
      /*top: 10rem;*/
      border-radius: 0.3rem; }
    .hero8__gorsel--8 {
      left: 20%;
      margin-top: -1rem;
      /*top: 10rem;*/ }
      @media only screen and (max-width: 1024px) {
        .hero8__gorsel--8 {
          margin-top: 1rem; } }
      @media only screen and (max-width: 56.25em) {
        .hero8__gorsel--8 {
          margin-top: 60rem; } }
      @media only screen and (max-width: 40em) {
        .hero8__gorsel--8 {
          margin-top: 58rem; } }
    .hero8__gorsel--4 {
      left: 20%;
      margin-top: -5rem;
      /*top: 10rem;*/ }
      @media only screen and (max-width: 56.25em) {
        .hero8__gorsel--4 {
          margin-top: 18rem; } }

.hero9 {
  position: relative;
  z-index: 7; }
  .hero9__gorsel {
    width: 100%;
    /*height: 55rem;*/
    outline-offset: 2rem;
    z-index: 11; }
    @media only screen and (max-width: 56.25em) {
      .hero9__gorsel {
        float: left;
        height: 100%; } }
    .hero9__gorsel--3 {
      left: 20%;
      margin-top: -1rem;
      /*top: 10rem;*/
      border-radius: 0.3rem; }
    .hero9__gorsel--8 {
      left: 20%;
      margin-top: -1rem;
      /*top: 10rem;*/ }
      @media only screen and (max-width: 1024px) {
        .hero9__gorsel--8 {
          margin-top: 1rem; } }
      @media only screen and (max-width: 56.25em) {
        .hero9__gorsel--8 {
          margin-top: 60rem; } }
      @media only screen and (max-width: 40em) {
        .hero9__gorsel--8 {
          margin-top: 58rem; } }
    .hero9__gorsel--4 {
      left: 20%;
      margin-top: -5rem;
      /*top: 10rem;*/ }
      @media only screen and (max-width: 56.25em) {
        .hero9__gorsel--4 {
          margin-top: 18rem; } }

.infobg1 {
  position: relative;
  z-index: 7; }
  .infobg1__gorsel {
    width: 100%;
    /*height: 55rem;*/
    outline-offset: 2rem;
    z-index: 11; }
    @media only screen and (max-width: 56.25em) {
      .infobg1__gorsel {
        float: left;
        height: 100%; } }
    .infobg1__gorsel--3 {
      left: 20%;
      margin-top: -1rem;
      /*top: 10rem;*/
      border-radius: 0.3rem; }
    .infobg1__gorsel--8 {
      left: 20%;
      margin-top: -1rem;
      /*top: 10rem;*/ }
      @media only screen and (max-width: 1024px) {
        .infobg1__gorsel--8 {
          margin-top: 1rem; } }
      @media only screen and (max-width: 56.25em) {
        .infobg1__gorsel--8 {
          margin-top: 60rem; } }
      @media only screen and (max-width: 40em) {
        .infobg1__gorsel--8 {
          margin-top: 58rem; } }
    .infobg1__gorsel--4 {
      left: 20%;
      margin-top: -5rem;
      /*top: 10rem;*/ }
      @media only screen and (max-width: 56.25em) {
        .infobg1__gorsel--4 {
          margin-top: 18rem; } }

.particlehero {
  position: relative;
  z-index: 0; }
  .particlehero__gorsel {
    width: 100%;
    /*height: 55rem;*/
    outline-offset: 2rem;
    z-index: 11; }
    @media only screen and (max-width: 56.25em) {
      .particlehero__gorsel {
        float: left;
        height: 100%; } }
    .particlehero__gorsel--3 {
      left: 20%;
      margin-top: -1rem;
      /*top: 10rem;*/
      border-radius: 0.3rem; }
    .particlehero__gorsel--8 {
      left: 20%;
      margin-top: -1rem;
      /*top: 10rem;*/ }
      @media only screen and (max-width: 1024px) {
        .particlehero__gorsel--8 {
          margin-top: 1rem; } }
      @media only screen and (max-width: 56.25em) {
        .particlehero__gorsel--8 {
          margin-top: 60rem; } }
      @media only screen and (max-width: 40em) {
        .particlehero__gorsel--8 {
          margin-top: 58rem; } }
    .particlehero__gorsel--4 {
      left: 20%;
      margin-top: -5rem;
      /*top: 10rem;*/ }
      @media only screen and (max-width: 56.25em) {
        .particlehero__gorsel--4 {
          margin-top: 18rem; } }

.hizmetler-kutu {
  background-color: #fff;
  padding: 2.5rem;
  text-align: center;
  font-size: 1.5rem;
  border-radius: 5.5rem 5.5rem;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  transition: transform .3s; }
  @media only screen and (max-width: 56.25em) {
    .hizmetler-kutu {
      padding: 2rem; } }
  .hizmetler-kutu--icon {
    font-size: 6rem;
    margin-bottom: .5rem;
    display: inline-block;
    background-image: linear-gradient(to right, #33c4e7, #0c52aa);
    /* -webkit-background-clip: text; */
    color: transparent; }
    @media only screen and (max-width: 56.25em) {
      .hizmetler-kutu--icon {
        margin-bottom: 0;
        font-size: 13rem; } }
  .hizmetler-kutu:hover {
    transform: translateY(-1.5rem) scale(1.03); }
  .hizmetler-kutu--yazi {
    text-align: center; }
    @media only screen and (max-width: 56.25em) {
      .hizmetler-kutu--yazi {
        font-size: 3.5rem; } }

.ozellik-kutu {
  padding: 2.5rem;
  text-align: center;
  font-size: 1.5rem;
  transition: transform .3s; }
  @media only screen and (max-width: 1180px) {
    .ozellik-kutu {
      text-align: center;
      align-items: center;
      clear: both;
      margin: auto; } }
  @media only screen and (max-width: 56.25em) {
    .ozellik-kutu {
      padding: 2rem; } }
  .ozellik-kutu--icon {
    font-size: 6rem;
    display: inline-block;
    background-image: linear-gradient(to right, #3b3b3b, #000000);
    /* -webkit-background-clip: text; */
    color: transparent; }
    @media only screen and (max-width: 56.25em) {
      .ozellik-kutu--icon {
        margin-bottom: 0;
        font-size: 13rem; } } 
  .ozellik-kutu--yazi {
    text-align: center;
    font-size: 1.4rem;
    font-weight: 400; }
    @media only screen and (max-width: 375px) {
      .ozellik-kutu--yazi {
        font-size: 3.3rem;
        width: 290px; } }
    @media only screen and (max-width: 56.25em) {
      .ozellik-kutu--yazi {
        font-size: 3.3rem; } }

img.haber-gorsel {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  border-image: linear-gradient(to right, #000000, #202020);
  border-image-slice: 1;
  border-radius: 0.3rem; }
  @media only screen and (max-width: 1024px) {
    img.haber-gorsel {
      width: 100%; } }
  @media only screen and (max-width: 1180px) {
    img.haber-gorsel {
      width: 100%; } }

@media only screen and (max-width: 1024px) {
  img.team-gorsel {
    width: 100%; } }

.ozellik-kutu-siyah {
  background-color: #040404;
  color: #fff;
  padding: 2.5rem;
  text-align: center;
  font-size: 1.5rem;
  border-radius: .3rem;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  transition: transform .3s; }
  @media only screen and (max-width: 56.25em) {
    .ozellik-kutu-siyah {
      padding: 2rem; } }
  .ozellik-kutu-siyah--icon {
    font-size: 6rem;
    margin-bottom: .5rem;
    display: inline-block;
    background-image: linear-gradient(to right, #ffffff, #cecece);
    /* -webkit-background-clip: text; */
    color: transparent; }
    @media only screen and (max-width: 56.25em) {
      .ozellik-kutu-siyah--icon {
        margin-bottom: 0;
        font-size: 13rem; } }
  .ozellik-kutu-siyah:hover {
    transform: translateY(-1.5rem) scale(1.03); }
  .ozellik-kutu-siyah--yazi {
    text-align: center; }
    @media only screen and (max-width: 56.25em) {
      .ozellik-kutu-siyah--yazi {
        font-size: 2.3rem; } }

.ozellik-kutu-beyaz {
  background-color: #040404;
  color: #fff;
  padding: 2.5rem;
  text-align: center;
  font-size: 1.5rem;
  border-radius: .3rem;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  transition: transform .3s; }
  @media only screen and (max-width: 56.25em) {
    .ozellik-kutu-beyaz {
      padding: 2rem; } }
  .ozellik-kutu-beyaz--icon {
    font-size: 6rem;
    margin-bottom: .5rem;
    display: inline-block;
    background-image: linear-gradient(to right, #ffffff, #cecece);
    /* -webkit-background-clip: text; */
    color: transparent; }
    @media only screen and (max-width: 56.25em) {
      .ozellik-kutu-beyaz--icon {
        margin-bottom: 0;
        font-size: 13rem; } }
  .ozellik-kutu-beyaz:hover {
    transform: translateY(-1.5rem) scale(1.03); }
  .ozellik-kutu-beyaz--yazi {
    text-align: center;
    color: #fff; }
    @media only screen and (max-width: 56.25em) {
      .ozellik-kutu-beyaz--yazi {
        font-size: 3rem; } }

.post-kutu {
  padding: 2.5rem;
  text-align: center;
  font-size: 1.5rem;
  transition: transform .3s;
  border-radius: 1rem;
  -moz-border-radius: 50px;
  width: 450px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background: #fff;
  box-shadow: 3px 3px 13px 0 rgba(0, 0, 0, 0.096);
  /* -webkit-transform: translateY(0%) translateZ(0px) scale(0.89);  */
}
  @media only screen and (max-width: 1024px) {
    .post-kutu {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap; } }
  @media only screen and (max-width: 40em) {
    .post-kutu {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap; } }
  @media only screen and (max-width: 56.25em) {
    .post-kutu {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap; } }
  @media only screen and (max-width: 1180px) {
    .post-kutu {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap; } }
  .post-kutu--icon {
    font-size: 6rem;
    margin-bottom: -0.5rem;
    display: inline-block;
    background-image: linear-gradient(to right, #3b3b3b, #000000);
    /* -webkit-background-clip: text; */
    color: transparent; }
    @media only screen and (max-width: 56.25em) {
      .post-kutu--icon {
        margin-bottom: 0;
        font-size: 13rem; } }
  .post-kutu--yazi {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 1rem; }
    @media only screen and (max-width: 56.25em) {
      .post-kutu--yazi {
        font-size: 3.5rem;
        text-align: center;
        padding: 2rem; } }

.yorum-kutu {
  padding: 2.5rem;
  text-align: center;
  font-size: 1.5rem;
  /*transition: transform .3s;*/
  border-radius: 5rem;
  -moz-border-radius: 50px;
  -webkit-border-radius: 500px;
  width: 450px;
  height: 453px; }
  @media only screen and (max-width: 1024px) {
    .yorum-kutu {
      width: auto;
      height: auto; } }
  @media only screen and (max-width: 40em) {
    .yorum-kutu {
      padding: 2rem;
      width: auto;
      height: auto;
      text-align: left; } }
  @media only screen and (max-width: 56.25em) {
    .yorum-kutu {
      padding: 2rem;
      width: auto;
      height: auto;
      text-align: left; } }
  .yorum-kutu--icon {
    font-size: 6rem;
    margin-bottom: -0.5rem;
    display: inline-block;
    background-image: linear-gradient(to right, #3b3b3b, #000000);
    /* -webkit-background-clip: text; */
    color: transparent; }
    @media only screen and (max-width: 56.25em) {
      .yorum-kutu--icon {
        margin-bottom: 0;
        font-size: 13rem; } }
  .yorum-kutu--yazi {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 1rem; }
    @media only screen and (max-width: 56.25em) {
      .yorum-kutu--yazi {
        font-size: 3.5rem;
        text-align: left;
        padding: 2rem; } }

.ozellik-kutu-iletisim {
  background-color: #fff;
  padding: 2.5rem;
  text-align: center;
  font-size: 1.5rem;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  border-radius: 0.3rem;
  /*transition: transform .3s;*/ }
  @media only screen and (max-width: 56.25em) {
    .ozellik-kutu-iletisim {
      padding: 2rem; } }
  .ozellik-kutu-iletisim--icon {
    font-size: 6rem;
    margin-bottom: -2rem;
    display: inline-block;
    background-image: linear-gradient(to right, #000000, #292929);
    /* -webkit-background-clip: text; */
    color: transparent; }
    @media only screen and (max-width: 56.25em) {
      .ozellik-kutu-iletisim--icon {
        margin-bottom: 0;
        font-size: 13rem; } }
  .ozellik-kutu-iletisim--yazi {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 400;
    color: #797979; }
    @media only screen and (max-width: 56.25em) {
      .ozellik-kutu-iletisim--yazi {
        font-size: 3.5rem; } }
  .ozellik-kutu-iletisim--yazi-iletisim {
    text-align: center;
    font-size: 1.6rem;
    font-weight: 400;
    color: #292929; }
    @media only screen and (max-width: 56.25em) {
      .ozellik-kutu-iletisim--yazi-iletisim {
        font-size: 3.5rem; } }

.baslik-4 {
  font-size: 1.8rem;
  text-transform: uppercase;
  font-weight: 600;
  color: #0f0f0f; }
  @media only screen and (max-width: 1024px) {
    .baslik-4 {
      width: auto; } }
  @media only screen and (max-width: 56.25em) {
    .baslik-4 {
      padding: 2rem;
      font-size: 5rem; } }
  .baslik-4--icon {
    font-size: 6rem;
    display: inline-block;
    background-image: linear-gradient(to right, #3b3b3b, #000000);
    /* -webkit-background-clip: text; */
    color: transparent; }
    @media only screen and (max-width: 56.25em) {
      .baslik-4--icon {
        margin-bottom: 0;
        font-size: 13rem; } }
  .baslik-4--yazi {
    text-align: center;
    font-size: 1.3rem;
    font-weight: 400; }
    @media only screen and (max-width: 375px) {
      .baslik-4--yazi {
        font-size: 3.1rem;
        width: 290px; } }
    @media only screen and (max-width: 56.25em) {
      .baslik-4--yazi {
        font-size: 3.3rem; } }

.ozellik-kutu-yorumlar {
  background-color: #fff;
  padding: 2.5rem;
  text-align: center;
  font-size: 1.5rem;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  transition: transform .3s;
  width: 385px;
  /*height: 330px;*/ }
  @media only screen and (max-width: 1024px) {
    .ozellik-kutu-yorumlar {
      width: auto; } }
  @media only screen and (max-width: 56.25em) {
    .ozellik-kutu-yorumlar {
      padding: 2rem; } }
  .ozellik-kutu-yorumlar--icon {
    font-size: 6rem;
    display: inline-block;
    background-image: linear-gradient(to right, #3b3b3b, #000000);
    /* -webkit-background-clip: text; */
    color: transparent; }
    @media only screen and (max-width: 56.25em) {
      .ozellik-kutu-yorumlar--icon {
        margin-bottom: 0;
        font-size: 13rem; } }
  .ozellik-kutu-yorumlar--yazi {
    text-align: center;
    font-size: 1.3rem;
    font-weight: 400; }
    @media only screen and (max-width: 375px) {
      .ozellik-kutu-yorumlar--yazi {
        font-size: 3.1rem;
        width: 290px; } }
    @media only screen and (max-width: 56.25em) {
      .ozellik-kutu-yorumlar--yazi {
        font-size: 3.3rem; } }

.services-kutu1 {
  /*background-color: $beyaz-renk;*/
  padding: 2.5rem;
  text-align: left;
  font-size: 1.5rem;
  /*box-shadow: 0 1.5rem 4rem rgba($siyah-renk, .15);*/
  transition: transform .3s;
  border-right: 1px solid #e2e2e2;
  /*height: 330px;*/ }
  @media only screen and (max-width: 1180px) {
    .services-kutu1 {
      text-align: center;
      align-items: center;
      clear: both;
      margin: auto; } }
  @media only screen and (max-width: 56.25em) {
    .services-kutu1 {
      padding: 2rem; } }
  .services-kutu1--icon {
    font-size: 6rem;
    display: inline-block;
    background-image: linear-gradient(to right, #3b3b3b, #000000);
    /* -webkit-background-clip: text; */
    color: transparent; }
    @media only screen and (max-width: 56.25em) {
      .services-kutu1--icon {
        margin-bottom: 0;
        font-size: 13rem; } }
  .services-kutu1--yazi {
    text-align: left;
    font-size: 1.4rem;
    font-weight: 400;
    color: #ffffff; }
    @media only screen and (max-width: 375px) {
      .services-kutu1--yazi {
        font-size: 3.3rem;
        width: 290px; } }
    @media only screen and (max-width: 56.25em) {
      .services-kutu1--yazi {
        font-size: 3.3rem;
        text-align: center; } }
  .services-kutu1--yazi-p {
    text-align: left;
    font-size: 1.4rem;
    font-weight: 400;
    color: #666666; }
    @media only screen and (max-width: 375px) {
      .services-kutu1--yazi-p {
        font-size: 3.3rem;
        width: 290px; } }
    @media only screen and (max-width: 56.25em) {
      .services-kutu1--yazi-p {
        font-size: 3.3rem;
        text-align: center; } }

.services-kutu2 {
  /*background-color: $beyaz-renk;*/
  padding: 2.5rem;
  text-align: center;
  font-size: 1.5rem;
  /*box-shadow: 0 1.5rem 4rem rgba($siyah-renk, .15);*/
  transition: transform .3s;
  /*border-right: 1px solid #e2e2e2;*/
  /*height: 330px;*/
  background: #f7f7f7;
  border-radius: 0.3rem; }
  @media only screen and (max-width: 1180px) {
    .services-kutu2 {
      text-align: center;
      align-items: center;
      clear: both;
      margin: auto; } }
  @media only screen and (max-width: 56.25em) {
    .services-kutu2 {
      padding: 2rem; } }
  .services-kutu2--icon {
    font-size: 6rem;
    display: inline-block;
    background-image: linear-gradient(to right, #3b3b3b, #000000);
    /* -webkit-background-clip: text; */
    color: transparent; }
    @media only screen and (max-width: 56.25em) {
      .services-kutu2--icon {
        margin-bottom: 0;
        font-size: 13rem; } }
  .services-kutu2--yazi {
    text-align: center;
    font-size: 1.6rem;
    font-weight: 400;
    color: #ffffff; }
    @media only screen and (max-width: 375px) {
      .services-kutu2--yazi {
        font-size: 3.3rem;
        width: 290px;
        width: 100%;
        flex-wrap: wrap; } }
    @media only screen and (max-width: 56.25em) {
      .services-kutu2--yazi {
        font-size: 3.3rem;
        width: 100%;
        flex-wrap: wrap; } }
  .services-kutu2--yazi1 {
    text-align: center;
    font-size: 1.6rem;
    font-weight: 400;
    color: #616161; }
    @media only screen and (max-width: 375px) {
      .services-kutu2--yazi1 {
        font-size: 3.3rem;
        width: 290px; } }
    @media only screen and (max-width: 56.25em) {
      .services-kutu2--yazi1 {
        font-size: 3.3rem;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%; } }

.services-kutu3 {
  /*background-color: $beyaz-renk;*/
  padding: 2.5rem;
  text-align: left;
  font-size: 1.5rem;
  /*box-shadow: 0 1.5rem 4rem rgba($siyah-renk, .15);*/
  transition: transform .3s;
  /*height: 330px;*/ }
  @media only screen and (max-width: 1180px) {
    .services-kutu3 {
      text-align: center;
      align-items: center;
      clear: both;
      margin: auto; } }
  @media only screen and (max-width: 56.25em) {
    .services-kutu3 {
      padding: 2rem; } }
  .services-kutu3--icon {
    font-size: 6rem;
    display: inline-block;
    background-image: linear-gradient(to right, #3b3b3b, #000000);
    /* -webkit-background-clip: text; */
    color: transparent; }
    @media only screen and (max-width: 56.25em) {
      .services-kutu3--icon {
        margin-bottom: 0;
        font-size: 13rem; } }
  .services-kutu3--yazi {
    text-align: left;
    font-size: 1.4rem;
    font-weight: 400;
    color: #ffffff; }
    @media only screen and (max-width: 375px) {
      .services-kutu3--yazi {
        font-size: 3.3rem;
        width: 232px; } }
    @media only screen and (max-width: 56.25em) {
      .services-kutu3--yazi {
        font-size: 3.3rem; } }

.container1 {
  background: #edeff0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center; }

.testimonial-card {
  min-height: 400px;
  border-radius: 10px;
  flex-flow: column;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background: #fff; }

.testimon-text {
  padding: 2.2em;
  line-height: 1.7em;
  position: relative;
  font-size: 18px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  color: #666666; }

.testimonimg {
  position: absolute;
  top: -50%;
  left: 35%;
  display: flex;
  justify-content: center;
  width: 30%; }

.testimonialimg {
  height: 140px;
  border-radius: 0 0 175px 175px;
  position: relative; }

.quote {
  font-size: 400%;
  float: right;
  opacity: .1;
  transform: rotate(10deg) translate(-10px, -40px);
  color: #4D3FA3; }

.person {
  color: #030301;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -100%);
  font-size: 1.5rem;
  font-weight: 600; }

.paketler {
  perspective: 150rem;
  -moz-perspective: 150rem;
  position: relative;
  z-index: 4; }
  .paketler__on {
    background-color: #000000;
    transition: all .8s ease;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    backface-visibility: hidden;
    overflow: hidden;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15); }
    .paketler__on--onyazi {
      background-color: #fff;
      padding: 26px 15px 25px 15px;
      height: 23rem;
      border-radius: 0.3rem; }
  .paketler:hover .paketler__on--arkayazi {
    transform: rotateY(1deg); }
  .paketler__gorsel {
    background-size: cover;
    height: 23rem;
    background-blend-mode: color;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem; }
    @media only screen and (max-width: 56.25em) {
      .paketler__gorsel {
        height: 44rem; } }
    /* .paketler__gorsel--1 {
      background-image: linear-gradient(to right bottom, #0e0e0e, #1c1a1a), url(../404.html); }
    .paketler__gorsel--2 {
      background-image: linear-gradient(to right bottom, #0e0e0e, #1c1a1a), url(../404.html); }
    .paketler__gorsel--3 {
      background-image: linear-gradient(to right bottom, #0e0e0e, #1c1a1a), url(../404.html); } */
  .paketler__baslik {
    font-size: 2.2rem;
    font-weight: 300;
    color: #fff;
    text-align: right;
    /*text-transform: uppercase;*/
    position: absolute;
    top: 15rem;
    margin-left: 8rem;
    width: 75%; }
  .paketler__baslik-span {
    padding: 1rem 1.5rem;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone; }
    .paketler__baslik-span--1 {
      background-image: linear-gradient(to right bottom, rgba(36, 36, 36, 0.85), rgba(22, 22, 22, 0.85)); }
    .paketler__baslik-span--2 {
      background-image: linear-gradient(to right bottom, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)); }
    .paketler__baslik-span--3 {
      background-image: linear-gradient(to right bottom, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)); }
  .paketler__icerik {
    padding: 3rem; }
    .paketler__icerik ul {
      list-style: none;
      width: 80%;
      margin: 0 auto; }
      .paketler__icerik ul li {
        text-align: center;
        font-size: 1.5rem;
        padding: 1rem; }
        .paketler__icerik ul li:not(:last-child) {
          border-bottom: 0.1rem solid #eee; }
        @media only screen and (max-width: 56.25em) {
          .paketler__icerik ul li {
            font-size: 3.5rem; } }
  .paketler__pr {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    text-align: center; }
  .paketler__pr-kutu {
    text-align: center;
    margin-bottom: 2rem;
    color: #fff; }
  .paketler__pr-yazi {
    font-size: 1.8rem;
    text-transform: uppercase;
    text-align: center;
    font-weight: 600; }
    @media only screen and (max-width: 56.25em) {
      .paketler__pr-yazi {
        font-size: 4.4rem; } }
  .paketler__pr-degeri {
    font-size: 6rem;
    font-weight: 100;
    text-align: center; }
    @media only screen and (max-width: 56.25em) {
      .paketler__pr-degeri {
        font-size: 11rem; } }
  @media only screen and (max-width: 56.25em), only screen and (hover: none) {
    .paketler {
      height: auto;
      background-color: #fff;
      box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15); }
      .paketler__on {
        height: auto;
        position: relative;
        box-shadow: none; }
        .paketler__on--arkayazi {
          transform: rotateY(0); }
      .paketler:hover .paketler__on--onyazi {
        transform: rotateY(0); }
      .paketler__icerik {
        padding: 1rem 3rem; }
      .paketler__pr {
        position: relative;
        top: 0;
        left: 0;
        transform: translate(0);
        width: 100%;
        padding: 7rem 4rem 4rem 4rem; }
      .paketler__pr-kutu {
        margin-bottom: 3rem; }
      .paketler__pr-degeri {
        font-size: 4rem; } }
    @media only screen and (max-width: 56.25em) and (max-width: 56.25em), only screen and (hover: none) and (max-width: 56.25em) {
      .paketler__pr-degeri {
        font-size: 11rem; } }

.referans {
  height: 12.5rem;
  margin-left: 4rem;
  filter: alpha(opacity=40);
  opacity: 0.4;
  transition: all .2s ease-out; }
  @media only screen and (max-width: 56.25em) {
    .referans {
      height: 23.5rem; } }
  .referans:hover {
    height: 12.5rem;
    margin-left: 4rem;
    opacity: 1;
    transform: translateY(-0.3rem); }
    @media only screen and (max-width: 56.25em) {
      .referans:hover {
        height: 23.5rem; } }

.paketler2 {
  perspective: 150rem;
  -moz-perspective: 150rem;
  position: relative;
  height: 29rem;
  z-index: 4; }
  .paketler2__on {
    height: 29.1rem;
    transition: all .8s ease;
    position: absolute;
    top: 0;
    left: 0;
    width: 99%;
    backface-visibility: hidden;
    overflow: hidden;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.13); }
    .paketler2__on--onyazi {
      border-radius: 0.3rem; }
    .paketler2__on--arkayazi {
      transform: rotateY(180deg);
      border-radius: 0.3rem; }
      .paketler2__on--arkayazi-1 {
        background: linear-gradient(to right bottom, rgba(0, 0, 0, 0.829), rgba(36, 36, 36, 0.884)), transparent url(../img/service1.jpg) center center/cover no-repeat scroll; }
      .paketler2__on--arkayazi-2 {
        background: linear-gradient(to right bottom, rgba(0, 0, 0, 0.829), rgba(36, 36, 36, 0.89)), transparent url(../img/service2.jpg) center center/cover no-repeat scroll; }
      .paketler2__on--arkayazi-3 {
        background: linear-gradient(to right bottom, rgba(0, 0, 0, 0.829), rgba(36, 36, 36, 0.932)), transparent url(../img/service3.jpg) center center/cover no-repeat scroll; }
      .paketler2__on--arkayazi-4 {
        background: linear-gradient(to right bottom, rgba(0, 0, 0, 0.829), rgba(36, 36, 36, 0.877)), transparent url(../img/service4.jpg) center center/cover no-repeat scroll; }
      .paketler2__on--arkayazi-5 {
        background: linear-gradient(to right bottom, rgba(0, 0, 0, 0.829), rgba(36, 36, 36, 0.897)), transparent url(../img/service5.jpg) center center/cover no-repeat scroll; }
      .paketler2__on--arkayazi-6 {
        background: linear-gradient(to right bottom, rgba(0, 0, 0, 0.829), rgba(36, 36, 36, 0.904)), transparent url(../img/service6.jpg) center center/cover no-repeat scroll; }
  .paketler2:hover .paketler2__on--onyazi {
    transform: rotateY(-180deg); }
  .paketler2:hover .paketler2__on--arkayazi {
    transform: rotateY(0); }
  .paketler2__gorsel {
    background-size: cover;
    height: 23rem; }
    @media only screen and (max-width: 56.25em) {
      .paketler2__gorsel {
        height: 44rem; } }
    .paketler2__gorsel--1 {
      height: 29rem;
      border: 1px solid #ffffff;
      border-radius: 8px;
      box-shadow: 0px 1px 51.3px 5.7px rgba(137, 143, 156, 0.151); }
    .paketler2__gorsel--2 {
      height: 29rem;
      border: 1px solid #ffffff;
      border-radius: 8px;
      box-shadow: 0px 1px 51.3px 5.7px rgba(137, 143, 156, 0.151); }
    .paketler2__gorsel--3 {
      height: 29rem;
      border: 1px solid #ffffff;
      border-radius: 8px;
      box-shadow: 0px 1px 51.3px 5.7px rgba(137, 143, 156, 0.151); }
    .paketler2__gorsel--4 {
      height: 29rem;
      border: 1px solid #ffffff;
      border-radius: 8px;
      box-shadow: 0px 1px 51.3px 5.7px rgba(137, 143, 156, 0.151); }
    .paketler2__gorsel--5 {
      height: 29rem;
      border: 1px solid #ffffff;
      border-radius: 8px;
      box-shadow: 0px 1px 51.3px 5.7px rgba(137, 143, 156, 0.151); }
    .paketler2__gorsel--6 {
      height: 29rem;
      border: 1px solid #ffffff;
      border-radius: 8px;
      box-shadow: 0px 1px 51.3px 5.7px rgba(137, 143, 156, 0.151); }
  .paketler2__baslik {
    font-size: 2.2rem;
    font-weight: 300;
    color: #fff;
    text-align: right;
    /*text-transform: uppercase;*/
    position: absolute;
    top: 15rem;
    margin-left: 8rem;
    width: 75%; }
  .paketler2__baslik-span {
    padding: 1rem 1.5rem;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone; }
    .paketler2__baslik-span--1 {
      background-image: linear-gradient(to right bottom, rgba(36, 36, 36, 0.85), rgba(22, 22, 22, 0.85)); }
    .paketler2__baslik-span--2 {
      background-image: linear-gradient(to right bottom, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)); }
    .paketler2__baslik-span--3 {
      background-image: linear-gradient(to right bottom, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)); }
  .paketler2__icerik {
    padding: 3rem; }
    .paketler2__icerik ul {
      list-style: none;
      width: 80%;
      margin: 0 auto; }
      .paketler2__icerik ul li {
        text-align: center;
        font-size: 1.5rem;
        padding: 1rem; }
        .paketler2__icerik ul li:not(:last-child) {
          border-bottom: 0.1rem solid #eee; }
        @media only screen and (max-width: 56.25em) {
          .paketler2__icerik ul li {
            font-size: 3.5rem; } }
  .paketler2__pr {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    text-align: center; }
  .paketler2__pr-kutu {
    text-align: center;
    margin-bottom: 2rem;
    color: #fff; }
  .paketler2__pr-yazi {
    font-size: 1.8rem;
    text-transform: uppercase;
    text-align: center;
    font-weight: 600; }
    @media only screen and (max-width: 56.25em) {
      .paketler2__pr-yazi {
        font-size: 4.4rem; } }
  .paketler2__pr-degeri {
    font-size: 6rem;
    font-weight: 100;
    text-align: center; }
    @media only screen and (max-width: 56.25em) {
      .paketler2__pr-degeri {
        font-size: 11rem; } }
  @media only screen and (max-width: 56.25em), only screen and (hover: none) {
    .paketler2 {
      height: auto;
      border-radius: .3rem;
      box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15); }
      .paketler2__on {
        height: auto;
        position: relative;
        box-shadow: none; }
        .paketler2__on--arkayazi {
          transform: rotateY(0); }
      .paketler2:hover .paketler2__on--onyazi {
        transform: rotateY(0); }
      .paketler2__icerik {
        padding: 1rem 3rem; }
      .paketler2__pr {
        position: relative;
        top: 0;
        left: 0;
        transform: translate(0);
        width: 100%;
        padding: 7rem 4rem 4rem 4rem; }
      .paketler2__pr-kutu {
        margin-bottom: 3rem; }
      .paketler2__pr-degeri {
        font-size: 4rem; } }
    @media only screen and (max-width: 56.25em) and (max-width: 56.25em), only screen and (hover: none) and (max-width: 56.25em) {
      .paketler2__pr-degeri {
        font-size: 11rem; } }

.referans {
  height: 12.5rem;
  margin-left: 4rem;
  filter: alpha(opacity=40);
  opacity: 0.4;
  transition: all .2s ease-out; }
  @media only screen and (max-width: 56.25em) {
    .referans {
      height: 23.5rem; } }
  .referans:hover {
    height: 12.5rem;
    margin-left: 4rem;
    opacity: 1;
    transform: translateY(-0.3rem); }
    @media only screen and (max-width: 56.25em) {
      .referans:hover {
        height: 23.5rem; } }

.paketler3 {
  perspective: 150rem;
  -moz-perspective: 150rem;
  position: relative;
  height: 52rem; }
  .paketler3__on {
    background-color: #000000;
    height: 52rem;
    transition: all .8s ease;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    backface-visibility: hidden;
    border-radius: .3rem;
    overflow: hidden;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15); }
    .paketler3__on--onyazi {
      background-color: #fff; }
    .paketler3__on--arkayazi {
      transform: rotateY(180deg); }
      .paketler3__on--arkayazi-1 {
        background-image: linear-gradient(to right bottom, #201b08, #000000); }
      .paketler3__on--arkayazi-2 {
        background-image: linear-gradient(to right bottom, #201b08, #000000); }
      .paketler3__on--arkayazi-3 {
        background-image: linear-gradient(to right bottom, #201b08, #000000); }
  .paketler3:hover .paketler3__on--onyazi {
    transform: rotateY(-180deg); }
  .paketler3:hover .paketler3__on--arkayazi {
    transform: rotateY(0); }
  .paketler3__gorsel {
    background-size: cover;
    height: 25rem;
    background-blend-mode: color;
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%); }
    @media only screen and (max-width: 56.25em) {
      .paketler3__gorsel {
        height: 44rem; } }
    /* .paketler3__gorsel--1 {
      background-image: linear-gradient(to right bottom, #201c09, #000000), url(../404.html);
      padding: 5px; }
    .paketler3__gorsel--2 {
      background-image: linear-gradient(to right bottom, #201c09, #000000), url(../404.html);
      padding: 5px; }
    .paketler3__gorsel--3 {
      background-image: linear-gradient(to right bottom, #201c09, #000000), url(../404.html); */
      /* padding: 5px; } */
  .paketler3__baslik {
    font-size: 2.2rem;
    font-weight: 300;
    color: #fff;
    text-align: right;
    /*text-transform: uppercase;*/
    position: absolute;
    top: 15rem;
    margin-left: 8rem;
    width: 75%; }
  .paketler3__baslik-span {
    padding: 1rem 1.5rem;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone; }
    .paketler3__baslik-span--1 {
      background-image: linear-gradient(to right bottom, rgba(36, 36, 36, 0.85), rgba(22, 22, 22, 0.85)); }
    .paketler3__baslik-span--2 {
      background-image: linear-gradient(to right bottom, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)); }
    .paketler3__baslik-span--3 {
      background-image: linear-gradient(to right bottom, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)); }
  .paketler3__icerik {
    padding: 3rem; }
    .paketler3__icerik ul {
      list-style: none;
      width: 80%;
      margin: 0 auto; }
      .paketler3__icerik ul li {
        text-align: center;
        font-size: 1.5rem;
        padding: 1rem; }
        .paketler3__icerik ul li:not(:last-child) {
          border-bottom: 0.1rem solid #eee;
          width: 100%;
          display: flex;
          justify-content: center;
          flex-wrap: wrap; }
        @media only screen and (max-width: 56.25em) {
          .paketler3__icerik ul li {
            font-size: 3.5rem;
            width: 100%;
            display: flex;
            justify-content: center;
            flex-wrap: wrap; } }
  .paketler3__pr {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    text-align: center; }
  .paketler3__pr-kutu {
    text-align: center;
    margin-bottom: 8rem;
    color: #fff; }
  .paketler3__pr-yazi {
    font-size: 1.8rem;
    text-transform: uppercase;
    text-align: center;
    font-weight: 600; }
    @media only screen and (max-width: 56.25em) {
      .paketler3__pr-yazi {
        font-size: 4.4rem; } }
  .paketler3__pr-degeri {
    font-size: 6rem;
    font-weight: 100;
    text-align: center; }
    @media only screen and (max-width: 56.25em) {
      .paketler3__pr-degeri {
        font-size: 11rem; } }
  @media only screen and (max-width: 56.25em), only screen and (hover: none) {
    .paketler3 {
      height: auto;
      border-radius: .3rem;
      background-color: #fff;
      box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15); }
      .paketler3__on {
        height: auto;
        position: relative;
        box-shadow: none; }
        .paketler3__on--arkayazi {
          transform: rotateY(0);
          clip-path: polygon(0 15%, 100% 0, 100% 100%, 0% 100%); }
      .paketler3:hover .paketler3__on--onyazi {
        transform: rotateY(0); }
      .paketler3__icerik {
        padding: 1rem 3rem; }
      .paketler3__pr {
        position: relative;
        top: 0;
        left: 0;
        transform: translate(0);
        width: 100%;
        padding: 7rem 4rem 4rem 4rem; }
      .paketler3__pr-kutu {
        margin-bottom: 3rem; }
      .paketler3__pr-degeri {
        font-size: 4rem; } }
    @media only screen and (max-width: 56.25em) and (max-width: 56.25em), only screen and (hover: none) and (max-width: 56.25em) {
      .paketler3__pr-degeri {
        font-size: 11rem; } }

.referans {
  height: 12.5rem;
  margin-left: 4rem;
  filter: alpha(opacity=40);
  opacity: 0.4;
  transition: all .2s ease-out; }
  @media only screen and (max-width: 56.25em) {
    .referans {
      height: 23.5rem; } }
  .referans:hover {
    height: 12.5rem;
    margin-left: 4rem;
    opacity: 1;
    transform: translateY(-0.3rem); }
    @media only screen and (max-width: 56.25em) {
      .referans:hover {
        height: 23.5rem; } }

.paketler4 {
  perspective: 150rem;
  -moz-perspective: 150rem;
  position: relative;
  height: 23rem;
  z-index: 4; }
  .paketler4__on {
    background-color: #000000;
    transition: all .8s ease;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    backface-visibility: hidden;
    overflow: hidden;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15); }
    .paketler4__on--onyazi {
      background-color: #fff;
      padding: 26px 15px 25px 15px;
      height: 23rem;
      border-radius: 0.3rem; }
    .paketler4__on--arkayazi {
      transform: rotateY(180deg);
      height: 23rem;
      border-radius: 0.3rem; }
      .paketler4__on--arkayazi-1 {
        background-color: #ffffff;
        transform: rotateX(180deg) rotateY(0);
        -webkit-transform: rotateX(180deg) rotateY(0); }
      .paketler4__on--arkayazi-2 {
        background-color: #ffffff;
        transform: rotateX(180deg) rotateY(0);
        -webkit-transform: rotateX(180deg) rotateY(0); }
      .paketler4__on--arkayazi-3 {
        background-color: #ffffff;
        transform: rotateX(180deg) rotateY(0);
        -webkit-transform: rotateX(180deg) rotateY(0); }
  .paketler4:hover .paketler4__on--onyazi {
    transform: rotateX(180deg) rotateY(0);
    -webkit-transform: rotateX(180deg) rotateY(0); }
  .paketler4:hover .paketler4__on--arkayazi {
    transform: rotateY(1deg); }
  .paketler4__gorsel {
    background-size: cover;
    height: 23rem;
    background-blend-mode: color;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem; }
    @media only screen and (max-width: 56.25em) {
      .paketler4__gorsel {
        height: 44rem; } }
    /* .paketler4__gorsel--1 {
      background-image: linear-gradient(to right bottom, #0e0e0e, #1c1a1a), url(../404.html); }
    .paketler4__gorsel--2 {
      background-image: linear-gradient(to right bottom, #0e0e0e, #1c1a1a), url(../404.html); }
    .paketler4__gorsel--3 {
      background-image: linear-gradient(to right bottom, #0e0e0e, #1c1a1a), url(../404.html); } */
  .paketler4__baslik {
    font-size: 2.2rem;
    font-weight: 300;
    color: #fff;
    text-align: right;
    /*text-transform: uppercase;*/
    position: absolute;
    top: 15rem;
    margin-left: 8rem;
    width: 75%; }
  .paketler4__baslik-span {
    padding: 1rem 1.5rem;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone; }
    .paketler4__baslik-span--1 {
      background-image: linear-gradient(to right bottom, rgba(36, 36, 36, 0.85), rgba(22, 22, 22, 0.85)); }
    .paketler4__baslik-span--2 {
      background-image: linear-gradient(to right bottom, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)); }
    .paketler4__baslik-span--3 {
      background-image: linear-gradient(to right bottom, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)); }
  .paketler4__icerik {
    padding: 3rem; }
    .paketler4__icerik ul {
      list-style: none;
      width: 80%;
      margin: 0 auto; }
      .paketler4__icerik ul li {
        text-align: center;
        font-size: 1.5rem;
        padding: 1rem; }
        .paketler4__icerik ul li:not(:last-child) {
          border-bottom: 0.1rem solid #eee; }
        @media only screen and (max-width: 56.25em) {
          .paketler4__icerik ul li {
            font-size: 3.5rem; } }
  .paketler4__pr {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    text-align: center; }
  .paketler4__pr-kutu {
    text-align: center;
    margin-bottom: 2rem;
    color: #fff; }
  .paketler4__pr-yazi {
    font-size: 1.8rem;
    text-transform: uppercase;
    text-align: center;
    font-weight: 600; }
    @media only screen and (max-width: 56.25em) {
      .paketler4__pr-yazi {
        font-size: 4.4rem; } }
  .paketler4__pr-degeri {
    font-size: 6rem;
    font-weight: 100;
    text-align: center; }
    @media only screen and (max-width: 56.25em) {
      .paketler4__pr-degeri {
        font-size: 11rem; } }
  @media only screen and (max-width: 56.25em), only screen and (hover: none) {
    .paketler4 {
      height: auto;
      background-color: #fff;
      box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15); }
      .paketler4__on {
        height: auto;
        position: relative;
        box-shadow: none; }
        .paketler4__on--arkayazi {
          transform: rotateY(0); }
      .paketler4:hover .paketler4__on--onyazi {
        transform: rotateY(0); }
      .paketler4__icerik {
        padding: 1rem 3rem; }
      .paketler4__pr {
        position: relative;
        top: 0;
        left: 0;
        transform: translate(0);
        width: 100%;
        padding: 7rem 4rem 4rem 4rem; }
      .paketler4__pr-kutu {
        margin-bottom: 3rem; }
      .paketler4__pr-degeri {
        font-size: 4rem; } }
    @media only screen and (max-width: 56.25em) and (max-width: 56.25em), only screen and (hover: none) and (max-width: 56.25em) {
      .paketler4__pr-degeri {
        font-size: 11rem; } }

.referans {
  height: 12.5rem;
  margin-left: 4rem;
  filter: alpha(opacity=40);
  opacity: 0.4;
  transition: all .2s ease-out; }
  @media only screen and (max-width: 56.25em) {
    .referans {
      height: 23.5rem; } }
  .referans:hover {
    height: 12.5rem;
    margin-left: 4rem;
    opacity: 1;
    transform: translateY(-0.3rem); }
    @media only screen and (max-width: 56.25em) {
      .referans:hover {
        height: 23.5rem; } }

.paketler5 {
  perspective: 150rem;
  -moz-perspective: 150rem;
  position: relative;
  height: 29rem;
  z-index: 4; }
  .paketler5__on {
    height: 29rem;
    transition: all .8s ease;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    backface-visibility: hidden;
    overflow: hidden;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.13); }
    .paketler5__on--onyazi {
      border-radius: 0.3rem; }
    .paketler5__on--arkayazi {
      transform: rotateY(180deg);
      border-radius: 0.3rem; }
      .paketler5__on--arkayazi-1 {
        background-color: #fff; }
      .paketler5__on--arkayazi-2 {
        background-color: #fff; }
      .paketler5__on--arkayazi-3 {
        background-color: #fff; }
      .paketler5__on--arkayazi-4 {
        background-color: #fff; }
      .paketler5__on--arkayazi-5 {
        background-color: #fff; }
      .paketler5__on--arkayazi-6 {
        background-color: #fff; }
  .paketler5:hover .paketler5__on--onyazi {
    transform: rotateY(-180deg); }
  .paketler5:hover .paketler5__on--arkayazi {
    transform: rotateY(0); }
  .paketler5__gorsel {
    background-size: cover;
    height: 23rem; }
    @media only screen and (max-width: 56.25em) {
      .paketler5__gorsel {
        height: 44rem; } }
    .paketler5__gorsel--1 {
      height: 29rem;
      border-radius: 8px;
      box-shadow: 0px 1px 51.3px 5.7px rgba(137, 143, 156, 0.151); }
    .paketler5__gorsel--2 {
      height: 29rem;
      border-radius: 8px;
      box-shadow: 0px 1px 51.3px 5.7px rgba(137, 143, 156, 0.151); }
    .paketler5__gorsel--3 {
      height: 29rem;
      border-radius: 8px;
      box-shadow: 0px 1px 51.3px 5.7px rgba(137, 143, 156, 0.151); }
    .paketler5__gorsel--4 {
      height: 29rem;
      border-radius: 8px;
      box-shadow: 0px 1px 51.3px 5.7px rgba(137, 143, 156, 0.151); }
    .paketler5__gorsel--5 {
      height: 29rem;
      border-radius: 8px;
      box-shadow: 0px 1px 51.3px 5.7px rgba(137, 143, 156, 0.151); }
    .paketler5__gorsel--6 {
      height: 29rem;
      border-radius: 8px;
      box-shadow: 0px 1px 51.3px 5.7px rgba(137, 143, 156, 0.151); }
  .paketler5__baslik {
    font-size: 2.2rem;
    font-weight: 300;
    color: #fff;
    text-align: right;
    /*text-transform: uppercase;*/
    position: absolute;
    top: 15rem;
    margin-left: 8rem;
    width: 75%; }
  .paketler5__baslik-span {
    padding: 1rem 1.5rem;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone; }
    .paketler5__baslik-span--1 {
      background-image: linear-gradient(to right bottom, rgba(36, 36, 36, 0.85), rgba(22, 22, 22, 0.85)); }
    .paketler5__baslik-span--2 {
      background-image: linear-gradient(to right bottom, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)); }
    .paketler5__baslik-span--3 {
      background-image: linear-gradient(to right bottom, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)); }
  .paketler5__icerik {
    padding: 3rem; }
    .paketler5__icerik ul {
      list-style: none;
      width: 80%;
      margin: 0 auto; }
      .paketler5__icerik ul li {
        text-align: center;
        font-size: 1.5rem;
        padding: 1rem; }
        .paketler5__icerik ul li:not(:last-child) {
          border-bottom: 0.1rem solid #eee; }
        @media only screen and (max-width: 56.25em) {
          .paketler5__icerik ul li {
            font-size: 3.5rem; } }
  .paketler5__pr {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    text-align: center; }
  .paketler5__pr-kutu {
    text-align: center;
    margin-bottom: 2rem;
    color: #fff; }
  .paketler5__pr-yazi {
    font-size: 1.8rem;
    text-transform: uppercase;
    text-align: center;
    font-weight: 600; }
    @media only screen and (max-width: 56.25em) {
      .paketler5__pr-yazi {
        font-size: 4.4rem; } }
  .paketler5__pr-degeri {
    font-size: 6rem;
    font-weight: 100;
    text-align: center; }
    @media only screen and (max-width: 56.25em) {
      .paketler5__pr-degeri {
        font-size: 11rem; } }
  @media only screen and (max-width: 56.25em), only screen and (hover: none) {
    .paketler5 {
      height: auto;
      border-radius: .3rem;
      box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15); }
      .paketler5__on {
        height: auto;
        position: relative;
        box-shadow: none; }
        .paketler5__on--arkayazi {
          transform: rotateY(0); }
      .paketler5:hover .paketler5__on--onyazi {
        transform: rotateY(0); }
      .paketler5__icerik {
        padding: 1rem 3rem; }
      .paketler5__pr {
        position: relative;
        top: 0;
        left: 0;
        transform: translate(0);
        width: 100%;
        padding: 7rem 4rem 4rem 4rem; }
      .paketler5__pr-kutu {
        margin-bottom: 3rem; }
      .paketler5__pr-degeri {
        font-size: 4rem; } }
    @media only screen and (max-width: 56.25em) and (max-width: 56.25em), only screen and (hover: none) and (max-width: 56.25em) {
      .paketler5__pr-degeri {
        font-size: 11rem; } }

.referans {
  height: 12.5rem;
  margin-left: 4rem;
  filter: alpha(opacity=40);
  opacity: 0.4;
  transition: all .2s ease-out; }
  @media only screen and (max-width: 56.25em) {
    .referans {
      height: 23.5rem; } }
  .referans:hover {
    height: 12.5rem;
    margin-left: 4rem;
    opacity: 1;
    transform: translateY(-0.3rem); }
    @media only screen and (max-width: 56.25em) {
      .referans:hover {
        height: 23.5rem; } }

.popup {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  transition: all .3s; }
  .popup:target {
    opacity: 1;
    visibility: visible; }
  .popup__kapat:link, .popup__kapat:visited {
    color: #000;
    position: absolute;
    top: 0rem;
    right: 1.5rem;
    font-size: 4rem;
    display: inline-block;
    transition: all .2s; }
    @media only screen and (max-width: 56.25em) {
      .popup__kapat:link, .popup__kapat:visited {
        font-size: 7rem; } }
  .popup__kapat:hover {
    color: #000000; }
  .popup__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30%;
    height: 50rem;
    background-color: #fff;
    box-shadow: 0 2rem 4rem rgba(0, 0, 0, 0.2); }
    @media only screen and (max-width: 56.25em) {
      .popup__content {
        width: 80%;
        height: 80rem; } }
    @media only screen and (max-width: 40em) {
      .popup__content {
        width: 80%;
        height: 80rem; } }

/* style member component */
.list-members {
  background-image: linear-gradient(to right, #000000, #0c0c0c);
  width: 80%;
  margin: 4% auto;
  display: flex;
  flex-wrap: wrap-reverse; }

.member {
  flex-basis: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between; }

.member-image {
  width: 50%;
  height: 100%;
  cursor: pointer;
  overflow: hidden;
  position: relative; }

.member-image img {
  width: 100%;
  height: 100%;
  transition: 1s; }

.member-image:hover img {
  transform: scale(1.1); }

.member-info {
  width: 50%;
  text-align: center; }

.member-info p {
  margin: 20px 0;
  text-align: center; }

/* style social link */
.social-link .fab {
  width: 35px;
  height: 35px;
  line-height: 35px;
  border: 1px solid #fff;
  margin: 0 7px;
  cursor: pointer;
  transition: transform .5s;
  background: #fff;
  color: #020202; }

.social-link .fab:hover {
  background: #020202;
  color: #fff;
  transform: translateY(-7px); }

/* Membuat segitiga */
.member-image::after {
  content: '';
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 15px solid  #020202;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%); }

/* Merubah posisi member-image dengan member-info */
@media screen and (min-width: 771px) {
  .member:nth-child(4n+3) .member-info,
  .member:nth-child(4n+4) .member-info {
    order: 1; }

  .member:nth-child(4n+3) .member-image,
  .member:nth-child(4n+4) .member-image {
    order: 2; }

  /* Merubah posisi sigitiga pada baris genap */
  .member:nth-child(4n+3) .member-image::after,
  .member:nth-child(4n+4) .member-image::after {
    left: 0;
    right: auto;
    transform: translateY(-50%) rotateZ(180deg); } }
/* Mobile Styles */
@media screen and (max-width: 770px) {
  .list-members {
    width: 95%; }

  .member {
    flex-basis: 100%;
    font-size: 14px; }

  .social-link .fab {
    width: 30px;
    height: 30px;
    line-height: 30px; }

  .member:nth-child(even) .member-info {
    order: 1; }

  .member:nth-child(even) .member-image {
    order: 2; }

  /* Merubah posisi sigitiga elemen genap */
  .member:nth-child(even) .member-image::after {
    left: 0;
    right: auto;
    transform: translateY(-50%) rotateZ(180deg); } }
p.r1 {
  color: white; }

h3.r1 {
  color: white; }

.open-btn {
  position: fixed;
  top: 26px;
  right: 109px;
  /* background: #ffffff url(../404.html) no-repeat 15px center; */
  background-size: 35px 35px;
  width: 72px;
  height: 65px;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  z-index: 8; }
  @media only screen and (max-width: 40em) {
    .open-btn {
      top: 11px;
      right: 54px; } }
  @media (min-width: 641px) and (max-width: 900px) {
    .open-btn {
      top: 31px;
      right: 75px; } }

#search-wrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  transition: all 0.4s;
  width: 100%;
  height: 100vh; }

#search-wrap.panelactive {
  opacity: 1;
  z-index: 888;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center; }

#search-wrap .search-area {
  display: none; }

#search-wrap.panelactive .search-area {
  display: block;
  width: 80%;
  position: relative; }

#search-wrap form {
  position: relative;
  height: 66px; }

#search-wrap input {
  /* -webkit-appearance: none; */
  outline: none;
  cursor: pointer;
  color: #666; }

#search-wrap input[type="text"] {
  width: 100%;
  padding: 20px;
  border: none;
  border-bottom: 2px solid #666;
  transition: all 0.5s;
  letter-spacing: 0.05em;
  font-size: 2rem;
  font-weight: 600; }

#search-wrap input[type="text"]:focus {
  background: #fff; }

#search-wrap input[type="submit"] {
  position: absolute;
  top: 0;
  right: 10px;
  /* background: url("../404.html") no-repeat 15px center; */
  background-size: 25px 25px;
  width: 60px;
  height: 60px;
  border: none; }

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
  cursor: pointer;
  width: 60px;
  height: 60px;
  background: #8a8a99; }

.close-btn span {
  display: inline-block;
  position: absolute;
  left: 14px;
  height: 3px;
  border-radius: 2px;
  background-color: #fff; }

.close-btn span:nth-of-type(1) {
  top: 21px;
  left: 16px;
  transform: translateY(6px) rotate(-135deg);
  width: 50%; }

.close-btn span:nth-of-type(2) {
  top: 32px;
  left: 16px;
  transform: translateY(-6px) rotate(135deg);
  width: 50%; }

.footer {
  background-image: linear-gradient(to right bottom, #000000, #131414);
  padding: 1rem 0 1rem;
  font-size: 1.4rem;
  color: #fff;
  /*margin-top: -16rem;*/ }
  @media only screen and (max-width: 56.25em) {
    .footer {
      padding: 18rem 0;
      text-align: center; } }
  .footer__menu {
    border-top: 0.1rem solid #fff;
    padding-top: 1rem;
    display: inline-block;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }
    @media only screen and (max-width: 56.25em) {
      .footer__menu {
        width: 100%;
        text-align: center; } }
  .footer__list {
    list-style: none; }
    @media only screen and (max-width: 56.25em) {
      .footer__list {
        font-size: 4rem; } }
  .footer__item {
    display: inline-block; }
  .footer__link:link, .footer__link:visited {
    color: #fff;
    font-weight: 600;
    text-decoration: none;
    display: inline-block;
    transition: all .2s; }
  .footer__link:hover, .footer__link:active {
    color: #fff; }
  .footer__copyright {
    border-top: 0.1rem solid #fff;
    color: #fff;
    padding-top: 2rem;
    width: 100%; }
    @media only screen and (max-width: 56.25em) {
      .footer__copyright {
        width: 100%;
        float: none;
        text-align: center;
        font-size: 2.7rem; } }
  .footer__sosyal {
    border-top: 0.1rem solid #fff;
    padding-top: 2rem;
    width: 48%;
    text-align: center; }
    @media only screen and (max-width: 56.25em) {
      .footer__sosyal {
        width: 100%;
        float: none;
        text-align: center; } }
  .footer__sosyallink:link, .footer__sosyallink:visited {
    color: #fff;
    font-weight: 600;
    text-decoration: none;
    display: inline-block;
    transition: all .2s; }
    @media only screen and (max-width: 56.25em) {
      .footer__sosyallink:link, .footer__sosyallink:visited {
        font-size: 5rem; } }
  .footer__sosyallink:hover, .footer__sosyallink:active {
    color: #fff;
    transform: translateY(-0.3rem); }

.footer-404 {
  background-image: linear-gradient(to right bottom, #33c4e7, #259cb9);
  padding: 5rem 0 1rem;
  font-size: 1.4rem;
  color: #fff;
  margin-top: -10rem; }
  @media only screen and (max-width: 56.25em) {
    .footer-404 {
      padding: 18rem 0;
      text-align: center; } }

.ozel {
  margin-left: 6rem; }
  .ozel-copyright {
    margin-left: 16.2rem;
    margin-top: -2rem; }
    @media only screen and (max-width: 40em) {
      .ozel-copyright {
        margin-left: 1rem; } }
    @media only screen and (max-width: 56.25em) {
      .ozel-copyright {
        margin-left: 1rem; } }
  @media only screen and (max-width: 40em) {
    .ozel {
      margin-left: 1rem; } }
  @media only screen and (max-width: 56.25em) {
    .ozel {
      margin-left: 1rem; } }

.iletisim-icon {
  background-image: linear-gradient(to right, #fdfdfd, #e0dfdf);
  /* -webkit-background-clip: text; */
  color: transparent;
  font-size: 5.5rem;
  transition: all .2s ease-in-out; }
  @media only screen and (max-width: 40em) {
    .iletisim-icon {
      font-size: 13.5rem; } }
  @media only screen and (max-width: 56.25em) {
    .iletisim-icon {
      font-size: 13.5rem; } }

.ozel-iletisim {
  margin-left: 16rem;
  margin-top: -3rem; }
  @media only screen and (max-width: 40em) {
    .ozel-iletisim {
      margin-left: 5rem;
      margin-top: 1rem;
      padding: .5rem 2rem .2rem 1.5rem; } }
  @media only screen and (max-width: 56.25em) {
    .ozel-iletisim {
      margin-left: 5rem;
      margin-top: 1rem;
      padding: .5rem 2rem .2rem 1.5rem; } }
  .ozel-iletisim__yazi {
    color: #e0e0e0;
    font-weight: 600;
    font-size: 4.2rem;
    /*margin-left: 6rem;*/
    margin-top: -3.5rem; }
    @media only screen and (max-width: 56.25em) {
      .ozel-iletisim__yazi {
        font-size: 5.2rem;
        margin-left: 0rem; } }
    @media only screen and (max-width: 40em) {
      .ozel-iletisim__yazi {
        font-size: 5.2rem;
        margin-left: 0rem; } }

p.footer-address {
  font-size: 1.4rem;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 3rem; }
  @media only screen and (max-width: 56.25em) {
    p.footer-address {
      width: 100%;
      text-align: center;
      font-size: 4rem; } }

p.footer-support {
  font-size: 1.4rem;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 3rem; }
  @media only screen and (max-width: 56.25em) {
    p.footer-support {
      width: 100%;
      text-align: center;
      font-size: 4rem; } }

/* CUSTOM CONTAINER */
@media (min-width: 1170px) {
  .container {
    max-width: 1100px; } }
@media (min-width: 1280px) {
  .container {
    max-width: 1260px; } }
/* CUSTOM CLASSES */
.overflow {
  overflow: hidden; }

.no-gutters {
  padding: 0;
  margin: 0; }

/* SPACING */
.no-spacing {
  margin: 0 !important;
  padding: 0 !important; }

.no-top-spacing {
  margin-top: 0 !important;
  padding-top: 0 !important; }

.no-bottom-spacing {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important; }

/* HAMBURGER MENU */
.hamburger-menu {
  width: 30px;
  height: 20px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  transition-duration: 500ms;
  -webkit-transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  cursor: pointer; }

.hamburger-menu span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #fff;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out; }

.hamburger-menu span:nth-child(1) {
  top: 0px;
  width: 100%; }

.hamburger-menu span:nth-child(2) {
  top: 9px;
  width: 22px; }

.hamburger-menu span:nth-child(3) {
  top: 18px;
  width: 100%; }

.hamburger-menu:hover span {
  width: 100% !important; }

.hamburger-menu.open span {
  width: 20px !important; }

.hamburger-menu.open span:nth-child(1) {
  top: 9px;
  right: 0;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
  width: 28px !important; }

.hamburger-menu.open span:nth-child(2) {
  opacity: 0;
  left: -20px; }

.hamburger-menu.open span:nth-child(3) {
  top: 9px;
  right: 0;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
  width: 28px !important; }

/* TOPBAR */
.topbar div {
  display: inline-block;
  font-size: 16px;
   }

.topbar div b {
  font-weight: 500;
  display: inline-block;
  margin-right: 6px;
  opacity: 0.5; }

.topbar div a {
  display: inline-block;
  color: #fff; }

/* NAVBAR */
.navbar {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2; }

.navbar .logo {
  margin-right: auto;
  padding: 30px 0;
  padding-right: 30px; }

.navbar .logo a {
  display: inline-block; }

.navbar .logo a img {
  height: 50px; }

.navbar .site-menu {
  margin: 0 auto; }

.navbar .site-menu ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0; }

.navbar .site-menu ul li {
  display: inline-block;
  margin: 0;
  padding: 0 15px;
  list-style: none;
  -webkit-transition: all .35s ease-in-out;
  -moz-transition: all .35s ease-in-out;
  -ms-transition: all .35s ease-in-out;
  -o-transition: all .35s ease-in-out;
  transition: all .35s ease-in-out; }

.navbar .site-menu ul li a {
  color: #fff;
  font-weight: 500; }

.navbar .site-menu ul li a:hover {
  text-decoration: none; }

.navbar .hamburger-menu {
  margin-left: auto; }

.navbar .navbar-button {
  margin-left: 30px; }

.navbar .navbar-button a {
  height: 70px;
  line-height: 70px;
  display: inline-block;
  background: #25aae2;
  color: #fff;
  padding: 0 50px;
  position: relative; }

.navbar .navbar-button a:before {
  content: "";
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.05;
  transition-duration: 1s;
  -webkit-transition-duration: 1s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1); }

.navbar .navbar-button a:hover {
  text-decoration: none;
  color: #fff; }

.navbar .navbar-button a:hover:before {
  width: 100%; }


body {
  margin: 0;
  padding: 0;
  
  font-size: 18px;
  color: #0d0d0d;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

/* LINKS */
a {
  color: #0d0d0d;
  -webkit-transition: all .35s ease-in-out;
  -moz-transition: all .35s ease-in-out;
  -ms-transition: all .35s ease-in-out;
  -o-transition: all .35s ease-in-out;
  transition: all .35s ease-in-out; }

a:hover {
  text-decoration: underline;
  color: #0d0d0d; }

/* HTML TAGS */
img {
  max-width: 100%; }

/* FORM ELEMENTS */
input[type="text"] {
  max-width: 100%;
  height: 70px;
  padding: 0 30px; }

input[type="email"] {
  max-width: 100%;
  height: 70px;
  padding: 0 30px; }

input[type="search"] {
  max-width: 100%;
  height: 70px;
  padding: 0 30px; }

input[type="password"] {
  max-width: 100%;
  height: 70px;
  padding: 0 30px; }

input[type="radio"] {
  width: 18px;
  height: 18px;
  display: inline-block;
  margin-right: 4px;
  transform: translateY(3px);
  appearance: none;
  background: #ededed;
  border-radius: 50%; }

input[type="radio"]:checked {
  border: 6px solid #0d0d0d; }

input[type="checkbox"] {
  width: 18px;
  height: 18px;
  display: inline-block;
  margin-right: 4px;
  transform: translateY(3px);
  appearance: none;
  background: #ededed; }

input[type="checkbox"]:checked {
  border: 6px solid #0d0d0d; }

textarea {
  max-width: 100%;
  height: 140px;
  padding: 30px; }

select {
  width: 420px;
  max-width: 100%;
  height: 70px;
  padding: 0 30px;
  border: 1px solid #cecece; }

select {
  /* -webkit-appearance: none; */
  /* -moz-appearance: none; */
  background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 30px) 34px, calc(100% - 25px) 34px, calc(100% - 3.5em) 20px;
  background-size: 5px 5px, 5px 5px, 1px 40px;
  background-repeat: no-repeat; }

select:focus {
  background-image: linear-gradient(45deg, gray 50%, transparent 50%), linear-gradient(135deg, transparent 50%, gray 50%), linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 25px) 34px, calc(100% - 30px) 34px, calc(100% - 3.5em) 20px;
  background-size: 5px 5px, 5px 5px, 1px 40px;
  background-repeat: no-repeat;
  border-color: gray;
  outline: 0; }

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000; }

input[type="submit"] {
  height: 70px;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  background: #0d0d0d;
  border: none;
  padding: 0 50px; }

button[type="submit"] {
  height: 70px;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  background: #0d0d0d;
  border: none;
  padding: 0 50px; }

button[type="submit"] i {
  display: inline-block;
  margin-right: 8px;
  font-size: 18px;
  transform: translateY(2px); }

/* CUSTOM CONTAINER */
@media (min-width: 1170px) {
  .container {
    max-width: 1100px; } }
@media (min-width: 1280px) {
  .container {
    max-width: 1260px; } }
/* CUSTOM CLASSES */
.overflow {
  overflow: hidden; }

.no-gutters {
  padding: 0;
  margin: 0; }

/* SPACING */
.no-spacing {
  margin: 0 !important;
  padding: 0 !important; }

.no-top-spacing {
  margin-top: 0 !important;
  padding-top: 0 !important; }

.no-bottom-spacing {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important; }

/* HAMBURGER MENU */
.hamburger-menu {
  width: 30px;
  height: 20px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  transition-duration: 500ms;
  -webkit-transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  cursor: pointer; }

.hamburger-menu span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #fff;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out; }

.hamburger-menu span:nth-child(1) {
  top: 0px;
  width: 100%; }

.hamburger-menu span:nth-child(2) {
  top: 9px;
  width: 22px; }

.hamburger-menu span:nth-child(3) {
  top: 18px;
  width: 100%; }

.hamburger-menu:hover span {
  width: 100% !important; }

.hamburger-menu.open span {
  width: 20px !important; }

.hamburger-menu.open span:nth-child(1) {
  top: 9px;
  right: 0;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
  width: 28px !important; }

.hamburger-menu.open span:nth-child(2) {
  opacity: 0;
  left: -20px; }

.hamburger-menu.open span:nth-child(3) {
  top: 9px;
  right: 0;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
  width: 28px !important; }

/* PAGE HEADER */
.page-header {
  width: 100%;
  height: 700px;
  max-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  background-size: cover !important;
  padding-top: 100px; }

.page-header:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; }

.page-header .container {
  position: relative;
  z-index: 1;
  color: #fff; }

.page-header .container h2 {
  width: 100%;
  display: block;
  font-size: 80px;
  margin-bottom: 0; }

.page-header .container p {
  width: 100%;
  display: block;
  margin-bottom: 50px;
  font-size: 20px; }

/* CONTENT SECTION */
.content-section {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 100px 0;
  position: relative; }

.content-section.left-white-spacing {
  position: relative; }

.content-section.left-white-spacing .container {
  position: relative;
  z-index: 1; }

.content-section.left-white-spacing:before {
  content: "";
  width: 30%;
  height: 100%;
  background: #fff;
  position: absolute;
  left: 0;
  top: 0; }

.content-section.bottom-dark-spacing {
  position: relative;
  padding-bottom: 0 !important; }

.content-section.bottom-dark-spacing .container {
  position: relative;
  z-index: 1; }

.content-section.bottom-dark-spacing:before {
  content: "";
  width: 30%;
  height: 100%;
  background: #fff;
  position: absolute;
  left: 0;
  top: 0; }

.content-section.bottom-dark-spacing:after {
  content: "";
  width: 100%;
  height: 140px;
  background: #0d0d0d;
  position: absolute;
  left: 0;
  bottom: 0; }

/* SECTION TITLE */
.section-title {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
  text-align: center; }

.section-title figure {
  width: 100%;
  display: block;
  margin-bottom: 15px; }

.section-title figure img {
  height: 40px; }

.section-title h6 {
  width: 100%;
  display: block; }

.section-title h2 {
  width: 100%;
  display: block;
  margin-bottom: 0;
  font-size: 70px;
  color: #25aae2; }

.section-title p {
  width: 100%;
  display: block;
  margin-bottom: 0;
  opacity: 0.7; }

/* TAB WRAPPER */
.tab-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap; }

.tab-wrapper .tab-nav {
  width: 25%;
  margin: 0;
  padding: 0;
  padding-right: 40px;
  position: relative;
  z-index: 1; }

.tab-wrapper .tab-nav li {
  width: 100%;
  display: block;
  margin-bottom: 10px;
  list-style: none; }

.tab-wrapper .tab-nav li.active a {
  width: calc(100% + 60px);
  margin-right: -60px;
  background: #25aae2;
  color: #fff; }

.tab-wrapper .tab-nav li.active a:hover {
  background: #25aae2; }

.tab-wrapper .tab-nav li a {
  width: 100%;
  display: block;
  background: #f4f4f4;
  padding: 25px;
  font-weight: 700; }

.tab-wrapper .tab-nav li a:hover {
  background: #f0f0f0;
  text-decoration: none; }

.tab-wrapper .tab-item {
  width: 75%;
  display: none; }

.tab-wrapper .tab-item.active-item {
  display: flex; }

.tab-wrapper .tab-item .tab-inner {
  width: 100%;
  display: flex;
  position: relative;
  background: #0d0d0d; }

.tab-wrapper .tab-item .tab-inner ul {
  width: calc(350px - 100px);
  height: 40vw;
  overflow: auto;
  float: left;
  color: #fff;
  margin: 40px;
  margin-left: 60px;
  padding: 0; }

.tab-wrapper .tab-item .tab-inner ul li {
  width: 100%;
  display: block;
  margin-bottom: 20px;
  padding: 0;
  list-style: none; }

.tab-wrapper .tab-item .tab-inner ul li span {
  width: 100%;
  display: block;
  color: #25aae2; }

.tab-wrapper .tab-item .tab-inner ul li h6 {
  width: 100%;
  display: block; }

.tab-wrapper .tab-item .tab-inner ul li small {
  width: 100%;
  display: block;
  
  opacity: 0.7; }

.tab-wrapper .tab-item .tab-inner figure {
  width: 100%;
  float: left;
  margin: 0; }

.tab-wrapper .tab-item .tab-inner figure img {
  width: 100%; }

/* COUNTER BOX */
.counter-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 40px;
  text-align: center; }

.counter-box figure {
  width: 100%;
  display: block;
  margin-bottom: 15px; }

.counter-box figure img {
  height: 90px; }

.counter-box .odometer {
  display: inline-block;
  line-height: 1;
  margin: 0 auto;
  font-size: 100px;
  font-weight: 500; }

.counter-box h6 {
  width: 100%;
  height: 26px;
  line-height: 26px;
  display: block;
  font-size: 22px;
  margin-bottom: 0;
  margin-top: 20px;
  color: #25aae2;
  position: relative; }

.counter-box h6:after {
  content: "";
  width: 100px;
  height: 4px;
  background: #25aae2;
  position: absolute;
  left: calc(50% - 50px);
  bottom: -40px; }

/* SERVICE BOX */
.service-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  margin: 15px 0;
  background: #0d0d0d; }

.service-box:before {
  content: "";
  width: 100%;
  height: 100%;
  background: #25aae2;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  -webkit-transition: all .35s ease-in-out;
  -moz-transition: all .35s ease-in-out;
  -ms-transition: all .35s ease-in-out;
  -o-transition: all .35s ease-in-out;
  transition: all .35s ease-in-out; }

.service-box:hover img {
  opacity: 0;
  transform: scale(1.1); }

.service-box:hover figcaption p {
  margin-bottom: 30px;
  margin-top: 10px;
  opacity: 0.7; }

.service-box:hover figcaption a {
  margin-bottom: 0;
  opacity: 1; }

.service-box:hover:before {
  transform: scale(1.1); }

.service-box * {
  -webkit-transition: all .35s ease-in-out;
  -moz-transition: all .35s ease-in-out;
  -ms-transition: all .35s ease-in-out;
  -o-transition: all .35s ease-in-out;
  transition: all .35s ease-in-out; }

.service-box img {
  width: 100%;
  position: relative; }

.service-box figcaption {
  width: 100%;
  position: absolute;
  left: 0;
  top: 50%;
  z-index: 1;
  transform: translateY(-50%);
  color: #fff;
  text-align: center; }

.service-box figcaption h6 {
  width: 100%;
  display: block;
  font-size: 36px;
  font-weight: 700;
  margin: 0;
  line-height: 1; }

.service-box figcaption p {
  width: 100%;
  display: block;
  padding: 0 10%;
  margin-bottom: -100px;
  opacity: 0; }

.service-box figcaption a {
  height: 70px;
  line-height: 68px;
  display: inline-block;
  border: 2px solid #fff;
  color: #fff;
  margin-bottom: -100px;
  opacity: 0;
  padding: 0 50px; }

.service-box figcaption a:hover {
  text-decoration: none;
  background: #fff;
  color: #25aae2; }

/* IMAGE OVERLAP BOX */
.image-overlap-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden; }

.image-overlap-box * {
  transition-duration: 1s;
  -webkit-transition-duration: 1s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1); }

.image-overlap-box:hover figure img {
  opacity: 0.3;
  transform: scale(1.05); }

.image-overlap-box:hover .content img {
  margin-top: 0;
  margin-bottom: -100px;
  opacity: 0.2;
  transform: scale(1.4); }

.image-overlap-box:hover .content p {
  margin-bottom: 40px;
  opacity: 1; }

.image-overlap-box:hover .content a {
  margin-bottom: 0;
  opacity: 1; }

.image-overlap-box figure {
  width: 100%;
  display: block;
  margin: 0;
  background: #25aae2; }

.image-overlap-box figure img {
  width: 100%; }

.image-overlap-box .content {
  width: 100%;
  position: absolute;
  left: 0;
  top: 50%;
  text-align: center;
  transform: translateY(-50%); }

.image-overlap-box .content img {
  height: 80px;
  display: inline-block;
  margin-bottom: 20px;
  margin-top: 100px; }

.image-overlap-box .content h6 {
  width: 100%;
  display: block;
  font-size: 30px;
  font-weight: 500;
  color: #fff; }

.image-overlap-box .content p {
  width: 100%;
  display: block;
  padding: 0 10%;
  color: #fff;
  margin-bottom: 0;
  opacity: 0; }

.image-overlap-box .content a {
  height: 70px;
  line-height: 68px;
  display: inline-block;
  border: 2px solid #fff;
  color: #fff;
  margin-bottom: -100px;
  opacity: 0;
  padding: 0 50px; }

.image-overlap-box .content a:hover {
  text-decoration: none;
  background: #fff;
  color: #25aae2; }

/* CUSTOM LIST */
.custom-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin-bottom: 20px; }

.custom-list li {
  width: 100%;
  display: block;
  margin-bottom: 15px;
  padding: 0;
  list-style: none;
  
  font-size: 20px; }

.custom-list li:last-child {
  margin-bottom: 0; }

.custom-list li:before {
  content: "\ea54";
  color: #25aae2;
  font-size: 16px;
  display: inline-block;
  margin-right: 12px; }

/* VIDEO */
.video {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
  background: #0d0d0d; }

.video img {
  width: 100%;
  -webkit-transition: all .35s ease-in-out;
  -moz-transition: all .35s ease-in-out;
  -ms-transition: all .35s ease-in-out;
  -o-transition: all .35s ease-in-out;
  transition: all .35s ease-in-out; }

.video:hover img {
  opacity: 0.8; }

.video a {
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  left: calc(50% - 60px);
  top: calc(50% - 60px);
  color: #25aae2;
  font-size: 30px; }

.video a:hover {
  text-decoration: none;
  transform: scale(1.1); }

/* IMAGE */
.image {
  width: 100%;
  display: block;
  margin-bottom: 30px; }

.image.spacing {
  margin: 40px 0; }

.image img {
  width: 100%; }

/* TEXT BOX */
.text-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap; }

.text-box h3 {
  width: 100%;
  display: block;
  font-size: 36px;
  font-weight: 700; }

.text-box h5 {
  width: 100%;
  display: block;
  color: #25aae2;
  font-weight: 500;
  margin-bottom: 0;
  font-size: 22px; }

.text-box p {
  width: 100%;
  display: block;
  margin-bottom: 20px; }

/* PASS BOX */
.col-lg-6:nth-child(1) .pass-box {
  border-right: 1px solid rgba(255, 255, 255, 0.1); }

.pass-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  text-align: center;
  color: #fff; }

.pass-box figure {
  width: 100%;
  display: block;
  margin-bottom: 30px; }

.pass-box figure img {
  height: 80px; }

.pass-box h6 {
  width: 100%;
  display: block;
  font-size: 50px;
  font-weight: 800; }

.pass-box p {
  width: 100%;
  display: block;
  padding: 0 20%;
  margin-bottom: 0; }

/* RECENT NEWS */
.recent-news {
  width: 100%;
  display: flex;
  flex-wrap: wrap; }

.recent-news:hover figure img {
  opacity: 0.3;
  transform: scale(1.05); }

.recent-news figure {
  width: 100%;
  display: block;
  margin: 0;
  background: #25aae2;
  position: relative;
  overflow: hidden; }

.recent-news figure img {
  width: 100%;
  -webkit-transition: all .35s ease-in-out;
  -moz-transition: all .35s ease-in-out;
  -ms-transition: all .35s ease-in-out;
  -o-transition: all .35s ease-in-out;
  transition: all .35s ease-in-out; }

.recent-news .content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 40px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top: none; }

.recent-news .content h3 {
  width: 100%;
  display: block;
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 34px; }

.recent-news .content h3 a {
  display: inline-block;
  color: #0d0d0d; }

.recent-news .content h3 a:hover {
  color: #25aae2;
  text-decoration: none; }

.recent-news .content p {
  width: 100%;
  display: block;
  margin-bottom: 25px;
  opacity: 0.7; }

.recent-news .content small {
  width: 100%;
  display: block;
  font-size: 16px; }

.recent-news .content small span {
  width: 5px;
  height: 5px;
  display: inline-block;
  border-radius: 50%;
  background: #0d0d0d;
  margin: 0 15px;
  transform: translateY(-3px); }

/* BLOG BOX */
.blog-box {
  width: 100%;
  display: block;
  position: relative;
  margin-bottom: 100px; }

.blog-box:last-child {
  margin-bottom: 0; }

.blog-box:hover .content h3 a {
  background-size: 100% 100%; }

.blog-box figure {
  width: 100%;
  display: block;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
  background: #25aae2; }

.blog-box figure img {
  width: 100%;
  max-width: inherit; }

.blog-box .content {
  width: 100%;
  height: 100%;
  display: block;
  background: #fff; }

.blog-box .content small {
  display: block;
  font-size: 15px;
  opacity: 0.6;
  margin-bottom: 10px;
  text-transform: uppercase; }

.blog-box .content h3 {
  width: 100%;
  display: block;
  margin-bottom: 20px;
  font-size: 56px;
  line-height: 1.1;
  font-weight: 800; }

.blog-box .content h3 a {
  display: block;
  color: #0d0d0d;
  -webkit-transition: all .35s ease-in-out;
  -moz-transition: all .35s ease-in-out;
  -ms-transition: all .35s ease-in-out;
  -o-transition: all .35s ease-in-out;
  transition: all .35s ease-in-out; }

.blog-box .content h3 a:hover {
  color: #25aae2;
  text-decoration: none; }

.blog-box .content .author {
  width: 100%;
  display: block;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 20px; }

.blog-box .content .author img {
  height: 70px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 15px; }

.blog-box .content .author b {
  font-weight: 500;
  opacity: 0.6; }

.blog-box .content h6 {
  font-size: 24px;
  line-height: 1.7;
  margin: 30px 0; }

.blog-box .content strong {
  font-weight: 600; }

.blog-box .content figure {
  margin: 30px 0; }

.blog-box .content blockquote {
  width: 100%;
  display: block;
  color: #25aae2;
  font-size: 26px;
  
  margin-bottom: 30px; }

.blog-box .content blockquote:before {
  content: "“";
  font-size: 100px;
  height: 50px;
  line-height: 0.9;
  display: block; }

.blog-box .content ul {
  padding-left: 20px; }

.blog-box .content ul li {
  margin: 4px 0; }

.blog-box .content .half-image {
  width: 50%;
  float: right;
  margin-left: 20px; }

.blog-box .content .full-width {
  width: calc(100% + 100px);
  float: left;
  margin-left: -50px;
  margin-right: -50px; }

/* SIDEBAR */
.sidebar {
  width: 100%;
  display: block;
  padding-left: 30px; }

.sidebar .widget {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #eee;
  padding: 40px;
  margin-bottom: 40px;
  position: relative; }

.sidebar .widget * {
  position: relative; }

.sidebar .widget .widget-title {
  width: 100%;
  display: block;
  position: relative;
  z-index: 1;
  font-weight: 800;
  letter-spacing: 1px;
  font-size: 22px;
  color: #0d0d0d;
  margin-bottom: 20px;
  padding-bottom: 20px; }

.sidebar .widget .widget-title:before {
  content: "";
  width: 100%;
  height: 2px;
  background: #25aae2;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1; }

.sidebar .widget form {
  width: 100%;
  display: block;
  margin-top: 10px; }

.sidebar .widget form input[type="submit"] {
  margin-top: 10px;
  background: #0d0d0d;
  color: #fff; }

.sidebar .widget .categories {
  width: 100%;
  display: block;
  margin: 0;
  padding: 0; }

.sidebar .widget .categories li {
  width: 100%;
  display: block;
  margin: 4px 0;
  padding: 0;
  list-style: none; }

.sidebar .widget .categories li a {
  color: #0d0d0d;
  font-size: 19px; }

.sidebar .widget .side-gallery {
  width: calc(100% + 4px);
  float: left;
  margin: 0 -2px;
  padding: 0; }

.sidebar .widget .side-gallery li {
  width: 50%;
  float: left;
  margin: 0;
  padding: 2px;
  list-style: none; }

/* BRANCH BOX */
.branch-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap; }

.branch-box h6 {
  width: 100%;
  display: block;
  font-weight: 800;
  font-size: 24px;
  color: #25aae2; }

.branch-box address {
  width: 100%;
  display: block;
  margin-bottom: 10px; }

.branch-box address b {
  width: 100%;
  display: block;
  margin-top: 5px;
  font-weight: 500; }

.branch-box a {
  display: inline-block;
  text-decoration: underline; }

.branch-box a:hover {
  text-decoration: none;
  color: #25aae2; }

/* MEMBER BOX */
.member-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  overflow: hidden;
  margin: 0; }

.member-box:hover figcaption {
  bottom: 0;
  transform: translateY(0); }

.member-box img {
  width: 100%;
  display: block; }

.member-box figcaption {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  left: 0;
  bottom: 120px;
  color: #fff;
  transform: translateY(100%);
  background: #25aae2;
  text-align: center;
  padding: 30px;
  -webkit-transition: all .35s ease-in-out;
  -moz-transition: all .35s ease-in-out;
  -ms-transition: all .35s ease-in-out;
  -o-transition: all .35s ease-in-out;
  transition: all .35s ease-in-out; }

.member-box figcaption h6 {
  width: 100%;
  display: block;
  font-size: 34px;
  font-weight: 700; }

.member-box figcaption small {
  width: 100%;
  display: block;
  margin-bottom: 15px; }

.member-box figcaption p {
  width: 100%;
  display: block;
  padding: 0 10%;
  opacity: 0.7; }

.member-box figcaption ul {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0; }

.member-box figcaption ul li {
  display: inline-block;
  margin: 0 7px;
  padding: 0;
  list-style: none; }

.member-box figcaption ul li a {
  color: #fff;
  float: left;
  font-size: 13px; }

/* CTA BOX */
.cta-box {
  width: 100%;
  max-width: 600px;
  display: inline-block;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 50px;
  text-align: center; }

.cta-box h2 {
  width: 100%;
  display: block;
  font-size: 50px;
  font-weight: 500; }

.cta-box p {
  width: 100%; }

.cta-box .custom-button {
  margin-top: 10px !important; }

/* TESTIMONIALS */
.testimonial {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  text-align: center; }

.testimonial blockquote {
  width: 100%;
  display: block;
  font-size: 32px;
   }

.testimonial p {
  width: 100%;
  display: block; }

.testimonial i {
  display: inline-block;
  margin: 0 3px;
  color: #25aae2; }

.testimonial h6 {
  width: 100%;
  display: block;
  margin: 0; }

.testimonial figure {
  width: 100%;
  display: block;
  margin: 0; }

.testimonial figure img {
  height: 440px; }

/* CONTACT BOX */
.contact-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
   }

.contact-box li {
  width: 100%;
  display: flex;
  margin-bottom: 15px;
  padding: 0;
  list-style: none;
  line-height: 1.2; }

.contact-box li:last-child {
  margin-bottom: 0; }

.contact-box li h6 {
  width: 100px;
  display: inline-block;
  color: #25aae2;
  margin: 0; }

.contact-box li span {
  display: inline-block; }

.contact-box li a {
  display: inline-block;
  text-decoration: underline; }

/* CONTACT FORM */
.contact-form {
  width: 100%;
  display: block;
  margin: 0;
  padding: 0; }

.contact-form .form-group {
  width: 100%;
  display: block; }

.contact-form .form-group:last-child {
  margin-bottom: 0; }

/* GOOGLE MAPS */
.google-maps {
  width: 100%;
  display: block;
  position: relative; }

.google-maps iframe {
  width: 100%;
  height: 500px;
  display: block;
  border: none;
  filter: grayscale(1);
  position: relative;
  z-index: 0; }

.google-maps .timetable {
  width: 340px;
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  left: 100px;
  top: 50%;
  transform: translateY(-50%);
  background: #0d0d0d;
  padding: 40px;
  margin: 0;
  z-index: 1; }

.google-maps .timetable li {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
  padding: 0;
  list-style: none; }

.google-maps .timetable li span {
  color: #fff; }

.google-maps .timetable li b {
  font-weight: 400;
  margin-left: auto;
  color: #25aae2; }

@media only screen and (min-width: 1300px) and (max-width: 2000px) {
  .navbar .hamburger-menu {
    display: none; } }
/* clearfix */
.owl-carousel .owl-wrapper:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: ".";
  line-height: 0; }

/* display none until init */
.owl-carousel {
  position: relative;
  display: none;
  width: 100%;
  /* -ms-touch-action: pan-y; */
 }

.owl-carousel .owl-wrapper {
  position: relative;
  display: none;
  /* -webkit-transform: translate3d(0px, 0px, 0px); */
 }

.owl-carousel .owl-wrapper-outer {
  position: relative;
  overflow: hidden;
  width: 100%; }

.owl-carousel .owl-wrapper-outer.autoHeight {
  -webkit-transition: height 500ms ease-in-out;
  -moz-transition: height 500ms ease-in-out;
  -ms-transition: height 500ms ease-in-out;
  -o-transition: height 500ms ease-in-out;
  transition: height 500ms ease-in-out; }

.owl-carousel2 .owl-wrapper-outer {
  position: relative;
  overflow: hidden;
  width: 100%;
  display: inline-flex; }

.owl-carousel2 .owl-wrapper-outer.autoHeight {
  -webkit-transition: height 500ms ease-in-out;
  -moz-transition: height 500ms ease-in-out;
  -ms-transition: height 500ms ease-in-out;
  -o-transition: height 500ms ease-in-out;
  transition: height 500ms ease-in-out; }

.owl-carousel .owl-item {
  float: left; }

.owl-controls .owl-page,
.owl-controls .owl-buttons div {
  cursor: pointer; }

.owl-controls {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

/* mouse grab icon */
/* .grabbing {
  cursor: url(../404.html) 8 8, move; } */

/* fix */
.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-animation: goDown .6s ease both;
  -moz-animation: goDown .6s ease both;
  animation: goDown .6s ease both;
  /* -webkit-backface-visibility: hidden; */
  /* -moz-backface-visibility: hidden; */
  /* -ms-backface-visibility: hidden; */
  display: inline-table; }

.owl-theme .owl-controls {
  margin-top: 10px;
  text-align: center; }

/* 
 *  Owl Carousel CSS3 Transitions 
 *  v1.3.2
 */
.owl-origin {
  -webkit-perspective: 1200px;
  -webkit-perspective-origin-x: 50%;
  -webkit-perspective-origin-y: 50%;
  -moz-perspective: 1200px;
  -moz-perspective-origin-x: 50%;
  -moz-perspective-origin-y: 50%;
  perspective: 1200px; }

/* fade */
.owl-fade-out {
  z-index: 10;
  -webkit-animation: fadeOut .7s both ease;
  -moz-animation: fadeOut .7s both ease;
  animation: fadeOut .7s both ease; }

.owl-fade-in {
  -webkit-animation: fadeIn .7s both ease;
  -moz-animation: fadeIn .7s both ease;
  animation: fadeIn .7s both ease; }

/* backSlide */
.owl-backSlide-out {
  -webkit-animation: backSlideOut 1s both ease;
  -moz-animation: backSlideOut 1s both ease;
  animation: backSlideOut 1s both ease; }

.owl-backSlide-in {
  -webkit-animation: backSlideIn 1s both ease;
  -moz-animation: backSlideIn 1s both ease;
  animation: backSlideIn 1s both ease; }

/* goDown */
.owl-goDown-out {
  -webkit-animation: scaleToFade .7s ease both;
  -moz-animation: scaleToFade .7s ease both;
  animation: scaleToFade .7s ease both; }

.owl-goDown-in {
  -webkit-animation: goDown .6s ease both;
  -moz-animation: goDown .6s ease both;
  animation: goDown .6s ease both; }

/* scaleUp */
.owl-fadeUp-in {
  -webkit-animation: scaleUpFrom .5s ease both;
  -moz-animation: scaleUpFrom .5s ease both;
  animation: scaleUpFrom .5s ease both; }

.owl-fadeUp-out {
  -webkit-animation: scaleUpTo .5s ease both;
  -moz-animation: scaleUpTo .5s ease both;
  animation: scaleUpTo .5s ease both; }

/* Keyframes */
/*empty*/
@-webkit-keyframes empty {
  0% {
    opacity: 1; } }
@-moz-keyframes empty {
  0% {
    opacity: 1; } }
@keyframes empty {
  0% {
    opacity: 1; } }
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
@-moz-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }
@-moz-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }
@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }
@-webkit-keyframes backSlideOut {
  25% {
    opacity: .5;
    -webkit-transform: translateZ(-500px); }
  75% {
    opacity: .5;
    -webkit-transform: translateZ(-500px) translateX(-200%); }
  100% {
    opacity: .5;
    -webkit-transform: translateZ(-500px) translateX(-200%); } }
@-moz-keyframes backSlideOut {
  25% {
    opacity: .5;
    -moz-transform: translateZ(-500px); }
  75% {
    opacity: .5;
    -moz-transform: translateZ(-500px) translateX(-200%); }
  100% {
    opacity: .5;
    -moz-transform: translateZ(-500px) translateX(-200%); } }
@keyframes backSlideOut {
  25% {
    opacity: .5;
    transform: translateZ(-500px); }
  75% {
    opacity: .5;
    transform: translateZ(-500px) translateX(-200%); }
  100% {
    opacity: .5;
    transform: translateZ(-500px) translateX(-200%); } }
@-webkit-keyframes backSlideIn {
  0%,
  25% {
    opacity: .5;
    -webkit-transform: translateZ(-500px) translateX(200%); }
  75% {
    opacity: .5;
    -webkit-transform: translateZ(-500px); }
  100% {
    opacity: 1;
    -webkit-transform: translateZ(0) translateX(0); } }
@-moz-keyframes backSlideIn {
  0%,
  25% {
    opacity: .5;
    -moz-transform: translateZ(-500px) translateX(200%); }
  75% {
    opacity: .5;
    -moz-transform: translateZ(-500px); }
  100% {
    opacity: 1;
    -moz-transform: translateZ(0) translateX(0); } }
@keyframes backSlideIn {
  0%,
  25% {
    opacity: .5;
    transform: translateZ(-500px) translateX(200%); }
  75% {
    opacity: .5;
    transform: translateZ(-500px); }
  100% {
    opacity: 1;
    transform: translateZ(0) translateX(0); } }
@-webkit-keyframes scaleToFade {
  to {
    opacity: 0;
    -webkit-transform: scale(0.8); } }
@-moz-keyframes scaleToFade {
  to {
    opacity: 0;
    -moz-transform: scale(0.8); } }
@keyframes scaleToFade {
  to {
    opacity: 0;
    transform: scale(0.8); } }
@-webkit-keyframes goDown {
  from {
    -webkit-transform: translateY(-100%); } }
@-moz-keyframes goDown {
  from {
    -moz-transform: translateY(-100%); } }
@keyframes goDown {
  from {
    transform: translateY(-100%); } }
@-webkit-keyframes scaleUpFrom {
  from {
    opacity: 0;
    -webkit-transform: scale(1.5); } }
@-moz-keyframes scaleUpFrom {
  from {
    opacity: 0;
    -moz-transform: scale(1.5); } }
@keyframes scaleUpFrom {
  from {
    opacity: 0;
    transform: scale(1.5); } }
@-webkit-keyframes scaleUpTo {
  to {
    opacity: 0;
    -webkit-transform: scale(1.5); } }
@-moz-keyframes scaleUpTo {
  to {
    opacity: 0;
    -moz-transform: scale(1.5); } }
@keyframes scaleUpTo {
  to {
    opacity: 0;
    transform: scale(1.5); } }
/* Styling Next and Prev buttons */
.owl-theme .owl-controls .owl-buttons div {
  display: inline-block;
  margin: 5px;
  padding: 3px 10px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  background: #869791;
  color: #FFF;
  font-size: 12px;
  opacity: 0.5;
  filter: Alpha(Opacity=50);
  /*IE7 fix*/
  zoom: 1;
  display: inline;
  /* *display: inline; */
  /*IE7 life-saver */ }

/* Clickable class fix problem with hover on touch devices */
/* Use it for non-touch hover action */
.owl-theme .owl-controls.clickable .owl-buttons div:hover {
  text-decoration: none;
  opacity: 1;
  filter: Alpha(Opacity=100);
  /*IE7 fix*/ }

/* Styling Pagination*/
.owl-theme .owl-controls .owl-page {
  display: inline-block;
  zoom: 1;
  display: inline;
  /* *display: inline; */
  /*IE7 life-saver */ }

.owl-theme .owl-controls .owl-page span {
  display: block;
  margin: 5px 7px;
  width: 12px;
  height: 12px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  background: #869791;
  opacity: 0.5;
  filter: Alpha(Opacity=50);
  /*IE7 fix*/ }

.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span {
  opacity: 1;
  filter: Alpha(Opacity=100);
  /*IE7 fix*/ }

/* If PaginationNumbers is true */
.owl-theme .owl-controls .owl-page span.owl-numbers {
  padding: 2px 10px;
  width: auto;
  height: auto;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  color: #FFF;
  font-size: 12px; }

/* preloading images */
.owl-item.loading {
  min-height: 150px;
  /* background: url(../404.html) no-repeat center center; */
 }

/* CUSTOM USER OPTIONS */
.carousel-container {
  width: 100%;
  margin-top: 50px; }

.item {
  margin: 0;
  background: #fff;
  color: #f0f0f0;
  border-radius: 10px;
  text-align: center; }
  @media only screen and (max-width: 56.25em) {
    .item {
      font-size: 4.5rem; } }

.item img {
  width: 100%; }

.item h4 {
  margin-top: 27px;
  color: #0c52aa;
  font-size: 2rem; }
  @media only screen and (max-width: 56.25em) {
    .item h4 {
      font-size: 6.7rem; } }

.item h6 {
  padding-bottom: 30px;
  color: #575a5a;
  font-size: 1.5rem; }
  @media only screen and (max-width: 56.25em) {
    .item h6 {
      font-size: 3.8rem; } }

.customNavigation {
  display: none;
  margin-top: -25px;
  text-align: center; }

.customNavigation .btn {
  margin: 0;
  position: relative;
  display: inline-block;
  overflow: visible;
  padding: 0.8em 1.1em;
  width: 50%;
  /* float: left; */
  border: 0;
  box-shadow: none;
  color: #fff;
  text-transform: uppercase;
  text-shadow: none;
  font-size: 20px;
  cursor: crosshair;
  /* -webkit-transition: all .1s ease-in 0s; */
  /* -moz-transition: all .1s ease-in 0s; */
  /* -o-transition: all .1s ease-in 0s; */
  -webkit-font-smoothing: antialiased;
  background: #333; }

.customNavigation .btn:hover {
  background: #222; }

.hoverfx {
  display: block;
  overflow: hidden;
  text-align: center; }

.hoverfx img {
  position: absolute;
  left: 0;
  width: 100%;
  border-radius: 1px;
  margin-left: 1px;
  filter: alpha(opacity=40);
  opacity: 0.6;
  transition: all .5s ease-out;
  border-color: #cecece;
  border-style: solid;
  border-width: 1.5rem; }
  .hoverfx img:hover {
    opacity: 1;
    transform: translateY(5px); }

.hoverfx:before {
  display: inline-block;
  padding-top: 80%;
  content: '';
  vertical-align: middle; }

.hoverfx .figure {
  position: relative;
  z-index: 2;
  display: inline-block;
  padding: 12px;
  max-width: 60%;
  border: 3px solid #e9e9e9;
  color: #ecf0f1;
  vertical-align: middle;
  text-transform: uppercase;
  font-size: 1rem;
  opacity: 0;
  /* -webkit-transition: all .3s ease; */
  /* -moz-transition: all .3s ease; */
  /* -o-transition: all .3s ease;  */
}

.hoverfx .overlay {
  position: absolute;
  top: 0;
  z-index: 1;
  padding: 50%;
  margin-left: 5px;
  border-radius: 10px;
  background: #fff;
  opacity: 0;
  /* -webkit-transition: all .3s ease; */
  /* -moz-transition: all .3s ease; */
  /* -o-transition: all .3s ease; */
  display: none;
 }

.item:hover .figure,
.item:hover .overlay {
  opacity: 1; }

.owl-wrapper {
  width: 100%;
  left: 0px;
  display: block; }

.containerTitles {
  width: 100%;
  text-align: center;
  margin: 20px 0; }

.containerTitles h1 {
  font-size: 3rem;
  letter-spacing: 1px;
  text-transform: uppercase; }

.containerTitles h2 {
  font-weight: normal; }

.component-systemTabs {
  width: 100%;
  display: flex; }

.component-systemTabs .tabs-container {
  margin: 0 auto;
  width: 100%; }

.component-systemTabs .tabs-container ul.tabs {
  margin: 20px 0;
  padding: -3px;
  list-style: none;
  text-align: center;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%; }

.component-systemTabs .tabs-container ul.tabs li {
  background: none;
  color: #5a5a5a;
  display: inline-block;
  padding: 10px 25px;
  cursor: pointer;
  font-weight: 600;
  border-bottom: 4px solid #d7d7d7;
  font-size: 1.6rem;
  transition: all 0.5s ease; }
  @media only screen and (max-width: 56.25em) {
    .component-systemTabs .tabs-container ul.tabs li {
      font-size: 4.1rem; } }

.component-systemTabs .tabs-container ul.tabs li:hover {
  color: #000000; }

.component-systemTabs .tabs-container ul.tabs li.current {
  color: #000000;
  border-bottom: 4px solid #000000;
  transition: all 0.5s ease; }

.component-systemTabs .tab-content {
  display: none;
  padding: 15px;
  width: 100%; }

.component-systemTabs .tab-content.current {
  display: block; }

.component-systemTabs .cards {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  width: 100%; }

.component-systemTabs .cards .card {
  width: 454px;
  height: 507px;
  margin: 5px;
  position: relative; }

.component-systemTabs .cards .card img {
  height: 100%;
  width: 100%;
  max-width: 454px;
  max-height: 507px; }

.cardContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: absolute;
  background-color: rgba(28, 68, 127, 0.377);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 20px;
  border: .7rem solid transparent;
  border-image: linear-gradient(to right, #1c437f, #102a52);
  border-image-slice: 1;
  cursor: pointer;
  transition: all 0.3s ease; }

.component-systemTabs .cards .card .cardContent:hover {
  background-color: rgba(0, 0, 0, 0.2); }

.cardContentbutton {
  border: 0;
  background-color: #000000;
  padding: 7px 10px;
  width: 100px;
  color: white;
  font-size: 1.2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-weight: 900;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease;
  opacity: 0;
  transition: 0.3s ease; }

.component-systemTabs .cards .card .cardContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.123);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 20px;
  border: 4px solid transparent;
  border-image: linear-gradient(to right, #000000, #4f5050);
  border-image-slice: 1;
  cursor: pointer;
  transition: all 0.3s ease; }

.component-systemTabs .cards .card .cardContent h2 {
  color: white;
  font-size: 2rem; }

.component-systemTabs .cards .card .cardContent button {
  border: 0;
  background-color: #000000;
  padding: 7px 10px;
  width: 100px;
  color: white;
  font-size: 1.2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-weight: 900;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease;
  opacity: 0;
  transition: 0.3s ease; }



.component-systemTabs .cards .card .cardContent button:hover {
  -webkit-box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.3);
  font-size: 1.25rem; }

.component-systemTabs .cards .card .cardContent:hover {
  background-color: rgba(0, 0, 0, 0.2); }

.component-systemTabs .cards .card .cardContent:hover button {
  opacity: 1; }

/* Count */
.counter {
  text-align: center; }

.counter-count {
  font-size: 50px;
  font-weight: bold;
  position: relative;
  color: #ffffff;
  text-align: center;
  display: inline-block; }

h3.counter {
  text-align: center;
  color: #ffffff; }

span.plus {
  color: #ffffff;
  font-size: 2.5em; }

.form__grup {
  margin-bottom: .2rem;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  text-align: center; }
  .form__grup__grup:not(:last-child) {
    margin-bottom: .5rem; }
.form__input {
  font-size: 1.5rem;
  padding: 1.3rem 2rem;
  border-radius: .2rem;
  color: inherit;
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  border-bottom: .3rem solid transparent;
  width: 100%;
  display: block;
  transition: all .3s;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  border-bottom: 0.3rem solid #292929; }
  @media only screen and (max-width: 56.25em) {
    .form__input {
      font-size: 3.5rem;
      padding: 2.3rem 2rem; } }
  @media only screen and (max-width: 40em) {
    .form__input {
      font-size: 3.5rem;
      padding: 2.3rem 2rem; } }
  .form__input:focus {
    outline: none;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
    border-bottom: 0.3rem solid #292929; }
  .form__input:focus:invalid {
    border-bottom: 0.3rem solid #000000; }
  .form__input::-webkit-input-placeholder {
    color: #999; }
.form__label {
  font-size: 1.2rem;
  font-weight: 700;
  margin-left: 2rem;
  margin-top: .7rem;
  display: block;
  transition: all .3s; }
.form__input:placeholder-shown + .form__label {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-4rem); }
.form__radio-grup {
  width: 49%;
  display: inline-block;
  /* float: left; */
  margin-bottom: 2rem; }
.form__radio-input {
  display: none; }
.form__radio-label {
  font-size: 1.6rem;
  cursor: pointer;
  position: relative;
  padding-left: 4.5rem; }
.form__radio-buton {
  height: 3rem;
  width: 3rem;
  border: 0.5rem solid #292929;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 0;
  top: -.4rem; }
  .form__radio-buton::after {
    content: "";
    display: block;
    height: 1.3rem;
    width: 1.3rem;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #292929;
    opacity: 0;
    transition: opacity .2s; }
.form__radio-input:checked + .form__radio-label .form__radio-buton::after {
  opacity: 1; }

.form-popup {
  padding: 0rem 5rem 0rem 5rem;
  text-align: center; }
  .form-popup__grup {
    margin-bottom: .2rem; }
    .form-popup__grup__grup:not(:last-child) {
      margin-bottom: .5rem; }
  .form-popup__input {
    font-size: 1.5rem;
    padding: 1.3rem 2rem;
    border-radius: .2rem;
    color: #1a1a1a;
    background-color: rgba(255, 255, 255, 0.5);
    border: none;
    border-bottom: .3rem solid transparent;
    width: 100%;
    display: block;
    transition: all .3s;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
    border-bottom: .3rem solid #363636; }
    @media only screen and (max-width: 56.25em) {
      .form-popup__input {
        font-size: 3.5rem;
        padding: 2.3rem 2rem; } }
    @media only screen and (max-width: 40em) {
      .form-popup__input {
        font-size: 3.5rem;
        padding: 2.3rem 2rem; } }
    .form-popup__input:focus {
      outline: none;
      box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
      border-bottom: 0.3rem solid #292929; }
    .form-popup__input:focus:invalid {
      border-bottom: 0.3rem solid #000000; }
    .form-popup__input::-webkit-input-placeholder {
      color: #999; }
  .form-popup__label {
    font-size: 1.2rem;
    font-weight: 700;
    margin-left: 2rem;
    margin-top: .7rem;
    display: block;
    transition: all .3s; }
  .form-popup__input:placeholder-shown + .form-popup__label {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-4rem); }
  .form-popup__radio-grup {
    width: 49%;
    display: inline-block;
    /* float: left; */
    margin-bottom: 2rem; }
  .form-popup__radio-input {
    display: none; }
  .form-popup__radio-label {
    font-size: 1.6rem;
    cursor: pointer;
    position: relative;
    padding-left: 4.5rem; }
  .form-popup__radio-buton {
    height: 3rem;
    width: 3rem;
    border: 0.5rem solid #292929;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    left: 0;
    top: -.4rem; }
    .form-popup__radio-buton::after {
      content: "";
      display: block;
      height: 1.3rem;
      width: 1.3rem;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #292929;
      opacity: 0;
      transition: opacity .2s; }
  .form-popup__radio-input:checked + .form-popup__radio-label .form-popup__radio-buton::after {
    opacity: 1; }

.icon-bar {
  position: fixed;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 8; }

.icon-bar a {
  display: block;
  text-align: center;
  padding: 16px;
  transition: all 0.3s ease;
  color: white;
  font-size: 20px; }

.icon-bar a:hover {
  background-color: #000; }

.facebook {
  background: #3B5998;
  color: white; }

.twitter {
  background: #55ACEE;
  color: white; }

.google {
  background: #dd4b39;
  color: white; }

.linkedin {
  background: #007bb5;
  color: white; }

.youtube {
  background: #bb0000;
  color: white; }

.content {
  margin-left: 75px;
  font-size: 30px; }

header .item {
  position: relative;
  margin-top: 87px; }

header .item img {
  width: 100%;
  height: 100%;
  object-fit: cover; }

header .item .cover {
  padding: 75px 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center; }

header .item .cover .header-content {
  position: relative;
  padding: 56px;
  overflow: hidden;
  margin-top: 8px; }
  @media only screen and (max-width: 56.25em) {
    header .item .cover .header-content {
      padding: 0px;
      margin-top: -7rem;
      margin-left: 0rem; } }

header .item .cover .header-content .line {
  content: "";
  display: inline-block;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute; }

header .item .cover .header-content h2 {
  font-weight: 900;
  font-size: 50px;
  color: #fff;
  text-align: center;
  transform: translateY(-0.3rem);
  animation: butonefekt .2s ease-out .20s;
  animation-fill-mode: backwards;
  transition: all 1s; }
  @media only screen and (max-width: 1024px) {
    header .item .cover .header-content h2 {
      font-size: 46px; } }
  @media only screen and (max-width: 56.25em) {
    header .item .cover .header-content h2 {
      font-size: 30px;
      font-weight: 900;
      width: 100%;
      text-align: center; } }

header .item .cover .header-content h1 {
  font-size: 20px;
  font-weight: 600;
  word-spacing: 3px;
  color: #fff;
  padding: 5px;
  text-align: center;
  margin-bottom: 1rem;
  transform: translateY(-0.3rem);
  animation: butonefekt .3s ease-out .60s;
  animation-fill-mode: backwards;
  transition: all 1s; }
  @media only screen and (max-width: 1024px) {
    header .item .cover .header-content h1 {
      font-size: 25px; } }
  @media only screen and (max-width: 56.25em) {
    header .item .cover .header-content h1 {
      font-size: 17px;
      font-weight: 500;
      width: 100%;
      padding: 1px; } }

header .item .cover .header-content h4 {
  font-size: 25px;
  font-weight: 600;
  line-height: 40px;
  color: #fff; }
  @media only screen and (max-width: 1024px) {
    header .item .cover .header-content h4 {
      font-size: 30px; } }
  @media only screen and (max-width: 56.25em) {
    header .item .cover .header-content h4 {
      font-size: 17px; } }

header .owl-item.active h1 {
  -webkit-animation-duration: 50s;
  animation-duration: 0.8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-name: fadeInDown;
  animation-delay: 0.4s; }

header .owl-item.active h2 {
  -webkit-animation-duration: 50s;
  animation-duration: 0.8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-name: fadeInDown;
  animation-delay: 0.4s; }

header .owl-item.active h4 {
  -webkit-animation-duration: 50s;
  animation-duration: 0.8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-name: fadeInUp;
  animation-delay: 0.4s; }

header .owl-item.active .line {
  -webkit-animation-duration: 5s;
  animation-duration: 0.8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-name: fadeInLeft;
  animation-delay: 0.4s; }

header .owl-nav .owl-prev {
  position: absolute;
  left: 15px;
  top: 30%;
  opacity: 0;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  width: 40px;
  cursor: pointer;
  height: 40px;
  position: absolute;
  display: block;
  z-index: 1000;
  border-radius: 0; }

header .owl-nav .owl-prev span {
  font-size: 8.6875rem;
  color: #fff; }

header .owl-nav .owl-prev:focus {
  outline: 0; }

header .owl-nav .owl-next {
  position: absolute;
  right: 15px;
  top: 30%;
  opacity: 0;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  width: 40px;
  cursor: pointer;
  height: 40px;
  position: absolute;
  display: block;
  z-index: 1000;
  border-radius: 0; }

header .owl-nav .owl-next span {
  font-size: 8.6875rem;
  color: #fff; }

header .owl-nav .owl-next:focus {
  outline: 0; }

header:hover .owl-prev {
  left: 0px;
  opacity: 1; }

header:hover .owl-next {
  right: 0px;
  opacity: 1; }

.slider {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-end; }

.slider input[type="radio"] {
  position: relative;
  z-index: 888;
  margin: 5px;
  margin-bottom: 40px;
  outline: none;
  cursor: pointer; }

.yukaricik {
  position: fixed;
  width: 5rem;
  height: 5rem;
  background-image: linear-gradient(to right bottom, #000000, #292929);
  bottom: 0rem;
  right: 5rem;
  text-decoration: none;
  text-align: center;
  line-height: 5rem;
  display: inline-block;
  z-index: 0; }
  @media only screen and (max-width: 40em) {
    .yukaricik {
      display: none; } }
  @media only screen and (max-width: 75em) {
    .yukaricik {
      display: none; } }

.yukaricik-icon {
  font-size: 2rem;
  margin-bottom: .5rem;
  display: inline-block;
  color: #fff; }
  @media only screen and (max-width: 40em) {
    .yukaricik-icon {
      display: none; } }
  @media only screen and (max-width: 75em) {
    .yukaricik-icon {
      display: none; } }

#top {
  bottom: 5px;
  bottom: -41px;
  display: none;
  height: 100px;
  position: fixed;
  right: 20px;
  z-index: 5; }

* {
  outline: none !important; }

body {
  margin: 0;
  padding: 0;
  
  font-size: 18px;
  color: #000000;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

/* LINKS */
a {
  color: #000000;
  -webkit-transition: all .35s ease-in-out;
  -moz-transition: all .35s ease-in-out;
  -ms-transition: all .35s ease-in-out;
  -o-transition: all .35s ease-in-out;
  transition: all .35s ease-in-out; }

a:hover {
  text-decoration: underline;
  color: #000000; }

/* HTML TAGS */
img {
  max-width: 100%; }

/* FORM ELEMENTS */
input[type="text"] {
  max-width: 100%;
  height: 70px;
  padding: 0 30px; }

input[type="email"] {
  max-width: 100%;
  height: 70px;
  padding: 0 30px; }

input[type="search"] {
  max-width: 100%;
  height: 70px;
  padding: 0 30px; }

input[type="password"] {
  max-width: 100%;
  height: 70px;
  padding: 0 30px; }

input[type="radio"] {
  width: 18px;
  height: 18px;
  display: inline-block;
  margin-right: 4px;
  transform: translateY(3px);
  appearance: none;
  background: #ededed;
  border-radius: 50%; }

input[type="radio"]:checked {
  border: 6px solid #000000; }

input[type="checkbox"] {
  width: 18px;
  height: 18px;
  display: inline-block;
  margin-right: 4px;
  transform: translateY(3px);
  appearance: none;
  background: #ededed; }

input[type="checkbox"]:checked {
  border: 6px solid #000000; }

textarea {
  max-width: 100%;
  height: 140px;
  padding: 30px; }

select {
  max-width: 100%;
  height: 70px;
  padding: 0 30px; }

select {
  /* -webkit-appearance: none; */
  /* -moz-appearance: none; */
  background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 30px) 34px, calc(100% - 25px) 34px, calc(100% - 3.5em) 20px;
  background-size: 5px 5px, 5px 5px, 1px 40px;
  background-repeat: no-repeat; }

select:focus {
  background-image: linear-gradient(45deg, gray 50%, transparent 50%), linear-gradient(135deg, transparent 50%, gray 50%), linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 25px) 34px, calc(100% - 30px) 34px, calc(100% - 3.5em) 20px;
  background-size: 5px 5px, 5px 5px, 1px 40px;
  background-repeat: no-repeat;
  border-color: gray;
  outline: 0; }

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000; }

input[type="submit"] {
  height: 70px;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  background: #000000;
  border: none;
  padding: 0 50px; }

button[type="submit"] {
  height: 70px;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  background: #000000;
  border: none;
  padding: 0 50px; }
  button[type="submit"] i {
    display: inline-block;
    margin-right: 8px;
    font-size: 18px;
    transform: translateY(2px); }

/* CUSTOM CONTAINER */
@media (min-width: 1170px) {
  .container {
    max-width: 1100px; } }
@media (min-width: 1280px) {
  .container {
    max-width: 80vw; } }
/* CUSTOM CLASSES */
.overflow {
  overflow: hidden; }

.no-gutters {
  padding: 0;
  margin: 0; }

/* SPACING */
.no-spacing {
  margin: 0 !important;
  padding: 0 !important; }

.no-top-spacing {
  margin-top: 0 !important;
  padding-top: 0 !important; }

.no-bottom-spacing {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important; }

.show-mobile .site-menu li a.sb {
  width: 26px;
  height: 15px;
  text-align: center;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  font-weight: 700;
  border: 0 !important; }

span.sb {
  width: 26px;
  height: 18px;
  float: right;
  margin-top: -3px;
  text-align: center;
  color: #000;
  right: 0;
  top: 0;
  z-index: 2;
  font-weight: 700;
  border: 0 !important; }

/* HAMBURGER MENU */
.hamburger-menu {
  width: 30px;
  height: 20px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  transition-duration: 500ms;
  -webkit-transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  cursor: pointer; }

.hamburger-menu span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #000;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out; }

.hamburger-menu span:nth-child(1) {
  top: 0px;
  width: 100%; }

.hamburger-menu span:nth-child(2) {
  top: 9px;
  width: 22px; }

.hamburger-menu span:nth-child(3) {
  top: 18px;
  width: 100%; }

.hamburger-menu:hover span {
  width: 100% !important; }

.hamburger-menu.open span {
  width: 20px !important; }

.hamburger-menu.open span:nth-child(1) {
  top: 9px;
  right: 0;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
  width: 28px !important; }

.hamburger-menu.open span:nth-child(2) {
  opacity: 0;
  left: -20px; }

.hamburger-menu.open span:nth-child(3) {
  top: 9px;
  right: 0;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
  width: 28px !important; }

/* ODOMETER */
.odometer.odometer-auto-theme {
  padding: 0; }

.odometer.odometer-auto-theme .odometer-digit, .odometer.odometer-theme-car .odometer-digit {
  padding: 0; }

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner, .odometer.odometer-theme-car .odometer-digit .odometer-digit-inner {
  left: -4px; }

/* SWIPER PAGINATION */
.swiper-pagination {
  width: 100%; }
  .swiper-pagination .swiper-pagination-bullet {
    width: 3vw;
    height: 8px;
    background: #dddddd;
    -webkit-transition: all .35s ease-in-out;
    -moz-transition: all .35s ease-in-out;
    -ms-transition: all .35s ease-in-out;
    -o-transition: all .35s ease-in-out;
    transition: all .35s ease-in-out;
    opacity: 0.8;
    border-radius: 5px; }
    .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
      width: 6vw;
      background: #000000;
      opacity: 1; }

/* CUSTOM BUTTON */
.col-12.text-center .custom-button {
  margin-top: 50px; }

.custom-button {
  height: 70px;
  line-height: 70px;
  display: inline-block;
  background: #282f3f;
  color: #fff;
  font-weight: 600;
  border: 1px solid;
  padding: 0 50px;
  position: relative;
  font-size: 16px;
  border-radius: 100px; }
  .custom-button:before {
    content: "";
    width: 0;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.05;
    transition-duration: 1s;
    -webkit-transition-duration: 1s;
    transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
    -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1); }
  .custom-button:hover {
    text-decoration: none;
    color: #fff;
    background: #1a1a1a; }
  .custom-button:hover:before {
    width: 100%; }

.col-12.text-center .custom-buttonw {
  margin-top: 50px; }

.custom-buttonw {
  height: 70px;
  line-height: 70px;
  display: inline-block;
  background: #fff;
  color: #000000;
  padding: 0 50px;
  position: relative;
  font-size: 16px; }
  .custom-buttonw:before {
    content: "";
    width: 0;
    height: 100%;
    background: #000;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.05;
    transition-duration: 1s;
    -webkit-transition-duration: 1s;
    transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
    -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1); }
  .custom-buttonw:hover {
    text-decoration: none;
    color: #000000; }
  .custom-buttonw:hover:before {
    width: 100%; }

/* REVEAL EFFECT */
.wow.fade {
  opacity: 0;
  transition: opacity 0.5s ease;
  transition-delay: 0.2s; }

.wow.fade.animated {
  opacity: 1; }

.reveal-effect {
  float: left;
  position: relative; }
  .reveal-effect.animated:before {
    content: "";
    width: 100%;
    height: 100%;
    background: #eee;
    position: absolute;
    left: 0;
    top: 0;
    animation: 1s reveal linear forwards;
    -webkit-animation-duration: 1s;
    z-index: 1;
    -moz-animation-duration: 1s;
    -ms-animation-duration: 1s;
    -o-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -ms-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
    -moz-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
    -o-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
    -ms-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
    animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86); }

.reveal-effect.animated > * {
  animation: 1s reveal-inner linear forwards; }

/* @-webkit-keyframes reveal {
  0% {
    left: 0;
    width: 0; }
  50% {
    left: 0;
    width: 100%; }
  51% {
    left: auto;
    right: 0; }
  100% {
    left: auto;
    right: 0;
    width: 0; } 
  }

@-webkit-keyframes reveal-inner {
  0% {
    visibility: hidden;
    opacity: 0; }
  50% {
    visibility: hidden;
    opacity: 0; }
  51% {
    visibility: visible;
    opacity: 1; }
  100% {
    visibility: visible;
    opacity: 1; }
   } */
/* PRELOADER */
.preloader {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 99999;
  right: 0;
  top: 0;
  background: #ffffff;
  transition-duration: 1s;
  -webkit-transition-duration: 1s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1); }
  .preloader figure {
    width: 140px;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.164);
    border-radius: 50%;
    animation: fadeup 0.30s;
    position: relative; }
    .preloader figure:after {
      content: "";
      width: 100%;
      height: 100%;
      border: 1px solid transparent;
      border-top: 1px solid black;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 0;
      animation: rotate1 0.60s infinite; }
  .preloader img {
    height: 50px;
    display: inline-block; }

.page-loaded .preloader {
  top: 100%; }

@keyframes fadeup {
  0% {
    transform: translateY(20px);
    opacity: 0; }
  100% {
    transform: translateY(0);
    opacity: 1; } }
@keyframes rotate1 {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
/* PAGE TRANSITION */
.page-transition {
  width: 100%;
  height: 0;
  position: fixed;
  z-index: 99;
  left: 0;
  bottom: 0;
  background: #ffffff;
  transition-duration: 1s;
  -webkit-transition-duration: 1s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1); }
  .page-transition.active {
    height: 100%; }

/* SIDE WIDGET */
.side-widget {
  width: 400px;
  height: 100vh;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: fixed;
  left: -100%;
  top: 0;
  transition-duration: 1s;
  -webkit-transition-duration: 1s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
  background: #ffffff;
  z-index: 20;
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.4);
  padding: 20px 30px;
  color: #fff; }
  .side-widget .inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    overflow-y: auto;
    height: 100%; }
  .side-widget .logo {
    width: 100%;
    display: block;
    margin-bottom: 40px; }
  .side-widget .show-mobile {
    display: none; }
  .side-widget .hide-mobile {
    width: 100%;
    display: inline-block; }
  .side-widget .gallery {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px; }
    .side-widget .gallery a {
      width: 50%;
      padding-right: 3px; }
      .side-widget .gallery a:last-child {
        padding-left: 3px; }
  .side-widget p {
    width: 100%;
    display: block;
    color: #fff; }
  .side-widget .widget-title {
    width: 100%;
    display: block;
    margin-bottom: 10px;
    font-size: 18px;
    color: #000000; }
  .side-widget .address {
    width: 100%;
    display: block;
    margin-bottom: 20px; }
    .side-widget .address a {
      display: inline-block;
      color: #fff;
      text-decoration: underline; }
      .side-widget .address a:hover {
        text-decoration: none; }
  .side-widget .social-media {
    width: 100%;
    display: block;
    margin: 0;
    padding: 0; }
    .side-widget .social-media li {
      display: inline-block;
      margin-right: 20px;
      padding: 0;
      list-style: none; }
      .side-widget .social-media li a {
        color: #fff;
        font-size: 13px;
        font-weight: 600; }
        .side-widget .social-media li a:hover {
          color: #000000; }
  .side-widget .custom-menu {
    width: 100%;
    display: block;
    margin-bottom: 20px;
    margin-top: 20px; }
    .side-widget .custom-menu ul {
      width: 100%;
      display: block;
      margin: 0;
      padding: 0; }
      .side-widget .custom-menu ul li {
        display: inline-block;
        margin: 3px 0;
        margin-right: 10px;
        padding: 0;
        list-style: none; }
        .side-widget .custom-menu ul li ul {
          display: none;
          padding-left: 20px;
          margin-bottom: 10px; }
        .side-widget .custom-menu ul li a {
          color: #fff;
          font-size: 18px;
          font-weight: 600; }
          .side-widget .custom-menu ul li a:hover {
            text-decoration: none;
            color: #000000; }
  .side-widget .site-menu {
    width: 100%;
    display: block;
    margin-bottom: 20px;
    margin-top: 20px; }
    .side-widget .site-menu ul {
      width: 100%;
      display: block;
      margin: 0;
      padding: 0; }
      .side-widget .site-menu ul li {
        display: block;
        margin: 3px 0;
        padding: 0;
        list-style: none; }
        .side-widget .site-menu ul li ul {
          display: none;
          padding-left: 20px;
          margin-bottom: 10px; }
        .side-widget .site-menu ul li a {
          color: #000;
          font-size: 18px;
          font-weight: 600; }
          .side-widget .site-menu ul li a:hover {
            text-decoration: none;
            color: #000; }
  .side-widget small {
    font-size: 13px;
    width: 100%;
    display: block;
    margin-top: 20px;
    
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }
  .side-widget.active {
    left: 0;
    z-index: 8888; }

/* TOPBAR */
.topbar {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background: #000000;
  padding: 10px 0;
  color: #fff;
  display: none; }
  .topbar div {
    display: inline-block;
    font-size: 16px;
     }
    .topbar div b {
      font-weight: 500;
      display: inline-block;
      margin-right: 6px;
      opacity: 0.5; }
    .topbar div a {
      display: inline-block;
      color: #fff;
      border-radius: 100px; }

/* NAVBAR */
.navbar {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
  position: fixed !important;
  background: #ffffff; }
  .navbar .logo {
    margin-right: auto;
    padding: 22px 0; }
    .navbar .logo a {
      display: inline-block; }
      .navbar .logo a img {
        height: 75px; }
  .navbar .site-menu {
    margin: 0 auto; }
    .navbar .site-menu ul {
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      padding: 0; }
      .navbar .site-menu ul li {
        display: inline-block;
        margin: 0;
        padding: 0 22px;
        list-style: none;
        -webkit-transition: all .35s ease-in-out;
        -moz-transition: all .35s ease-in-out;
        -ms-transition: all .35s ease-in-out;
        -o-transition: all .35s ease-in-out;
        transition: all .35s ease-in-out; }
        .navbar .site-menu ul li a {
          color: #000;
          font-weight: 600;
          font-size: 17px;
          color: #000; }
          .navbar .site-menu ul li a:hover {
            text-decoration: none;
            color: #000; }
  .navbar .hamburger-menu {
    margin-left: auto; }
  .navbar .navbar-button a {
    height: 70px;
    line-height: 70px;
    display: inline-block;
    background: #000000;
    color: #ffffff;
    padding: 0 21px;
    position: relative;
    font-size: 17px;
    font-weight: 600;
    border-radius: 0.3rem; }
    .navbar .navbar-button a:before {
      content: "";
      width: 0;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 1;
      transition-duration: 1s;
      -webkit-transition-duration: 1s;
      transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
      -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1); }
    .navbar .navbar-button a:hover {
      text-decoration: none;
      color: #ffffff; }
    .navbar .navbar-button a:hover:before {
      width: 100%; }

a.sub {
  color: #000000; }

ul {
  list-style: none;
  margin: 0;
  padding-left: 0; }

li {
  display: block;
  float: left;
  padding: 1rem;
  position: relative;
  text-decoration: none;
  transition-duration: 0.5s; }

li a {
  color: #000000; }

li:hover,
li:focus-within {
  cursor: pointer; }

li:focus-within a {
  outline: none; }

ul li ul {
  visibility: hidden;
  opacity: 0;
  min-width: 5rem;
  position: absolute;
  transition: all 0.5s ease;
  margin-top: 1rem;
  left: 0;
  display: none; }

ul li:hover > ul,
ul li:focus-within > ul,
ul li ul:hover,
ul li ul:focus {
  visibility: visible;
  opacity: 1;
  display: block; }

ul li ul li {
  clear: both;
  width: 100%; }

.menueffect {
  text-align: center; }

.menueffect * {
  box-sizing: border-box; }

.menueffect li {
  display: inline-block;
  list-style: outside none none;
  margin: 0 1.5em;
  padding: 0; }

.menueffect a {
  padding: 0.5em 0;
  color: rgba(255, 255, 255, 0.5);
  position: relative;
  letter-spacing: 1px;
  text-decoration: none; }

.menueffect a:before,
.menueffect a:after {
  position: absolute;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease; }

.menueffect a:before {
  bottom: 0;
  display: block;
  height: 3px;
  width: 0%;
  content: "";
  background-color: #000000; }

.menueffect a:after {
  left: 0;
  top: 0;
  padding: 0.5em 0;
  position: absolute;
  content: attr(data-hover);
  color: #ffffff;
  white-space: nowrap;
  max-width: 0%;
  overflow: hidden; }

.menueffect a:hover:before,
.menueffect .current a:before {
  opacity: 1;
  width: 100%; }

.menueffect a:hover:after,
.menueffect .current a:after {
  max-width: 100%; }

/* SLIDER */
.slider {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  position: relative; }
  .slider .main-slider {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    overflow: hidden; }
    .slider .main-slider .swiper-slide {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding-top: 150px;
      background: white; }
      .slider .main-slider .swiper-slide .slide-image {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-size: cover !important;
        background-position: center !important;
        opacity: 1; }
        .slider .main-slider .swiper-slide .slide-image:after {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          opacity: 0.4; }
      .slider .main-slider .swiper-slide .container {
        color: #fff;
        position: relative;
        z-index: 1; }
        .slider .main-slider .swiper-slide .container h1 {
          width: 100%;
          display: block;
          font-size: 80px;
          margin-bottom: 10px;
          font-weight: 600;
          color: #000000; }
        .slider .main-slider .swiper-slide .container p {
          width: 100%;
          display: block;
          color: #000000;
          margin-bottom: 50px;
          font-size: 20px; }
        .slider .main-slider .swiper-slide .container a {
          height: 70px;
          line-height: 70px;
          display: inline-block;
          padding: 0 50px;
          background: #000000;
          color: #fff;
          -webkit-transition: all .35s ease-in-out;
          -moz-transition: all .35s ease-in-out;
          -ms-transition: all .35s ease-in-out;
          -o-transition: all .35s ease-in-out;
          transition: all .35s ease-in-out;
          position: relative;
          font-size: 18px;
          font-weight: 600;
          border-radius: 0.3rem; }
          .slider .main-slider .swiper-slide .container a:before {
            content: "";
            width: 0;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0.05;
            transition-duration: 1s;
            -webkit-transition-duration: 1s;
            transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
            -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1); }
          .slider .main-slider .swiper-slide .container a:hover {
            text-decoration: none;
            color: #fff;
            background: #1a1a1a;
            border-radius: 0.3rem; }
          .slider .main-slider .swiper-slide .container a:hover:before {
            width: 100%;
            border-radius: 0.3rem; }
  .slider .button-prev {
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    position: absolute;
    left: 50px;
    top: 50%;
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: #fff;
    z-index: 3;
    font-size: 23px;
    cursor: pointer;
    -webkit-transition: all .35s ease-in-out;
    -moz-transition: all .35s ease-in-out;
    -ms-transition: all .35s ease-in-out;
    -o-transition: all .35s ease-in-out;
    transition: all .35s ease-in-out;
    background: #000000;
    border-color: transparent;
    border-radius: 0.3rem; }
    .slider .button-prev:hover {
      background: #1a1a1a;
      border-color: transparent; }
  .slider .button-next {
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    position: absolute;
    right: 50px;
    top: 50%;
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: #fff;
    z-index: 3;
    font-size: 23px;
    cursor: pointer;
    -webkit-transition: all .35s ease-in-out;
    -moz-transition: all .35s ease-in-out;
    -ms-transition: all .35s ease-in-out;
    -o-transition: all .35s ease-in-out;
    transition: all .35s ease-in-out;
    background: #000000;
    border-color: transparent;
    border-radius: 0.3rem; }
    .slider .button-next:hover {
      background: #1a1a1a;
      border-color: transparent;
      border-radius: 0.3rem; }

/* PAGE HEADER */
.page-header {
  width: 100%;
  height: 556px;
  max-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  background-size: cover !important; }
  .page-header:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; }
  .page-header .container {
    position: relative;
    z-index: 1;
    color: #fff; }
    .page-header .container h2 {
      width: 100%;
      display: block;
      font-size: 55px;
      margin-bottom: 0;
      line-height: 6rem;
      font-weight: 700;
      width: 100%;
      margin-top: 7rem;
      display: flex;
      justify-content: center;
      flex-wrap: wrap; }
    .page-header .container p {
      width: 100%;
      display: block;
      margin-bottom: 98px;
      font-size: 20px;
      width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap; }

h3.h3-baslik-anasayfa-beyaz.owl-fadeUp-in {
  width: 100%;
  display: block;
  font-size: 45px;
  margin-bottom: 0;
  line-height: 5rem;
  font-weight: 700; }

/* CONTENT SECTION */
.content-section {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 83px 0;
  position: relative;
  background: #000000; }
  .content-section.left-white-spacing {
    position: relative; }
    .content-section.left-white-spacing .container {
      position: relative;
      z-index: 1; }
    .content-section.left-white-spacing:before {
      content: "";
      width: 30%;
      height: 100%;
      background: #fff;
      position: absolute;
      left: 0;
      top: 0; }
  .content-section.bottom-dark-spacing {
    position: relative;
    padding-bottom: 0 !important; }
    .content-section.bottom-dark-spacing .container {
      position: relative;
      z-index: 1; }
    .content-section.bottom-dark-spacing:before {
      content: "";
      width: 30%;
      height: 100%;
      background: #fff;
      position: absolute;
      left: 0;
      top: 0; }
    .content-section.bottom-dark-spacing:after {
      content: "";
      width: 100%;
      height: 140px;
      background: #000000;
      position: absolute;
      left: 0;
      bottom: 0; }

/* SECTION TITLE */
.section-title {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
  text-align: center; }
  .section-title figure {
    width: 100%;
    display: block;
    margin-bottom: 15px; }
    .section-title figure img {
      height: 40px; }
  .section-title h6 {
    width: 100%;
    display: block; }
  .section-title h2 {
    width: 100%;
    display: block;
    margin-bottom: 0;
    font-size: 70px;
    color: #000000; }
  .section-title p {
    width: 100%;
    display: block;
    margin-bottom: 0;
    opacity: 0.7; }

/* IMAGE BOX */
.col-lg-4:nth-child(1) .image-box {
  padding-right: 30px; }

.col-lg-4:nth-child(2) .image-box {
  margin-top: 60px; }

.col-lg-4:nth-child(3) .image-box {
  padding-left: 30px; }

.image-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap; }
  .image-box figure {
    width: 100%;
    display: block;
    margin-bottom: 20px; }
    .image-box figure img {
      width: 100%; }
  .image-box .time {
    display: inline-block;
    color: #000000;
    margin-right: 6px;
    margin-top: 4px; }
  .image-box h6 {
    display: block;
    font-size: 32px; }

:root {
  --body-bg-color: #1a1c1d;
  --text-color: #aaaebc;
  --hr-color: #26292a;
  --transition-duration: 0.3s;
  --transition-delay: 0.2s;
  --red: #e74c3c; }

hr {
  border-color: var(--hr-color);
  margin: 20px 0; }

.menu {
  display: flex;
  justify-content: center; }

.menu li {
  margin-right: 70px; }

.menu a {
  position: relative;
  padding: 10px; }

.menu .border {
  position: absolute;
  left: 0;
  background: currentColor;
  transition: transform var(--transition-duration) ease-in-out; }

.menu .border-top,
.menu .border-bottom {
  width: 100%;
  height: 1px;
  transform: scaleX(0); }

.menu .border-left,
.menu .border-right {
  width: 1px;
  height: 100%;
  transform: scaleY(0); }

.menu .border-top,
.menu .border-left,
.menu .border-right {
  top: 0; }

.menu .border-bottom {
  bottom: 0;
  transform-origin: bottom right; }

.menu .border-top {
  transform-origin: top left; }

.menu .border-left {
  transform-origin: bottom left; }

.menu .border-right {
  left: auto;
  right: 0;
  transform-origin: top right; }

.menu a:hover .border-top,
.menu a:hover .border-bottom {
  transform: scaleX(1); }

.menu a:hover .border-left,
.menu a:hover .border-right {
  transform: scaleY(1); }

.menu a:hover .border-right {
  transition-delay: var(--transition-delay); }

.menu a:hover .border-bottom {
  transition-delay: calc(var(--transition-delay) * 2); }

.menu a:hover .border-left {
  transition-delay: calc(var(--transition-delay) * 3); }

/* ANIMATIONS
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.menu[data-animation="diagonal"] .border-left {
  transform-origin: top left; }

.menu[data-animation="diagonal"] .border-right,
.menu[data-animation="diagonal"] .border-bottom {
  transform-origin: bottom right; }

.menu[data-animation="diagonal"] a:hover [class^=border] {
  transition-delay: 0s; }

/* SIDE CONTENT */
.side-content {
  width: 100%;
  display: flex;
  flex-wrap: wrap; }
  .side-content.left {
    padding-right: 20%; }
  .side-content.right {
    padding-left: 10%; }
  .side-content.light {
    color: #fff; }
    .side-content.light h2 {
      color: #fff; }
  .side-content h2 {
    width: 100%;
    display: block;
    font-size: 50px;
    font-weight: 800;
    color: #fff; }
  .side-content h6 {
    width: 100%;
    display: block;
    font-weight: 800;
    font-size: 19px;
    color: #ffffff;
    text-align: center; }
  .side-content .custom-button {
    margin-top: 30px; }
  .side-content figure {
    width: 100%;
    display: block; }
    .side-content figure img {
      height: 100px; }
  .side-content form {
    width: 100%;
    display: flex;
    margin-top: 40px; }
    .side-content form input[type="text"] {
      border: none; }
    .side-content form button[type="submit"] {
      width: 70px;
      padding: 0;
      text-align: center;
      margin-left: -70px;
      background: none;
      color: #000000; }

/* SIDE IMAGE */
.side-image {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  position: relative; }
  .side-image.full-left {
    width: 47vw;
    float: right; }
  .side-image.full-right {
    width: 49.6vw;
    float: left; }
  .side-image img {
    width: 100%; }
  .side-image .side-timetable {
    width: 340px;
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    left: -50px;
    bottom: 50px;
    background: #000000;
    padding: 40px;
    margin: 0;
    z-index: 1; }
    .side-image .side-timetable li {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin: 10px 0;
      padding: 0;
      list-style: none; }
      .side-image .side-timetable li span {
        color: #fff; }
      .side-image .side-timetable li b {
        font-weight: 400;
        margin-left: auto;
        color: #000000; }

/* SIDE GALLERY */
.side-gallery {
  width: calc(50vw - 15px);
  display: flex;
  flex-wrap: wrap;
  margin: 0; }
  .side-gallery figure {
    width: calc(33.33333% - 10px);
    display: inline-block;
    margin: 5px 0;
    margin-left: 10px;
    background: #000000;
    position: relative; }
    .side-gallery figure:before {
      content: "";
      width: 4px;
      height: 50px;
      background: #fff;
      position: absolute;
      left: calc(50% - 2px);
      top: calc(50% - 25px);
      z-index: 1;
      -webkit-transition: all .35s ease-in-out;
      -moz-transition: all .35s ease-in-out;
      -ms-transition: all .35s ease-in-out;
      -o-transition: all .35s ease-in-out;
      transition: all .35s ease-in-out;
      opacity: 0; }
    .side-gallery figure:after {
      content: "";
      width: 50px;
      height: 4px;
      background: #fff;
      position: absolute;
      left: calc(50% - 25px);
      top: calc(50% - 2px);
      z-index: 1;
      -webkit-transition: all .35s ease-in-out;
      -moz-transition: all .35s ease-in-out;
      -ms-transition: all .35s ease-in-out;
      -o-transition: all .35s ease-in-out;
      transition: all .35s ease-in-out;
      opacity: 0; }
    .side-gallery figure img {
      width: 100%;
      -webkit-transition: all .35s ease-in-out;
      -moz-transition: all .35s ease-in-out;
      -ms-transition: all .35s ease-in-out;
      -o-transition: all .35s ease-in-out;
      transition: all .35s ease-in-out; }
    .side-gallery figure:hover img {
      opacity: 0.3; }
    .side-gallery figure:hover:before {
      opacity: 1; }
    .side-gallery figure:hover:after {
      opacity: 1; }

/* SIDE MEMBER */
.side-member {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  overflow: hidden;
  margin-bottom: 0; }
  .side-member img {
    width: 100%;
    display: block; }
  .side-member figcaption {
    width: 100%;
    height: 140px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background: #000000;
    color: #fff;
    text-align: center; }
    .side-member figcaption h5 {
      width: 100%;
      display: block;
      font-size: 50px;
      line-height: 1;
      font-weight: 500;
      margin-top: auto;
      margin-bottom: 0; }
    .side-member figcaption span {
      width: 100%;
      display: block;
      font-size: 20px;
      margin-bottom: auto; }

/* PROGRESS BAR */
.custom-progress {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 30px; }
  .custom-progress:last-child {
    margin-bottom: 0; }
  .custom-progress h6 {
    display: inline-block;
    font-size: 20px;
    font-weight: 500;
    margin: 0; }
  .custom-progress span {
    margin-left: auto;
    font-size: 20px;
    color: #000000; }
  .custom-progress .progress-bar {
    width: 100%;
    height: 5px;
    background: #eee;
    display: inline-block;
    margin-top: 10px;
    border-radius: 0;
    position: relative; }
    .custom-progress .progress-bar .progress {
      width: 0;
      height: 5px;
      background: #000000;
      position: absolute;
      left: 0;
      top: 0;
      transition-duration: 1s;
      -webkit-transition-duration: 1s;
      transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
      -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1); }
    .custom-progress .progress-bar.animated .one {
      width: 80%; }
    .custom-progress .progress-bar.animated .two {
      width: 67%; }
    .custom-progress .progress-bar.animated .three {
      width: 92%; }
    .custom-progress .progress-bar.animated .four {
      width: 88%; }

/* TAB WRAPPER */
.tab-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap; }
  .tab-wrapper .tab-nav {
    width: 25%;
    margin: 0;
    padding: 0;
    padding-right: 40px;
    position: relative;
    z-index: 1; }
    .tab-wrapper .tab-nav li {
      width: 100%;
      display: block;
      margin-bottom: 10px;
      list-style: none; }
      .tab-wrapper .tab-nav li.active a {
        width: calc(100% + 60px);
        margin-right: -60px;
        background: #000000;
        color: #fff; }
        .tab-wrapper .tab-nav li.active a:hover {
          background: #000000; }
      .tab-wrapper .tab-nav li a {
        width: 100%;
        display: block;
        background: #f4f4f4;
        padding: 25px;
        font-weight: 700; }
        .tab-wrapper .tab-nav li a:hover {
          background: #f0f0f0;
          text-decoration: none; }
  .tab-wrapper .tab-item {
    width: 75%;
    display: none; }
    .tab-wrapper .tab-item.active-item {
      display: flex; }
    .tab-wrapper .tab-item .tab-inner {
      width: 100%;
      display: flex;
      position: relative;
      background: #000000; }
      .tab-wrapper .tab-item .tab-inner ul {
        width: calc(350px - 100px);
        height: 40vw;
        overflow: auto;
        float: left;
        color: #fff;
        margin: 40px;
        margin-left: 60px;
        padding: 0; }
        .tab-wrapper .tab-item .tab-inner ul li {
          width: 100%;
          display: block;
          margin-bottom: 20px;
          padding: 0;
          list-style: none; }
          .tab-wrapper .tab-item .tab-inner ul li span {
            width: 100%;
            display: block;
            color: #000000; }
          .tab-wrapper .tab-item .tab-inner ul li h6 {
            width: 100%;
            display: block; }
          .tab-wrapper .tab-item .tab-inner ul li small {
            width: 100%;
            display: block;
            
            opacity: 0.7; }
      .tab-wrapper .tab-item .tab-inner figure {
        width: 100%;
        float: left;
        margin: 0; }
        .tab-wrapper .tab-item .tab-inner figure img {
          width: 100%; }

/* COUNTER BOX */
.counter-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 4px;
  text-align: center;
  border-radius: 0.3rem; }
  .counter-box figure {
    width: 100%;
    display: block;
    margin-bottom: -16px; }
    .counter-box figure img {
      height: 109px; }
  .counter-box .odometer {
    line-height: 1;
    margin: 0 auto 20px;
    font-size: 45px;
    color: #000;
    font-weight: 700; }
  .counter-box h6 {
    width: 100%;
    height: 26px;
    line-height: 26px;
    display: block;
    font-size: 22px;
    margin-bottom: 22px;
    margin-top: -11px;
    color: #000;
    position: relative;
    font-weight: 700; }
    .counter-box h6:after {
      content: "";
      width: 93px;
      height: 8px;
      border-radius: 100px;
      background: #000000;
      position: absolute;
      left: calc(50% - 45px);
      bottom: -18px; }

/* SERVICE BOX */
.service-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  margin: 15px 0;
  background: #000000; }
  .service-box:before {
    content: "";
    width: 100%;
    height: 100%;
    background: #000000;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    -webkit-transition: all .35s ease-in-out;
    -moz-transition: all .35s ease-in-out;
    -ms-transition: all .35s ease-in-out;
    -o-transition: all .35s ease-in-out;
    transition: all .35s ease-in-out; }
  .service-box:hover img {
    opacity: 0;
    transform: scale(1.1); }
  .service-box:hover figcaption p {
    margin-bottom: 30px;
    margin-top: 10px;
    opacity: 0.7; }
  .service-box:hover figcaption a {
    margin-bottom: 0;
    opacity: 1; }
  .service-box:hover:before {
    transform: scale(1.1); }
  .service-box * {
    -webkit-transition: all .35s ease-in-out;
    -moz-transition: all .35s ease-in-out;
    -ms-transition: all .35s ease-in-out;
    -o-transition: all .35s ease-in-out;
    transition: all .35s ease-in-out; }
  .service-box img {
    width: 100%;
    position: relative; }
  .service-box figcaption {
    width: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 1;
    transform: translateY(-50%);
    color: #fff;
    text-align: center; }
    .service-box figcaption h6 {
      width: 100%;
      display: block;
      font-size: 36px;
      font-weight: 700;
      margin: 0;
      line-height: 1; }
    .service-box figcaption p {
      width: 100%;
      display: block;
      padding: 0 10%;
      margin-bottom: -100px;
      opacity: 0; }
    .service-box figcaption a {
      height: 70px;
      line-height: 68px;
      display: inline-block;
      border: 2px solid #fff;
      color: #fff;
      margin-bottom: -100px;
      opacity: 0;
      padding: 0 50px; }
      .service-box figcaption a:hover {
        text-decoration: none;
        background: #fff;
        color: #000000; }

.absoluteimg2 {
  position: absolute;
  left: 45% !important;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 90%;
  height: auto;
  margin-top: -875px; }

/* IMAGE OVERLAP BOX */
.image-overlap-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden; }
  .image-overlap-box * {
    transition-duration: 1s;
    -webkit-transition-duration: 1s;
    transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
    -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1); }
  .image-overlap-box:hover figure img {
    opacity: 0.3;
    transform: scale(1.05); }
  .image-overlap-box:hover .content img {
    margin-top: 0;
    margin-bottom: -100px;
    opacity: 0.2;
    transform: scale(1.4); }
  .image-overlap-box:hover .content p {
    margin-bottom: 40px;
    opacity: 1; }
  .image-overlap-box:hover .content a {
    margin-bottom: 0;
    opacity: 1; }
  .image-overlap-box figure {
    width: 100%;
    display: block;
    margin: 0;
    background: #000000; }
    .image-overlap-box figure img {
      width: 100%; }
  .image-overlap-box .content {
    width: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    text-align: center;
    transform: translateY(-50%); }
    .image-overlap-box .content img {
      height: 80px;
      display: inline-block;
      margin-bottom: 20px;
      margin-top: 100px; }
    .image-overlap-box .content h6 {
      width: 100%;
      display: block;
      font-size: 30px;
      font-weight: 500;
      color: #fff; }
    .image-overlap-box .content p {
      width: 100%;
      display: block;
      padding: 0 10%;
      color: #fff;
      margin-bottom: 0;
      opacity: 0; }
    .image-overlap-box .content a {
      height: 70px;
      line-height: 68px;
      display: inline-block;
      border: 2px solid #fff;
      color: #fff;
      margin-bottom: -100px;
      opacity: 0;
      padding: 0 50px; }
      .image-overlap-box .content a:hover {
        text-decoration: none;
        background: #fff;
        color: #000000; }

/* CUSTOM LIST */
.custom-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin-bottom: 20px; }
  .custom-list li {
    width: 100%;
    display: block;
    margin-bottom: 15px;
    padding: 0;
    list-style: none;
    
    font-size: 20px; }
    .custom-list li:last-child {
      margin-bottom: 0; }
    .custom-list li:before {
      font-size: 16px;
      display: inline-block;
      margin-right: 12px; }

/* VIDEO */
.video {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
  background: #000000; }
  .video img {
    width: 100%;
    -webkit-transition: all .35s ease-in-out;
    -moz-transition: all .35s ease-in-out;
    -ms-transition: all .35s ease-in-out;
    -o-transition: all .35s ease-in-out;
    transition: all .35s ease-in-out; }
  .video:hover img {
    opacity: 0.8; }
  .video a {
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    left: calc(50% - 60px);
    top: calc(50% - 60px);
    color: #000000;
    font-size: 30px; }
    .video a:hover {
      text-decoration: none;
      transform: scale(1.1); }

/* CAROUSEL CLASSES */
.carousel-classes {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
  padding-bottom: 50px; }
  .carousel-classes .swiper-pagination {
    bottom: 0; }

/* ALL CLASSES */
.all-classes {
  width: calc(100% + 30px);
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  margin-bottom: 100px;
  padding: 0; }
  .all-classes li {
    width: 33.33333%;
    margin: 0;
    margin-top: 50px;
    padding: 0 15px;
    list-style: none; }
    .all-classes li:nth-child(1), .all-classes li:nth-child(2), .all-classes li:nth-child(3) {
      margin-top: 0; }
    .all-classes li:nth-child(3n+2) {
      transform: translateY(100px); }

/* CLASS BOX */
.class-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  border-radius: 0.3rem; }
  .class-box:hover figure img {
    opacity: 0.3;
    transform: scale(1.05);
    border-radius: 0.3rem; }
  .class-box figure {
    width: 100%;
    display: block;
    margin-bottom: 20px;
    background: #000000;
    overflow: hidden;
    border-radius: 0.3rem; }
    .class-box figure img {
      width: 100%;
      -webkit-transition: all .35s ease-in-out;
      -moz-transition: all .35s ease-in-out;
      -ms-transition: all .35s ease-in-out;
      -o-transition: all .35s ease-in-out;
      transition: all .35s ease-in-out;
      border-radius: 0.3rem; }
  .class-box h6 {
    width: 100%;
    display: block;
    font-size: 42px;
    font-weight: 700;
    padding: 0 6%; }
  .class-box small {
    width: 100%;
    display: block;
    font-size: 16px;
    opacity: 0.7; }

/* IMAGE */
.image {
  width: 100%;
  display: block;
  margin-bottom: 30px; }
  .image.spacing {
    margin: 40px 0; }
  .image img {
    width: 100%; }

/* TEXT BOX */
.text-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap; }
  .text-box h3 {
    width: 100%;
    display: block;
    font-size: 36px;
    font-weight: 700; }
  .text-box h5 {
    width: 100%;
    display: block;
    color: #000000;
    font-weight: 500;
    margin-bottom: 0;
    font-size: 22px; }
  .text-box p {
    width: 100%;
    display: block;
    margin-bottom: 20px; }

/* PASS BOX */
.col-lg-6:nth-child(1) .pass-box {
  border-right: 1px solid rgba(255, 255, 255, 0.1); }

.pass-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  text-align: center;
  color: #fff; }
  .pass-box figure {
    width: 100%;
    display: block;
    margin-bottom: 30px; }
    .pass-box figure img {
      height: 80px; }
  .pass-box h6 {
    width: 100%;
    display: block;
    font-size: 50px;
    font-weight: 800; }
  .pass-box p {
    width: 100%;
    display: block;
    padding: 0 20%;
    margin-bottom: 0; }

/* RECENT NEWS */
.recent-news {
  width: 100%;
  display: flex;
  flex-wrap: wrap; }
  .recent-news:hover figure img {
    opacity: 0.3;
    transform: scale(1.05); }
  .recent-news figure {
    width: 100%;
    display: block;
    margin: 0;
    background: #000000;
    position: relative;
    overflow: hidden; }
    .recent-news figure img {
      width: 100%;
      -webkit-transition: all .35s ease-in-out;
      -moz-transition: all .35s ease-in-out;
      -ms-transition: all .35s ease-in-out;
      -o-transition: all .35s ease-in-out;
      transition: all .35s ease-in-out; }
  .recent-news .content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 40px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-top: none; }
    .recent-news .content h3 {
      width: 100%;
      display: block;
      margin-bottom: 15px;
      font-weight: 500;
      font-size: 34px; }
      .recent-news .content h3 a {
        display: inline-block;
        color: #000000; }
        .recent-news .content h3 a:hover {
          color: #000000;
          text-decoration: none; }
    .recent-news .content p {
      width: 100%;
      display: block;
      margin-bottom: 25px;
      opacity: 0.7; }
    .recent-news .content small {
      width: 100%;
      display: block;
      font-size: 16px; }
      .recent-news .content small span {
        width: 5px;
        height: 5px;
        display: inline-block;
        border-radius: 50%;
        background: #000000;
        margin: 0 15px;
        transform: translateY(-3px); }

/* BLOG BOX */
.blog-box {
  width: 100%;
  display: block;
  position: relative;
  margin-bottom: 100px; }
  .blog-box:last-child {
    margin-bottom: 0; }
  .blog-box:hover .content h3 a {
    background-size: 100% 100%; }
  .blog-box figure {
    width: 100%;
    display: block;
    margin-bottom: 30px;
    position: relative;
    overflow: hidden;
    background: #000000; }
    .blog-box figure img {
      width: 100%;
      max-width: inherit; }
  .blog-box .content {
    width: 100%;
    height: 100%;
    display: block;
    background: #fff; }
    .blog-box .content small {
      display: block;
      font-size: 15px;
      opacity: 0.6;
      margin-bottom: 10px;
      text-transform: uppercase; }
    .blog-box .content h3 {
      width: 100%;
      display: block;
      margin-bottom: 20px;
      font-size: 56px;
      line-height: 1.1;
      font-weight: 800; }
      .blog-box .content h3 a {
        display: block;
        color: #000000;
        -webkit-transition: all .35s ease-in-out;
        -moz-transition: all .35s ease-in-out;
        -ms-transition: all .35s ease-in-out;
        -o-transition: all .35s ease-in-out;
        transition: all .35s ease-in-out; }
        .blog-box .content h3 a:hover {
          color: #000000;
          text-decoration: none; }
    .blog-box .content .author {
      width: 100%;
      display: block;
      margin-bottom: 0;
      font-weight: 500;
      font-size: 20px; }
      .blog-box .content .author img {
        height: 70px;
        display: inline-block;
        border-radius: 50%;
        margin-right: 15px; }
      .blog-box .content .author b {
        font-weight: 500;
        opacity: 0.6; }
    .blog-box .content h6 {
      font-size: 24px;
      line-height: 1.7;
      margin: 30px 0; }
    .blog-box .content strong {
      font-weight: 600; }
    .blog-box .content figure {
      margin: 30px 0; }
    .blog-box .content blockquote {
      width: 100%;
      display: block;
      color: #000000;
      font-size: 26px;
      
      margin-bottom: 30px; }
      .blog-box .content blockquote:before {
        content: "“";
        font-size: 100px;
        height: 50px;
        line-height: 0.9;
        display: block; }
    .blog-box .content ul {
      padding-left: 20px; }
      .blog-box .content ul li {
        margin: 4px 0; }
    .blog-box .content .half-image {
      width: 50%;
      float: right;
      margin-left: 20px; }
    .blog-box .content .full-width {
      width: calc(100% + 100px);
      float: left;
      margin-left: -50px;
      margin-right: -50px; }

/* SIDEBAR */
.sidebar {
  width: 100%;
  display: block;
  padding-left: 30px; }
  .sidebar .widget {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #eee;
    padding: 40px;
    margin-bottom: 40px;
    position: relative; }
    .sidebar .widget * {
      position: relative; }
    .sidebar .widget .widget-title {
      width: 100%;
      display: block;
      position: relative;
      z-index: 1;
      font-weight: 800;
      letter-spacing: 1px;
      font-size: 22px;
      color: #000000;
      margin-bottom: 20px;
      padding-bottom: 20px; }
      .sidebar .widget .widget-title:before {
        content: "";
        width: 100%;
        height: 2px;
        background: #000000;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: -1; }
    .sidebar .widget form {
      width: 100%;
      display: block;
      margin-top: 10px; }
      .sidebar .widget form input[type="submit"] {
        margin-top: 10px;
        background: #000000;
        color: #fff; }
    .sidebar .widget .categories {
      width: 100%;
      display: block;
      margin: 0;
      padding: 0; }
      .sidebar .widget .categories li {
        width: 100%;
        display: block;
        margin: 4px 0;
        padding: 0;
        list-style: none; }
        .sidebar .widget .categories li a {
          color: #000000;
          font-size: 19px; }
    .sidebar .widget .side-gallery {
      width: calc(100% + 4px);
      float: left;
      margin: 0 -2px;
      padding: 0; }
      .sidebar .widget .side-gallery li {
        width: 50%;
        float: left;
        margin: 0;
        padding: 2px;
        list-style: none; }

/* BRANCH BOX */
.branch-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap; }
  .branch-box h6 {
    width: 100%;
    display: block;
    font-weight: 800;
    font-size: 24px;
    color: #000000; }
  .branch-box address {
    width: 100%;
    display: block;
    margin-bottom: 10px; }
    .branch-box address b {
      width: 100%;
      display: block;
      margin-top: 5px;
      font-weight: 500; }
  .branch-box a {
    display: inline-block;
    text-decoration: underline; }
    .branch-box a:hover {
      text-decoration: none;
      color: #000000; }

/* MEMBER BOX */
.member-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  overflow: hidden;
  margin: 0; }
  .member-box:hover figcaption {
    bottom: 0;
    transform: translateY(0); }
  .member-box img {
    width: 100%;
    display: block; }
  .member-box figcaption {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    border-radius: 0.3rem;
    left: 0;
    bottom: 120px;
    color: #fff;
    transform: translateY(100%);
    background: #000000;
    text-align: center;
    padding: 30px;
    -webkit-transition: all .35s ease-in-out;
    -moz-transition: all .35s ease-in-out;
    -ms-transition: all .35s ease-in-out;
    -o-transition: all .35s ease-in-out;
    transition: all .35s ease-in-out; }
    .member-box figcaption h6 {
      width: 100%;
      display: block;
      font-size: 31px;
      font-weight: 700; }
    .member-box figcaption small {
      width: 100%;
      display: block;
      margin-bottom: 15px; }
    .member-box figcaption p {
      width: 100%;
      display: block;
      padding: 0 10%;
      opacity: 0.7; }
    .member-box figcaption ul {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 0;
      padding: 0; }
      .member-box figcaption ul li {
        display: inline-block;
        margin: 0 7px;
        padding: 0;
        list-style: none; }
        .member-box figcaption ul li a {
          color: #fff;
          float: left;
          font-size: 13px; }

/* CTA BOX */
.cta-box {
  width: 100%;
  max-width: 600px;
  display: inline-block;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 50px;
  text-align: center; }
  .cta-box h2 {
    width: 100%;
    display: block;
    font-size: 50px;
    font-weight: 500; }
  .cta-box p {
    width: 100%; }
  .cta-box .custom-button {
    margin-top: 10px !important; }

/* TESTIMONIALS */
.testimonial {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  text-align: center; }
  .testimonial blockquote {
    width: 100%;
    display: block;
    font-size: 32px;
     }
  .testimonial p {
    width: 100%;
    display: block; }
  .testimonial i {
    display: inline-block;
    margin: 0 3px;
    color: #000000; }
  .testimonial h6 {
    width: 100%;
    display: block;
    margin: 0; }
  .testimonial figure {
    width: 100%;
    display: block;
    margin: 0; }
    .testimonial figure img {
      height: 440px; }

/* CONTACT BOX */
.contact-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
   }
  .contact-box li {
    width: 100%;
    display: flex;
    margin-bottom: 15px;
    padding: 0;
    list-style: none;
    line-height: 1.2; }
    .contact-box li:last-child {
      margin-bottom: 0; }
    .contact-box li h6 {
      width: 100px;
      display: inline-block;
      color: #000000;
      margin: 0; }
    .contact-box li span {
      display: inline-block; }
    .contact-box li a {
      display: inline-block;
      text-decoration: underline; }

/* CONTACT FORM */
.contact-form {
  width: 100%;
  display: block;
  margin: 0;
  padding: 0; }
  .contact-form .form-group {
    width: 100%;
    display: block; }
    .contact-form .form-group:last-child {
      margin-bottom: 0; }

/* GOOGLE MAPS */
.google-maps {
  width: 100%;
  display: block;
  position: relative; }
  .google-maps iframe {
    width: 100%;
    height: 500px;
    display: block;
    border: none;
    filter: grayscale(1);
    position: relative;
    z-index: 0; }
  .google-maps .timetable {
    width: 340px;
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    left: 100px;
    top: 50%;
    transform: translateY(-50%);
    background: #000000;
    padding: 40px;
    margin: 0;
    z-index: 1; }
    .google-maps .timetable li {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin: 10px 0;
      padding: 0;
      list-style: none; }
      .google-maps .timetable li span {
        color: #fff; }
      .google-maps .timetable li b {
        font-weight: 400;
        margin-left: auto;
        color: #000000; }

/* icon */
.icon {
  width: 105px;
  height: 98px;
  line-height: 75px;
  border-radius: 5px;
  color: #ffffff;
  font-size: 50px;
  -webkit-transition: .5s;
  transition: .5s;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  font-size: 6rem;
  background: #000000;
  padding: 10px;
  text-align: center; }

.icon:hover {
  color: #f5f5f5; }

/* icon */
.icon-project-detail {
  width: 105px;
  height: 98px;
  line-height: 75px;
  border-radius: 5px;
  color: #000000;
  font-size: 50px;
  -webkit-transition: .5s;
  transition: .5s;
  margin-right: auto;
  margin-bottom: 15px;
  font-size: 6rem;
  padding: 10px;
  text-align: left; }

i.flaticon-right-chevron {
  font-size: 12px;
  font-weight: 800;
  padding: 9px; }

i.flaticon-road {
  font-size: 35px; }

i.flaticon-phone-call {
  font-size: 25px; }

i.flaticon-mail {
  font-size: 25px; }

.iconp {
  font-size: 3.5rem;
  float: left;
  margin-top: 0.5rem; }

.iconpfooter {
  font-size: 30px;
  float: left; }

/*icon white*/
.iconw {
  width: 90px;
  height: 93px;
  line-height: 80px;
  border-radius: 5px;
  color: #fff;
  font-size: 50px;
  -webkit-transition: .5s;
  transition: .5s;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  font-size: 7rem; }

.iconw:hover {
  color: #ebebeb; }

i.flaticon-down-arrow {
  color: #efefef;
  font-size: 13px;
  opacity: .5; }

.sidebar-service {
  background: #000000;
  display: inline-grid;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  text-align: left;
  border-radius: 0.3rem; }

span.menu-service {
  font-size: 20px;
  padding: 14px 15px 14px 18px;
  font-weight: 600;
  font-size: 20px;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  /* color: #737373; */
  position: relative;
  margin-bottom: 2px;
  border-radius: 0.3rem;
  cursor: pointer; }

/* span.menu-service:hover {
  background: #363636; } */

span.menu-service.menuactive {
  background: #363636; }

/* icon */
.iconleft {
  width: 90px;
  height: 93px;
  border-radius: 5px;
  color: #000000;
  font-size: 50px;
  -webkit-transition: .5s;
  transition: .5s;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  font-size: 5rem; }

.iconleft:hover {
  color: #000; }

/* icon */
.iconleft2 {
  width: 90px;
  border-radius: 5px;
  color: #000000;
  transition: .5s;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 6px;
  font-size: 5rem;
  margin-top: -2rem; }

.iconleft2.banner {
  color: #000; }

.sidebar-blog {
  display: inline-grid;
  align-items: center;
  text-align: left;
  border-radius: 7px;
  width: 100%;
  flex-wrap: wrap;
  background: #fff;
  box-shadow: 3px 3px 13px 0 rgba(0, 0, 0, 0.062); }

span.menu-blog {
  font-size: 20px;
  padding: 14px 15px 14px 18px;
  font-weight: 600;
  font-size: 20px;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  color: #232323;
  position: relative;
  margin-bottom: 2px;
  border-radius: 0.3rem;
  cursor: pointer;
  border-bottom: 0.1rem solid #eee; }

a.menucolor {
  color: #666666; }

a.menucolor:hover {
  color: #000000;
  transition: all 2ms; }

.imagerotate {
  position: absolute;
  top: 0%;
  left: 10%;
  margin: -38px 0 0 -60px;
  -webkit-animation: spin 15s linear infinite;
  -moz-animation: spin 15s linear infinite;
  animation: spin 15s linear infinite; }
  @media only screen and (max-width: 56.25em) {
    .imagerotate {
      width: 352px;
      left: 0%;
      margin: 3px 0 0 -60px; } }

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg); } }
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }
.imagerotate1 {
  position: absolute;
  top: 0%;
  left: 10%;
  margin: -38px 0 0 -60px; }
  @media only screen and (max-width: 56.25em) {
    .imagerotate1 {
      width: 352px;
      left: 0%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: auto;
      position: relative; } }

.imagerotate2 {
  position: absolute;
  top: 0%;
  left: 10%;
  margin: -38px 0 0 -60px;
  animation: updowns1 2s ease infinite; }
  @media only screen and (max-width: 56.25em) {
    .imagerotate2 {
      width: 352px;
      left: 0%;
      display: none; } }

@keyframes updowns1 {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px); }
  50% {
    -webkit-transform: translateY(0px);
    transform: translateY(35px); }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px); } }
.imagerotate3 {
  position: absolute;
  top: 0%;
  left: 10%;
  margin: -38px 0 0 -60px;
  animation: pulse 3s ease infinite alternate,  nudge 5s linear infinite alternate; }
  @media only screen and (max-width: 56.25em) {
    .imagerotate3 {
      width: 352px;
      left: 0%;
      display: none; } }

@keyframes nudge {
  0%, 100% {
    transform: translate(0, 0); }
  50% {
    transform: translate(10px, 0); }
  80% {
    transform: translate(-10px, 0); } }
.imagerotate4 {
  position: absolute;
  top: 0%;
  left: 10%;
  margin: -38px 0 0 -60px;
  animation: updown3 2s ease infinite; }
  @media only screen and (max-width: 56.25em) {
    .imagerotate4 {
      width: 352px;
      left: 0%;
      display: none; } }

@keyframes updown3 {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px); }
  50% {
    -webkit-transform: translateY(0px);
    transform: translateY(5px); }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px); } }
.imagerotate5 {
  position: absolute;
  top: 0%;
  left: 10%;
  margin: -38px 0 0 1px;
  animation: updown0 2s ease infinite; }
  @media only screen and (max-width: 56.25em) {
    .imagerotate5 {
      width: 352px;
      left: 0%;
      display: none; } }

@keyframes updown0 {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px); }
  50% {
    -webkit-transform: translateY(0px);
    transform: translateY(20px); }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px); } }
.imagerotate6 {
  position: absolute;
  top: 0%;
  left: 10%;
  margin: -38px 0 0 -60px;
  animation: updown1 2s ease infinite; }
  @media only screen and (max-width: 56.25em) {
    .imagerotate6 {
      width: 352px;
      left: 0%;
      display: none; } }

@keyframes updown1 {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px); }
  50% {
    -webkit-transform: translateY(0px);
    transform: translateY(5px); }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px); } }
.imagerotate7 {
  position: absolute;
  top: 0%;
  left: 10%;
  margin: -38px 0 0 -60px;
  animation: pulse32 3s ease infinite alternate,  nudge32 5s linear infinite alternate; }
  @media only screen and (max-width: 56.25em) {
    .imagerotate7 {
      width: 352px;
      left: 0%;
      display: none; } }

@keyframes nudge32 {
  0%, 100% {
    transform: translate(0, 0); }
  50% {
    transform: translate(10px, 0); }
  80% {
    transform: translate(-10px, 0); } }
.imagerotate8 {
  position: absolute;
  top: 0%;
  left: 10%;
  margin: -38px 0 0 -60px;
  animation: pulse33 3s ease infinite alternate,  nudge33 5s linear infinite alternate; }
  @media only screen and (max-width: 56.25em) {
    .imagerotate8 {
      width: 352px;
      left: 0%;
      display: none; } }

@keyframes nudge33 {
  0%, 100% {
    transform: translate(0, 0); }
  50% {
    transform: translate(10px, 0); }
  80% {
    transform: translate(-10px, 0); } }
.infobgrotate1 {
  position: absolute;
  top: 0%;
  left: 10%;
  margin: 339px 0 0 -567px;
  -webkit-animation: spin42 15s linear infinite;
  -moz-animation: spin42 15s linear infinite;
  animation: spin42 15s linear infinite; }
  @media only screen and (max-width: 56.25em) {
    .infobgrotate1 {
      width: 352px;
      left: 0%;
      display: none; } }

@-moz-keyframes spin42 {
  100% {
    -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin42 {
  100% {
    -webkit-transform: rotate(360deg); } }
@keyframes spin42 {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }
.infobgrotate2 {
  position: absolute;
  top: 0%;
  left: 10%;
  margin: 210px 0 0 -1004px;
  -webkit-animation: spin43 15s linear infinite;
  -moz-animation: spin43 15s linear infinite;
  animation: spin43 15s linear infinite; }
  @media only screen and (max-width: 56.25em) {
    .infobgrotate2 {
      width: 352px;
      left: 0%;
      display: none; } }

@-moz-keyframes spin43 {
  0% {
    -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin43 {
  0% {
    -webkit-transform: rotate(360deg); } }
@keyframes spin43 {
  0% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }
.infobgrotate3 {
  position: absolute;
  top: 0%;
  left: 10%;
  margin: 0px 0 0 -52px;
  -webkit-animation: spin44 15s linear infinite;
  -moz-animation: spin44 15s linear infinite;
  animation: spin44 15s linear infinite; }
  @media only screen and (max-width: 56.25em) {
    .infobgrotate3 {
      width: 352px;
      left: 0%;
      display: none; } }

@-moz-keyframes spin44 {
  100% {
    -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin44 {
  100% {
    -webkit-transform: rotate(360deg); } }
@keyframes spin44 {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }
.infobgrotate4 {
  position: absolute;
  top: 0%;
  left: 10%;
  margin: 2px 0 0 -50px;
  -webkit-animation: spin45 15s linear infinite;
  -moz-animation: spin45 15s linear infinite;
  animation: spin45 15s linear infinite; }
  @media only screen and (max-width: 56.25em) {
    .infobgrotate4 {
      width: 352px;
      left: 0%;
      display: none; } }

@-moz-keyframes spin45 {
  0% {
    -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin45 {
  0% {
    -webkit-transform: rotate(360deg); } }
@keyframes spin45 {
  0% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }
.infobgrotate5 {
  position: absolute;
  top: 0%;
  left: 10%;
  margin: 0px 0 0 -52px;
  -webkit-animation: spin46 15s linear infinite;
  -moz-animation: spin46 15s linear infinite;
  animation: spin46 15s linear infinite; }
  @media only screen and (max-width: 56.25em) {
    .infobgrotate5 {
      width: 352px;
      left: 0%;
      display: none; } }

@-moz-keyframes spin46 {
  100% {
    -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin46 {
  100% {
    -webkit-transform: rotate(360deg); } }
@keyframes spin46 {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }
.infobgrotate6 {
  position: absolute;
  top: 0%;
  left: 10%;
  margin: 2px 0 0 -50px;
  -webkit-animation: spin47 15s linear infinite;
  -moz-animation: spin47 15s linear infinite;
  animation: spin47 15s linear infinite; }
  @media only screen and (max-width: 56.25em) {
    .infobgrotate6 {
      width: 352px;
      left: 0%;
      display: none; } }

@-moz-keyframes spin47 {
  0% {
    -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin47 {
  0% {
    -webkit-transform: rotate(360deg); } }
@keyframes spin47 {
  0% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }
.absoluteimg {
  position: absolute;
  left: 72% !important;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 56%;
  height: auto;
  margin-top: -123px; }

img.simg {
  margin-top: -597px;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  align-items: center;
  justify-content: center;
  position: relative; }

@-moz-keyframes hizmet-rot {
  0% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg); } }
@keyframes hizmet-rot {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }
@-moz-keyframes hizmet-rot_2 {
  0% {
    -moz-transform: rotateY(0deg);
    transform: rotateY(0deg); }
  100% {
    -moz-transform: rotateY(360deg);
    transform: rotateY(360deg); } }
@keyframes hizmet-rot_2 {
  0% {
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    transform: rotateY(0deg); }
  100% {
    -webkit-transform: rotateY(360deg);
    -moz-transform: rotateY(360deg);
    -o-transform: rotateY(360deg);
    transform: rotateY(360deg); } }
.hizmet-kutu .kutu-duzen {
  position: relative;
  display: block;
  overflow: hidden;
  text-align: center;
  padding: 46px 20px 54px 20px;
  border-radius: 5px;
  transition: all 300ms ease; }

.hizmet-kutu .kutu-duzen:hover {
  background: #000000; }

.hizmet-kutu .kutu-duzen:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 0%;
  left: 0px;
  bottom: 0px;
  right: 0px;
  border-radius: 5px;
  transition: all 500ms ease; }

.hizmet-kutu .kutu-duzen:hover:before {
  height: 100%;
  top: 0px; }

.hizmet-kutu .kutu-duzen h3 {
  margin-bottom: 30px; }

.hizmet-kutu .kutu-duzen .icon-box {
  position: relative;
  display: inline-block;
  margin-bottom: 33px; }

.hizmet-kutu .kutu-duzen .icon-box i {
  position: relative;
  display: inline-block;
  font-size: 80px;
  background: #fff;
  width: 120px;
  height: 120px;
  line-height: 130px;
  text-align: center;
  border-radius: 50%;
  color: #000000;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
  transition: all 500ms ease; }

.hizmet-kutu .kutu-duzen:hover .icon-box i {
  box-shadow: none; }

.hizmet-kutu .kutu-duzen .icon-box .border-layer {
  position: absolute;
  width: 140px;
  height: 140px;
  left: -10px;
  top: -10px;
  border: 2px solid #fff;
  border-radius: 50%;
  clip-path: polygon(0% 0%, 50% 0%, 50% 100%, 0% 100%, 0 0);
  transition: all 500ms ease;
  -webkit-animation: hizmet-rot 2s infinite linear;
  -moz-animation: hizmet-rot 2s infinite linear;
  -o-animation: hizmet-rot 2s infinite linear;
  animation: hizmet-rot 2s infinite linear;
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -o-animation-play-state: paused;
  animation-play-state: paused; }

.hizmet-kutu .kutu-duzen:hover .icon-box .border-layer {
  -webkit-animation-play-state: running;
  -moz-animation-play-state: running;
  -o-animation-play-state: running;
  animation-play-state: running; }

.hizmet-kutu .kutu-duzen:hover .border-layer {
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, 0 0); }

.hizmet-kutu .kutu-duzen .icon-box .border-layer:before {
  position: absolute;
  content: '';
  background: #fff;
  width: 12px;
  height: 12px;
  left: 15px;
  bottom: 9px;
  border-radius: 50%; }

.hizmet-kutu .kutu-duzen p {
  color: #666;
  font-size: 1.4rem;
  text-align: center; }

h3 a {
  color: #000000; }

h3 a:hover {
  color: white; }

.hizmet-kutu.kutu-duzen:hover h3,
.hizmet-kutu .kutu-duzen:hover h3 a,
.hizmet-kutu .kutu-duzen:hover p {
  color: #fff; }

.analiz-stil {
  position: relative;
  padding: 125px 0px 130px 0px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

.analiz-stil .sec-title {
  margin-bottom: 60px; }

.analiz-alani .inner-box {
  position: relative;
  display: block;
  text-align: center; }

.analiz-alani .inner-box .ok-kutu {
  position: absolute;
  top: 32px;
  right: -104px;
  width: 194px;
  height: 56px;
  background-repeat: no-repeat; }

.analiz-alani .inner-box .icon-box {
  position: relative;
  display: inline-block;
  font-size: 60px;
  width: 100px;
  height: 100px;
  text-align: center;
  border-radius: 50%;
  margin-bottom: 20px;
  background: #000000; }

.analiz-alani .inner-box .icon-box i {
  position: relative;
  color: transparent !important;
  background: -webkit-linear-gradient(0deg, #fff, #fff 100%);
  /* -webkit-background-clip: text !important; */
  -webkit-text-fill-color: transparent !important;
  transition: all 500ms ease; }

.analiz-alani .inner-box .icon-box:before {
  position: absolute;
  content: '';
  background: #000000;
  width: 100px;
  height: 100px;
  left: 0;
  top: 0;
  opacity: 0;
  transition: all 500ms ease;
  border-radius: 50%; }

.analiz-alani .inner-box:hover .icon-box:before {
  opacity: 1; }

.analiz-alani .inner-box:hover .icon-box:after {
  opacity: 1; }

.analiz-alani .inner-box .icon-box .border-line {
  position: absolute;
  left: 50%;
  transform: translateY(-50%);
  bottom: -58px;
  width: 4px;
  height: 32px;
  background-repeat: no-repeat; }

.analiz-alani .inner-box .count-box {
  position: relative;
  width: 60px;
  height: 60px;
  padding: 10px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 50%;
  margin-bottom: 65px; }

.analiz-alani .inner-box .count-box span {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 15px;
  font-weight: 700;
  color: #fff;
  border-radius: 50%; }

.analiz-alani .inner-box .content-box {
  position: relative;
  display: block;
  background: #fff;
  padding: 47px 30px 43px 30px;
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1); }

.analiz-alani .inner-box .content-box h3 {
  margin-bottom: 12px;
  color: #000000; }

.analiz-stil .inner-content {
  position: relative; }

.analiz-stil .inner-content .line {
  position: absolute;
  left: 0px;
  top: 157px;
  width: 100%;
  height: 10px;
  background-repeat: no-repeat;
  background-position: center; }

.analiz-stil .more-btn {
  position: relative;
  display: block;
  text-align: center;
  margin-top: 60px; }

.analiz-alani .content-box p {
  color: #666;
  font-size: 1.4rem;
  text-align: center; }

.analiz-stil .more-btn .theme-btn {
  padding: 11px 36px; }

.services-special .inner-box {
  position: relative;
  display: block;
  text-align: center;
  background: #fff;
  border-radius: 10px;
  padding: 70px 50px 50px 50px;
  transition: all 500ms ease; }

.services-special:hover .inner-box {
  box-shadow: 0 10px 30px 3px rgba(99, 119, 238, 0.4); }

.services-special .icon-box {
  position: relative;
  display: inline-block;
  font-size: 60px;
  width: 130px;
  height: 130px;
  line-height: 130px;
  text-align: center;
  border-radius: 50%;
  margin-bottom: 43px;
  color: #ffffff;
  z-index: 1;
  transition: all 500ms ease; }

.services-special:hover .icon-box {
  color: #fff; }

.services-special .icon-box:after {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  left: 0px;
  bottom: 0px;
  border-radius: 50%;
  z-index: -1;
  transition: all 500ms ease; }

.services-special:hover .icon-box:after {
  top: 0px; }

.services-special .icon-box:before {
  position: absolute;
  content: '';
  background: #000000;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 2px;
  z-index: -1;
  border-radius: 50%;
  box-shadow: -10px 10px 20px 0px rgba(0, 0, 0, 0.2); }

.services-special .inner-box p {
  margin-bottom: 15px;
  color: #666;
  font-size: 1.4rem;
  text-align: center; }

.services-special .inner-box .btn-box .theme-btn.style-two {
  padding: 10.5px 35px; }

.services-special .icon-box .anim-icon {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  right: 0px;
  -webkit-animation: service_hexagon 3s infinite linear;
  -moz-animation: service_hexagon 3s infinite linear;
  -o-animation: service_hexagon 3s infinite linear;
  animation: service_hexagon 3s infinite linear;
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -o-animation-play-state: paused;
  animation-play-state: paused; }

.services-special:hover .icon-box .anim-icon {
  -webkit-animation-play-state: running;
  -moz-animation-play-state: running;
  -o-animation-play-state: running;
  animation-play-state: running; }

.services-special .icon-box .anim-icon .icon-1 {
  position: absolute;
  left: -11px;
  top: 60px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #4c80ba;
  -webkit-animation: zoom-fade-two 5s infinite linear;
  animation: zoom-fade-two 5s infinite linear; }

.services-special .icon-box .anim-icon .icon-2 {
  position: absolute;
  left: 25px;
  top: -5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #4c80ba;
  -webkit-animation: zoom-fade-two 4s infinite linear;
  animation: zoom-fade-two 4s infinite linear; }

.services-special .icon-box .anim-icon .icon-3 {
  position: absolute;
  top: 11px;
  right: 0px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #4c80ba;
  -webkit-animation: zoom-fade-two 3s infinite linear;
  animation: zoom-fade-two 3s infinite linear; }

@-webkit-keyframes zoom-fade {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9); }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9); } }
@keyframes zoom-fade {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9); }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9); } }
@-webkit-keyframes zoom-fade-two {
  0% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5); }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5); } }
@keyframes zoom-fade-two {
  0% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5); }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5); } }
.banner .parallax-container div img,
.fullwidth-video-section .play-link .icon-box {
  animation-name: zoom-fade;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-name: zoom-fade;
  -webkit-animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: zoom-fade;
  -moz-animation-duration: 5s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: zoom-fade;
  -ms-animation-duration: 5s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-animation-name: zoom-fade;
  -o-animation-duration: 5s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear; }

@keyframes rotateme {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }
@-webkit-keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg); } }
@-moz-keyframes rotateme {
  from {
    -moz-transform: rotate(0deg); }
  to {
    -moz-transform: rotate(360deg); } }
@-o-keyframes rotateme {
  from {
    -o-transform: rotate(0deg); }
  to {
    -o-transform: rotate(360deg); } }
.page-banner .icon-one,
.main-banner .icon-one {
  animation-name: rotateme;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-name: rotateme;
  -webkit-animation-duration: 7s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: rotateme;
  -moz-animation-duration: 7s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: rotateme;
  -ms-animation-duration: 7s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-animation-name: rotateme;
  -o-animation-duration: 7s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear; }

.how-it-works:before,
.main-banner .icon-six,
.main-banner .icon-two,
.page-banner .icon-six,
.page-banner .icon-two,
.rotate-me {
  animation-name: rotateme;
  animation-duration: 24s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-name: rotateme;
  -webkit-animation-duration: 24s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: rotateme;
  -moz-animation-duration: 24s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: rotateme;
  -ms-animation-duration: 24s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-animation-name: rotateme;
  -o-animation-duration: 24s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear; }

@-webkit-keyframes footer-animate {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px); }
  50% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px); }
  100% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px); } }
@keyframes footer-animate {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px); }
  50% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px); }
  100% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px); } }
/** service-hexagon **/
@-moz-keyframes service_hexagon {
  0% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg); } }
@keyframes service_hexagon {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }
.sidebar-tag {
  display: inline-flex;
  text-align: left;
  border-radius: 7px;
  width: 100%;
  background: #fff;
  box-shadow: 3px 3px 13px 0 rgba(0, 0, 0, 0.055);
  align-items: center;
  align-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 20px; }

span.menu-tag {
  color: #616161;
  display: block;
  font-size: 15px !important;
  padding: 5px 11px;
  text-align: center;
  text-transform: capitalize;
  background: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  margin: 0 3px 9px 0; }

a.menutag {
  color: #666666; }

a.menutag:hover {
  color: #000000;
  transition: all 2ms; }

i.flaticon-plus {
  right: 16px;
  top: 12px;
  font-size: 17px;
  color: white;
  position: absolute;
  font-weight: 800;
  right: 16px;
  top: 18px;
  font-size: 17px; }

.callbackform {
  border-radius: 7px;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  background: #fff;
  box-shadow: 3px 3px 13px 0 rgba(0, 0, 0, 0.075); }

/* PAGINATION */
.pagination {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0; }
  .pagination .page-item {
    display: inline-block; }
    .pagination .page-item .page-link {
      height: 60px;
      line-height: 60px;
      padding: 0 40px;
      border-radius: 0 !important;
      font-weight: 500;
      color: #000000;
      outline: none !important; }
      .pagination .page-item .page-link:focus {
        outline: none !important; }

.blogcount {
  border-top: 0.1rem solid #fff;
  padding-top: 1rem;
  display: inline-block;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }

p.countb {
  font-size: 28px;
  margin-left: 1rem;
  background: #000000;
  padding: 0 23px;
  color: #ffffff;
  cursor: pointer;
  border-radius: 0.3em; }

/* FOOTER */
.footer {
  width: 100%;
  flex-wrap: wrap;
  padding: 33px 0;
  /* background: #000000; */
  position: relative;
  color: #fff;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  /* background-image: url(../img/BG.jpgx); */
  filter: saturate(0.5);
  background-size: cover; }
  .footer .logo {
    width: 100%;
    display: block;
    margin-bottom: 30px; }
    .footer .logo img {
      width: auto;
      height: 74px;
      margin-top: 17px; }
  .footer .footer-info {
    width: 100%;
    display: block;
    margin-bottom: 20px;
     }
    .footer .footer-info a {
      color: #fff;
      text-decoration: underline;
      color: #000000;
      font-size: 14px; }
  .footer .copyright {
    width: 100%;
    display: block;
    margin: 0;
    text-align: center;
    font-size: 18px; 
  }

  .footer .footer-social {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: -1px;
    padding: 0;
    font-size: 2rem;
    justify-content: center;
    align-items: center;
    margin-left: -4rem; }
    .footer .footer-social li {
      display: inline-block;
      margin-right: 10px;
      border-radius: 50%;
      padding: 0;
      list-style: none; 
    }
      .footer .footer-social li a {
        width: 55px;
        height: 55px;
        line-height: 40px;
        display: inline-block;
        border-radius: 50%;
        border: 1px solid rgba(255, 255, 255, 0.3);
        color: #fff;
        text-align: center;
        font-size: 23px; }
        .footer .footer-social li a i{
          padding-top: 29%;
        }
        .footer .footer-social li:nth-child(1):hover{
          background: #0041ff;}
        .footer .footer-social li:nth-child(2):hover {
          background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #0041ff 90%); }
  .footer .widget-title {
    width: 100%;
    display: block;
    font-weight: 500;
    font-size: 26px;
    margin-top: 10px;
    margin-bottom: 15px; }
  .footer .footer-menu {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0; }
    .footer .footer-menu li {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: 0;
      padding: 5px 0;
      list-style: none; }
        /* .footer .footer-menu li:before {
          content: "";
          width: 11px;
          height: 4px;
          display: inline-block;
          background: #ffffff;
          margin-right: 9px; } */
      .footer .footer-menu li a {
        color: #fff; }

@media (min-width: 667px) and (max-width: 667px) {
  .page-header .container h2 {
    width: 100%;
    display: block;
    font-size: 40px;
    margin-bottom: 0;
    line-height: 5rem;
    font-weight: 700;
    margin-top: -3rem; }

  .page-header .container p {
    width: 100%;
    display: block;
    margin-bottom: 50px;
    font-size: 16px; } }
@media (min-width: 896px) and (max-width: 896px) {
  .page-header .container h2 {
    width: 100%;
    display: block;
    font-size: 50px;
    margin-bottom: 0;
    line-height: 5rem;
    font-weight: 700;
    margin-top: -5rem; }

  .page-header .container p {
    width: 100%;
    display: block;
    margin-bottom: 92px;
    font-size: 20px;
    width: 100%;
    display: flex;
    justify-content: left;
    flex-wrap: wrap; } }
@media (min-width: 844px) and (max-width: 844px) {
  .page-header .container h2 {
    width: 100%;
    display: block;
    font-size: 50px;
    margin-bottom: 0;
    line-height: 5rem;
    font-weight: 700;
    margin-top: -5rem; }

  .page-header .container p {
    width: 100%;
    display: block;
    margin-bottom: 92px;
    font-size: 20px;
    width: 100%;
    display: flex;
    justify-content: left;
    flex-wrap: wrap; } }
@media (min-width: 820px) and (max-width: 820px) {
  .page-header .container h2 {
    width: 100%;
    display: block;
    font-size: 50px;
    margin-bottom: 0;
    line-height: 5rem;
    font-weight: 700;
    margin-top: -5rem; }

  .page-header .container p {
    width: 100%;
    display: block;
    margin-bottom: 92px;
    font-size: 20px;
    width: 100%;
    display: flex;
    justify-content: left;
    flex-wrap: wrap; } }
@media (min-width: 768px) and (max-width: 768px) {
  .page-header .container h2 {
    width: 100%;
    display: block;
    font-size: 40px;
    margin-bottom: 0;
    line-height: 5rem;
    font-weight: 700;
    margin-top: -5rem; }

  .page-header .container p {
    width: 100%;
    display: block;
    margin-bottom: 92px;
    font-size: 20px;
    width: 100%;
    display: flex;
    justify-content: left;
    flex-wrap: wrap; } }
/* RESPONSIVE MEDIUM  */
@media only screen and (max-width: 1199px), only screen and (max-device-width: 1199px) {
  .page-header {
    width: 100%;
    height: 926px;
    max-height: 201vh;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    background-size: cover !important;
    padding-top: 1px; }

  h3.baslik-2.h-yazi-margin-kucuk {
    font-size: 4rem;
    text-align: center; }

  .absoluteimg {
    display: none; }

  .icon-project-detail {
    width: 100%;
    height: 98px;
    line-height: 75px;
    border-radius: 5px;
    color: #000000;
    font-size: 50px;
    -webkit-transition: .5s;
    transition: .5s;
    margin-right: auto;
    margin-bottom: 15px;
    font-size: 12rem;
    padding: 10px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center; }

  .iconleft2 {
    width: 90px;
    border-radius: 5px;
    color: #000000;
    transition: .5s;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 6px;
    font-size: 12rem;
    margin-top: -2rem;
    text-align: center; }

  h3 {
    font-size: 4rem; }

  .services-special .inner-box p {
    margin-bottom: 15px;
    color: #666;
    font-size: 2.8rem;
    text-align: center; }

  .paragraf-info:not(:last-child) {
    margin-bottom: 1.4rem;
    text-align: center; }

  .analiz-alani .inner-box .content-box h3 {
    margin-bottom: 12px;
    color: #000000;
    font-size: 3.5rem; }

  .analiz-alani .content-box p {
    color: #666;
    font-size: 2.8rem;
    text-align: center; }

  .analiz-alani .inner-box .ok-kutu {
    display: none; }

  .hizmet-kutu .kutu-duzen p {
    color: #666;
    font-size: 2.8rem;
    text-align: center; }

  .hizmet-kutu .kutu-duzen h3 {
    margin-bottom: 30px;
    font-size: 4rem; }

  .hero3 {
    position: relative;
    z-index: 5;
    display: none; }

  .absoluteimg {
    position: absolute;
    left: 72% !important;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 56%;
    height: auto;
    margin-top: -123px;
    display: none; }

  .footer .copyright {
    margin-top: 40px;
    text-align: center; }

  span.menu-service {
    font-size: 20px;
    padding: 14px 15px 14px 18px;
    font-weight: 600;
    font-size: 18px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    /* color: #232323; */
    position: relative;
    margin-bottom: 2px;
    border-radius: 0.3rem;
    cursor: pointer; }

  .side-widget .site-menu ul li ul {
    left: -19px;
    margin-bottom: 10px;
    display: block;
    margin-top: -22px;
    width: 100%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0); }

  .footer .footer-menu li {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;
    padding: 5px 0;
    list-style: none;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: space-around;
    text-align: center; }

  p.paragraf-slider.wow.fade.animated {
    font-size: 3rem; }

  p.footerp {
    font-size: 16px;
    text-align: center; }

  .iconleft {
    width: 90px;
    height: 93px;
    border-radius: 5px;
    color: #000000;
    font-size: 50px;
    -webkit-transition: .5s;
    transition: .5s;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
    font-size: 18rem;
    text-align: center; }

  .col-lg-4:nth-child(1) .image-box {
    padding-right: 0; }

  .col-lg-4:nth-child(3) .image-box {
    padding-left: 0; }

  .side-content h2 {
    font-size: 60px; }

  .side-content h2 br {
    display: none; }

  .counter-box {
    padding: 30px; }

  .side-content figure img {
    height: 70px; }

  .carousel-classes h6 {
    font-size: 38px; }

  .class-box h6 {
    font-size: 38px; }

  .sidebar {
    padding-left: 0; }

  .sidebar .widget {
    padding: 30px;
    margin-bottom: 30px; }

  .blog-box .content .full-width {
    width: 100%;
    margin-left: 0;
    margin-right: 0; }

  .recent-news .content {
    padding: 30px; }

  .footer .footer-menu li a {
    font-size: 17px; }

  .side-widget .site-menu ul li {
    opacity: 1 !important;
    width: 100%;
    display: block;
    padding: 5px; }

  .blogcount {
    margin-top: 15rem; }

  .footer .footer-info {
    width: 100%;
    display: block;
    margin-bottom: 20px;
    
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;
    align-content: space-around;
    font-size: 18px; } }
/* RESPONSIVE TABLET  */
@media only screen and (max-width: 991px), only screen and (max-device-width: 1180px) {
  .page-header {
    width: 100%;
    max-height: 70vh;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    background-size: cover !important;
    padding-top: 167px; }

  h3.baslik-2.h-yazi-margin-kucuk {
    font-size: 4rem;
    text-align: center; }

  .absoluteimg {
    display: none; }

  .icon-project-detail {
    width: 100%;
    height: 98px;
    line-height: 75px;
    border-radius: 5px;
    color: #000000;
    font-size: 50px;
    -webkit-transition: .5s;
    transition: .5s;
    margin-right: auto;
    margin-bottom: 15px;
    font-size: 12rem;
    padding: 10px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center; }

  .iconleft2 {
    width: 90px;
    border-radius: 5px;
    color: #000000;
    transition: .5s;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 6px;
    font-size: 12rem;
    margin-top: -2rem;
    text-align: center; }

  h3 {
    font-size: 4rem; }

  .services-special .inner-box p {
    margin-bottom: 15px;
    color: #666;
    font-size: 2.8rem;
    text-align: center; }

  .analiz-alani .inner-box .content-box h3 {
    margin-bottom: 12px;
    color: #000000;
    font-size: 3.5rem; }

  .analiz-alani .content-box p {
    color: #666;
    font-size: 2.8rem;
    text-align: center; }

  .analiz-alani .inner-box .ok-kutu {
    display: none; }

  .paragraf-info:not(:last-child) {
    margin-bottom: 1.4rem;
    text-align: center; }

  .hizmet-kutu .kutu-duzen p {
    color: #666;
    font-size: 2.8rem;
    text-align: center; }

  .hizmet-kutu .kutu-duzen h3 {
    margin-bottom: 30px;
    font-size: 4rem; }

  .hero3 {
    position: relative;
    z-index: 5;
    display: none; }

  .side-widget .site-menu ul li ul {
    left: -19px;
    margin-bottom: 10px;
    display: block;
    margin-top: -22px;
    width: 100%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0); }

  .absoluteimg {
    position: absolute;
    left: 72% !important;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 56%;
    height: auto;
    margin-top: -123px;
    display: none; }

  .iconleft {
    width: 90px;
    height: 93px;
    border-radius: 5px;
    color: #000000;
    font-size: 50px;
    -webkit-transition: .5s;
    transition: .5s;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
    font-size: 18rem;
    text-align: center; }

  p.footerp {
    font-size: 16px;
    text-align: center; }

  p.paragraf-slider.wow.fade.animated {
    font-size: 3rem; }

  span.menu-service {
    font-size: 20px;
    padding: 14px 15px 14px 18px;
    font-weight: 600;
    font-size: 18px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    /* color: #232323; */
    position: relative;
    margin-bottom: 2px;
    border-radius: 0.3rem;
    cursor: pointer; }

  .footer .copyright {
    margin-top: 40px;
    text-align: center; }

  .footer .footer-menu li {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;
    padding: 5px 0;
    list-style: none;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: space-around;
    text-align: center; }

  .footer .footer-info {
    width: 100%;
    display: block;
    margin-bottom: 20px;
    
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;
    align-content: space-around;
    font-size: 18px; }

  .blogcount {
    margin-top: 15rem; }

  .side-widget .hide-mobile {
    display: none; }

  .custom-button {
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }

  .side-widget .show-mobile {
    display: flex; }

  .side-widget .site-menu ul li {
    opacity: 1 !important;
    width: 100%;
    display: block;
    padding: 5px; }

  .side-widget .site-menu ul li a {
    font-size: 18px; }

  .navbar .site-menu {
    display: none; }

  .slider .button-prev {
    display: none; }

  .slider .button-next {
    display: none; }

  .slider .main-slider .swiper-slide .container h1 {
    font-size: 50px; }

  .slider .main-slider .swiper-slide .container h1 br {
    display: none; }

  .col-lg-4:nth-child(2) .image-box {
    margin-top: 0;
    margin-bottom: 50px; }

  .no-spacing .side-content {
    padding: 100px 0 !important; }

  .side-image.full-right {
    width: 100%; }

  .side-image.full-left {
    width: 100%; }

  .side-image .side-timetable {
    width: 100%;
    position: static; }

  .col-lg-4:nth-child(3) .counter-box {
    margin-top: 50px; }

  .carousel-classes h6 {
    font-size: 30px; }

  .content-section.bottom-dark-spacing:after {
    display: none; }

  .col-lg-6:nth-child(1) .pass-box {
    border-right: 0;
    margin-bottom: 50px; }

  .col-lg-4:nth-child(3) .recent-news {
    margin-top: 30px; }

  .side-gallery {
    width: calc(100% + 10px);
    margin-left: -5px;
    margin-right: -5px; }

  .side-gallery figure {
    margin: 5px !important; }

  .col-lg-3:nth-child(1) .branch-box {
    margin-bottom: 50px; }

  .col-lg-3:nth-child(2) .branch-box {
    margin-bottom: 50px; }

  .col-lg-4:nth-child(4) .member-box {
    margin-top: 30px; }

  .tab-wrapper .tab-nav {
    width: 100%;
    display: flex;
    padding: 0;
    justify-content: space-between; }

  .tab-wrapper .tab-nav li {
    width: auto;
    flex: 1;
    display: inline-block; }

  .tab-wrapper .tab-nav li.active a {
    width: 100%;
    margin-right: 0; }

  .tab-wrapper .tab-item {
    width: 100%; }

  .tab-wrapper .tab-item .tab-inner ul {
    height: 50vw; }

  .section-title h2 {
    font-size: 54px; }

  .section-title h2 br {
    display: none; }

  .side-content.left {
    padding-right: 0; }

  .all-classes {
    margin-bottom: 0; }

  .all-classes li {
    width: 50%; }

  .all-classes li:nth-child(3n+2) {
    transform: none; }

  .all-classes li:nth-child(3) {
    margin-top: 50px; }

  .sidebar {
    margin-top: 50px; }

  .sidebar .widget .side-gallery li {
    width: 33.33333%; }

  .contact-box {
    margin-bottom: 50px; }

  .footer .copyright {
    margin-top: 40px; }

  .footer .widget-title {
    margin-top: 50px; } }
/* RESPONSIVE MOBILE */
@media only screen and (max-width: 767px), only screen and (max-device-width: 767px) {
  .side-widget {
    max-width: 80vw; }

  .topbar div b {
    display: none; }

  .topbar div {
    font-size: 14px; }

  .navbar .navbar-button {
    display: none; }

  .slider .main-slider .swiper-slide {
    padding: 0;
    padding-top: 100px; }

  .blogcount {
    margin-top: 15rem; }

  .icon-project-detail {
    width: 100%;
    height: 98px;
    line-height: 75px;
    border-radius: 5px;
    color: #000000;
    font-size: 50px;
    -webkit-transition: .5s;
    transition: .5s;
    margin-right: auto;
    margin-bottom: 15px;
    font-size: 12rem;
    padding: 10px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center; }

  h3.baslik-2.h-yazi-margin-kucuk {
    font-size: 4rem;
    text-align: center; }

  .iconleft2 {
    width: 90px;
    border-radius: 5px;
    color: #000000;
    transition: .5s;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 6px;
    font-size: 12rem;
    margin-top: -2rem;
    text-align: center; }

  .absoluteimg {
    display: none; }

  h3 {
    font-size: 4rem; }

  .services-special .inner-box p {
    margin-bottom: 15px;
    color: #666;
    font-size: 2.8rem;
    text-align: center; }

  .analiz-alani .inner-box .content-box h3 {
    margin-bottom: 12px;
    color: #000000;
    font-size: 3.5rem; }

  .analiz-alani .content-box p {
    color: #666;
    font-size: 2.8rem;
    text-align: center; }

  .paragraf-info:not(:last-child) {
    margin-bottom: 1.4rem;
    text-align: center; }

  .analiz-alani .inner-box .ok-kutu {
    display: none; }

  .hizmet-kutu .kutu-duzen h3 {
    margin-bottom: 30px;
    font-size: 4rem; }

  .hero3 {
    position: relative;
    z-index: 5;
    display: none; }

  .side-widget .site-menu ul li ul {
    left: -19px;
    margin-bottom: 10px;
    display: block;
    margin-top: -22px;
    width: 100%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0); }

  .iconleft {
    width: 90px;
    height: 93px;
    border-radius: 5px;
    color: #000000;
    font-size: 50px;
    -webkit-transition: .5s;
    transition: .5s;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
    font-size: 18rem;
    text-align: center; }

  p.footerp {
    font-size: 16px;
    text-align: center; }

  .footer .copyright {
    margin-top: 40px;
    text-align: center; }

  .footer .footer-menu li {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;
    padding: 5px 0;
    list-style: none;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: space-around;
    text-align: center; }

  .footer .footer-info {
    width: 100%;
    display: block;
    margin-bottom: 20px;
    
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;
    align-content: space-around;
    font-size: 18px; }

  .page-header {
    width: 100%;
    height: 578px;
    max-height: 83vh;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    background-size: cover !important;
    padding-top: 1px; }

  .custom-button {
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }

  h3.h3-baslik-anasayfa-beyaz.owl-fadeUp-in {
    width: 100%;
    display: block;
    font-size: 25px;
    margin-bottom: 0;
    line-height: 10rem;
    font-weight: 700; }

  .footer .footer-social {
    width: 100%;
    padding: 0;
    text-align: center;
    align-items: center;
    clear: both;
    margin: auto;
    font-size: 5rem;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }

  .iconw {
    width: 105px;
    height: 98px;
    line-height: 75px;
    border-radius: 5px;
    color: #ffffff;
    font-size: 50px;
    -webkit-transition: .5s;
    transition: .5s;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
    font-size: 18rem;
    background: #000000;
    padding: 10px;
    align-items: center;
    clear: both;
    margin: auto;
    padding: 0;
    text-align: center;
    align-items: center;
    clear: both;
    margin: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }

  .icon {
    width: 105px;
    height: 98px;
    line-height: 75px;
    border-radius: 5px;
    color: #ffffff;
    font-size: 50px;
    -webkit-transition: .5s;
    transition: .5s;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
    font-size: 18rem;
    background: #000000;
    padding: 10px;
    align-items: center;
    clear: both;
    margin: auto;
    padding: 0;
    text-align: center;
    align-items: center;
    clear: both;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }

  span.menu-service {
    font-size: 20px;
    padding: 14px 15px 14px 18px;
    font-weight: 600;
    font-size: 18px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    /* color: #232323; */
    position: relative;
    margin-bottom: 2px;
    border-radius: 0.3rem;
    cursor: pointer; }

  .page-header .container p {
    width: 100%;
    display: block;
    margin-bottom: 50px;
    font-size: 16px; }

  p.paragraf-slider.wow.fade.animated {
    font-size: 3rem; }

  .hizmet-kutu .kutu-duzen p {
    color: #666;
    font-size: 2.8rem;
    text-align: center; }

  .absoluteimg {
    position: absolute;
    left: 72% !important;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 56%;
    height: auto;
    margin-top: -123px;
    display: none; }

  .slider .main-slider .swiper-slide .container h1 {
    font-size: 38px; }

  .section-title h2 {
    font-size: 42px; }

  .page-header .container {
    padding-top: 140px; }

  .page-header .container h2 {
    font-size: 43px;
    line-height: 5rem; }

  .col-lg-4:nth-child(1) .image-box {
    margin-bottom: 50px; }

  .side-content h2 {
    font-size: 42px; }

  .side-image .side-timetable {
    padding: 30px; }

  .col-lg-4:nth-child(2) .counter-box {
    margin-top: 30px; }

  .side-member figcaption h5 {
    font-size: 40px; }

  .pass-box h6 {
    font-size: 38px; }

  .pass-box p {
    padding: 0; }

  .video a {
    transform: scale(0.7); }

  .video a:hover {
    transform: scale(0.8); }

  .pagination .page-item .page-link {
    padding: 0 30px; }

  .google-maps iframe {
    display: flex; }

  .google-maps .timetable {
    width: 100%;
    position: static;
    padding: 30px;
    margin-bottom: -100px; }

  .blog-box .content h3 {
    font-size: 44px; }

  .all-classes li:nth-child(2) {
    margin-top: 50px; }

  .all-classes li {
    width: 100%; }

  .class-box h6 {
    padding: 0;
    font-size: 32px; }

  .tab-wrapper .tab-nav {
    max-width: 100%;
    overflow-x: auto; }

  .tab-wrapper .tab-item .tab-inner {
    flex-wrap: wrap; }

  .tab-wrapper .tab-item .tab-inner ul {
    width: 100%;
    height: 300px;
    margin: 30px 0;
    padding: 30px; }

  .tab-wrapper .tab-item .tab-inner figure {
    width: 100%; }

  .cta-box {
    padding: 30px; }

  .cta-box h2 {
    font-size: 44px; }

  .cta-box .custom-button {
    padding: 0;
    text-align: center;
    width: 100%; }

  .testimonial figure img {
    height: auto; }

  .col-lg-4:nth-child(3) .member-box {
    margin-top: 30px; }

  .col-lg-4:nth-child(2) .recent-news {
    margin-top: 30px; }

  .side-gallery {
    margin-top: 100px; }

  .side-gallery figure {
    width: calc(50% - 10px); }

  .col-lg-3:nth-child(3) .branch-box {
    margin-bottom: 50px; }

  .side-widget .site-menu ul li {
    opacity: 1 !important;
    width: 100%;
    display: block;
    padding: 5px; }

  .page-header .container h2 {
    width: 100%;
    display: block;
    font-size: 50px;
    margin-bottom: 0;
    line-height: 12rem;
    font-weight: 700; } }
@media (min-width: 767px) and (max-width: 850px) {
  .footer .footer-social {
    width: 100%;
    padding: 0;
    text-align: center;
    align-items: center;
    clear: both;
    margin: auto;
    font-size: 5rem;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }

  .icon-project-detail {
    width: 100%;
    height: 98px;
    line-height: 75px;
    border-radius: 5px;
    color: #000000;
    font-size: 50px;
    -webkit-transition: .5s;
    transition: .5s;
    margin-right: auto;
    margin-bottom: 15px;
    font-size: 12rem;
    padding: 10px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center; }

  h3.baslik-2.h-yazi-margin-kucuk {
    font-size: 4rem;
    text-align: center; }

  .iconleft2 {
    width: 90px;
    border-radius: 5px;
    color: #000000;
    transition: .5s;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 6px;
    font-size: 12rem;
    margin-top: -2rem;
    text-align: center; }

  .absoluteimg {
    display: none; }

  h3 {
    font-size: 4rem; }

  .services-special .inner-box p {
    margin-bottom: 15px;
    color: #666;
    font-size: 2.8rem;
    text-align: center; }

  .analiz-alani .inner-box .content-box h3 {
    margin-bottom: 12px;
    color: #000000;
    font-size: 3.5rem; }

  .analiz-alani .content-box p {
    color: #666;
    font-size: 2.8rem;
    text-align: center; }

  .analiz-alani .inner-box .ok-kutu {
    display: none; }

  .paragraf-info:not(:last-child) {
    margin-bottom: 1.4rem;
    text-align: center; }

  .hizmet-kutu .kutu-duzen p {
    color: #666;
    font-size: 2.8rem;
    text-align: center; }

  .hizmet-kutu .kutu-duzen h3 {
    margin-bottom: 30px;
    font-size: 4rem; }

  .hero3 {
    position: relative;
    z-index: 5;
    display: none; }

  .side-widget .site-menu ul li ul {
    left: -19px;
    margin-bottom: 10px;
    display: block;
    margin-top: -22px;
    width: 100%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0); }

  p.footerp {
    font-size: 16px;
    text-align: center; }

  .iconleft {
    width: 90px;
    height: 93px;
    border-radius: 5px;
    color: #000000;
    font-size: 50px;
    -webkit-transition: .5s;
    transition: .5s;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
    font-size: 18rem;
    text-align: center; }

  .footer .copyright {
    margin-top: 40px;
    text-align: center; }

  .footer .footer-menu li {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;
    padding: 5px 0;
    list-style: none;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: space-around;
    text-align: center; }

  .footer .footer-info {
    width: 100%;
    display: block;
    margin-bottom: 20px;
    
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;
    align-content: space-around;
    font-size: 18px; }

  .iconw {
    width: 90px;
    height: 93px;
    line-height: 80px;
    border-radius: 5px;
    color: #fff;
    font-size: 50px;
    -webkit-transition: .5s;
    transition: .5s;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
    font-size: 18rem;
    text-align: center;
    align-items: center;
    clear: both;
    margin: auto;
    padding: 0;
    text-align: center;
    align-items: center;
    clear: both;
    margin: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }

  .icon {
    width: 166px;
    height: 183px;
    line-height: 75px;
    border-radius: 5px;
    color: #ffffff;
    font-size: 50px;
    -webkit-transition: .5s;
    transition: .5s;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
    font-size: 18rem;
    background: #000000;
    padding: 10px;
    align-items: center;
    clear: both;
    margin: auto;
    padding: 0;
    text-align: center;
    align-items: center;
    clear: both;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }

  span.menu-service {
    font-size: 20px;
    padding: 14px 15px 14px 18px;
    font-weight: 600;
    font-size: 18px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    /* color: #232323; */
    position: relative;
    margin-bottom: 2px;
    border-radius: 0.3rem;
    cursor: pointer; }

  .paragraf-info:not(:last-child) {
    margin-bottom: 1.4rem;
    text-align: center; }

  .baslik-3white {
    margin-top: 10rem; }

  p.paragraf-slider.wow.fade.animated {
    font-size: 3rem; }

  .absoluteimg {
    position: absolute;
    left: 72% !important;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 56%;
    height: auto;
    margin-top: -123px;
    display: none; }

  .side-widget .site-menu ul li {
    opacity: 1 !important;
    width: 100%;
    display: block;
    padding: 5px; } }
@media (min-width: 667px) and (max-width: 667px) {
  .page-header .container h2 {
    width: 100%;
    display: block;
    font-size: 40px;
    margin-bottom: 0;
    line-height: 5rem;
    font-weight: 700;
    margin-top: -3rem; }

  .page-header .container p {
    width: 100%;
    display: block;
    margin-bottom: 50px;
    font-size: 16px; } }
@media (min-width: 896px) and (max-width: 896px) {
  .page-header .container h2 {
    width: 100%;
    display: block;
    font-size: 50px;
    margin-bottom: 0;
    line-height: 5rem;
    font-weight: 700;
    margin-top: -5rem; }

  .page-header .container p {
    width: 100%;
    display: block;
    margin-bottom: 92px;
    font-size: 20px;
    width: 100%;
    display: flex;
    justify-content: left;
    flex-wrap: wrap; } }
@media (min-width: 844px) and (max-width: 844px) {
  .page-header .container h2 {
    width: 100%;
    display: block;
    font-size: 50px;
    margin-bottom: 0;
    line-height: 5rem;
    font-weight: 700;
    margin-top: -5rem; }

  .page-header .container p {
    width: 100%;
    display: block;
    margin-bottom: 92px;
    font-size: 20px;
    width: 100%;
    display: flex;
    justify-content: left;
    flex-wrap: wrap; } }
@media (min-width: 820px) and (max-width: 820px) {
  .page-header .container h2 {
    width: 100%;
    display: block;
    font-size: 50px;
    margin-bottom: 0;
    line-height: 5rem;
    font-weight: 700;
    margin-top: -5rem; }

  .page-header .container p {
    width: 100%;
    display: block;
    margin-bottom: 92px;
    font-size: 20px;
    width: 100%;
    display: flex;
    justify-content: left;
    flex-wrap: wrap; } }
@media (min-width: 768px) and (max-width: 768px) {
  .page-header .container h2 {
    width: 100%;
    display: block;
    font-size: 40px;
    margin-bottom: 0;
    line-height: 5rem;
    font-weight: 700;
    margin-top: -5rem; }

  .page-header .container p {
    width: 100%;
    display: block;
    margin-bottom: 92px;
    font-size: 20px;
    width: 100%;
    display: flex;
    justify-content: left;
    flex-wrap: wrap; } }
@media (min-width: 1180px) and (max-width: 1200px) {
  .page-header .container h2 {
    width: 100%;
    display: block;
    font-size: 70px;
    margin-bottom: 0;
    line-height: 6rem;
    font-weight: 700;
    text-align: center; }

  .icon-project-detail {
    width: 100%;
    height: 98px;
    line-height: 75px;
    border-radius: 5px;
    color: #000000;
    font-size: 50px;
    -webkit-transition: .5s;
    transition: .5s;
    margin-right: auto;
    margin-bottom: 15px;
    font-size: 12rem;
    padding: 10px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center; }

  h3.baslik-2.h-yazi-margin-kucuk {
    font-size: 4rem;
    text-align: center; }

  .iconleft2 {
    width: 90px;
    border-radius: 5px;
    color: #000000;
    transition: .5s;
    /* margin-leftpnm : auto; */
    margin-right: auto;
    margin-bottom: 6px;
    font-size: 12rem;
    margin-top: -2rem;
    text-align: center; }

  .analiz-alani .inner-box .content-box h3 {
    margin-bottom: 12px;
    color: #000000;
    font-size: 3.5rem; }

  .absoluteimg {
    display: none; }

  h3 {
    font-size: 4rem; }

  .services-special .inner-box p {
    margin-bottom: 15px;
    color: #666;
    font-size: 2.8rem;
    text-align: center; }

  .analiz-alani .content-box p {
    color: #666;
    font-size: 2.8rem;
    text-align: center; }

  .analiz-alani .inner-box .ok-kutu {
    display: none; }

  .paragraf-info:not(:last-child) {
    margin-bottom: 1.4rem;
    text-align: center; }

  .hizmet-kutu .kutu-duzen h3 {
    margin-bottom: 30px;
    font-size: 4rem; }

  .hero3 {
    position: relative;
    z-index: 5;
    display: none; }

  p.paragraf-slider.wow.fade.animated {
    font-size: 3rem; }

  .hizmet-kutu .kutu-duzen p {
    color: #666;
    font-size: 2.8rem;
    text-align: center; }

  .absoluteimg {
    position: absolute;
    left: 72% !important;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 56%;
    height: auto;
    margin-top: -123px;
    display: none; }

  .side-widget .site-menu ul li ul {
    left: -19px;
    margin-bottom: 10px;
    display: block;
    margin-top: -22px;
    width: 100%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0); } }
@media (min-width: 820px) and (max-width: 820px) {
  .side-widget {
    width: 400px;
    height: 82vh;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: fixed;
    left: -100%;
    top: 0;
    transition-duration: 1s;
    -webkit-transition-duration: 1s;
    transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
    -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
    background: #ffffff;
    z-index: 6;
    box-shadow: 0 0 60px rgba(0, 0, 0, 0.39);
    padding: 20px 30px;
    color: #fff; }

  .icon-project-detail {
    width: 100%;
    height: 98px;
    line-height: 75px;
    border-radius: 5px;
    color: #000000;
    font-size: 50px;
    -webkit-transition: .5s;
    transition: .5s;
    margin-right: auto;
    margin-bottom: 15px;
    font-size: 12rem;
    padding: 10px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center; }

  h3.baslik-2.h-yazi-margin-kucuk {
    font-size: 4rem;
    text-align: center; }

  .analiz-alani .inner-box .ok-kutu {
    display: none; }

  .iconleft2 {
    width: 90px;
    border-radius: 5px;
    color: #000000;
    transition: .5s;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 6px;
    font-size: 12rem;
    margin-top: -2rem;
    text-align: center; }

  .analiz-alani .inner-box .content-box h3 {
    margin-bottom: 12px;
    color: #000000;
    font-size: 3.5rem; }

  .analiz-alani .content-box p {
    color: #666;
    font-size: 2.8rem;
    text-align: center; }

  .paragraf-info:not(:last-child) {
    margin-bottom: 1.4rem;
    text-align: center; }

  .hizmet-kutu .kutu-duzen h3 {
    margin-bottom: 30px;
    font-size: 4rem; }

  .hero3 {
    position: relative;
    z-index: 5;
    display: none; }

  .absoluteimg {
    display: none; }

  span.menu-service {
    font-size: 20px;
    padding: 14px 15px 14px 18px;
    font-weight: 600;
    font-size: 18px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    /* color: #232323; */
    position: relative;
    margin-bottom: 2px;
    border-radius: 0.3rem;
    cursor: pointer; }

  .side-widget .site-menu ul li ul {
    left: -19px;
    margin-bottom: 10px;
    display: block;
    margin-top: -22px;
    width: 100%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0); }

  .absoluteimg {
    position: absolute;
    left: 72% !important;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 56%;
    height: auto;
    margin-top: -123px;
    display: none; }

  .hizmet-kutu .kutu-duzen p {
    color: #666;
    font-size: 2.8rem;
    text-align: center; }

  p.paragraf-slider.wow.fade.animated {
    font-size: 3rem; }

  h3 {
    font-size: 4rem; }

  .services-special .inner-box p {
    margin-bottom: 15px;
    color: #666;
    font-size: 2.8rem;
    text-align: center; } }
.show-mobile .site-menu li a.sb {
  width: 26px;
  height: 15px;
  text-align: center;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  font-weight: 700;
  border: 0 !important; }

.show-mobile .site-menu li a.sb:hover {
  display: none; }

a.sb::after {
  display: none; }

a.sb:checked {
  display: none; }

@media (min-width: 1280px) and (max-width: 1280px) {
  .navbar .hamburger-menu {
    margin-left: auto;
    display: none; }

  body {
    overflow-y: scroll;
    overflow-x: hidden; }

  .navbar .site-menu ul li {
    display: inline-block;
    margin: 0;
    padding: 0 12px; } }
@media (min-width: 1280px) and (max-width: 1280px) {
  

  .navbar .site-menu ul li {
    display: inline-block;
    margin: 0;
    padding: 0 7px; }

  }

.loadwraps {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  pointer-events: none;
  background: #fff;
  transition: all 1s; }
  .loadwraps:before, .loadwraps:after,
  .loadwraps .dot,
  .loadwraps .outline {
    position: absolute;
    top: 50%;
    left: 50%;
    -o-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 50%; }
  .loadwraps .dot {
    width: 96px;
    height: 96px;
    background: #FFFFFF;
    -o-animation: in 4s linear infinite;
    -moz-animation: in 4s linear infinite;
    -ms-animation: in 4s linear infinite;
    -webkit-animation: in 4s linear infinite;
    animation: in 4s linear infinite;
    z-index: 2; }
  .loadwraps:before {
    content: "";
    width: 0px;
    height: 0px;
    background: #000000;
    -o-animation: out1 4s linear infinite;
    -moz-animation: out1 4s linear infinite;
    -ms-animation: out1 4s linear infinite;
    -webkit-animation: out1 4s linear infinite;
    animation: out1 4s linear infinite; }
  .loadwraps:after {
    content: "";
    width: 0px;
    height: 0px;
    background: #FFFFFF;
    -o-animation: out2 4s linear infinite;
    -moz-animation: out2 4s linear infinite;
    -ms-animation: out2 4s linear infinite;
    -webkit-animation: out2 4s linear infinite;
    animation: out2 4s linear infinite; }
  .loadwraps .outline {
    width: 120px;
    height: 120px;
    z-index: 2; }
    .loadwraps .outline span {
      width: 68px;
      height: 68px;
      -o-transform-origin: 100% 100%;
      -moz-transform-origin: 100% 100%;
      -webkit-transform-origin: 100% 100%;
      -ms-transform-origin: 100% 100%;
      transform-origin: 100% 100%;
      -o-transform: rotate(45deg) skewX(80deg);
      -moz-transform: rotate(45deg) skewX(80deg);
      -webkit-transform: rotate(45deg) skewX(80deg);
      -ms-transform: rotate(45deg) skewX(80deg);
      transform: rotate(45deg) skewX(80deg);
      overflow: hidden;
      position: absolute;
      bottom: 50%;
      right: 50%;
      -o-animation: outline 4s linear infinite;
      -moz-animation: outline 4s linear infinite;
      -ms-animation: outline 4s linear infinite;
      -webkit-animation: outline 4s linear infinite;
      animation: outline 4s linear infinite; }
      .loadwraps .outline span:before {
        content: "";
        display: block;
        border: solid 5px #fff;
        width: 200%;
        height: 200%;
        border-radius: 50%;
        -o-transform: skewX(-80deg);
        -moz-transform: skewX(-80deg);
        -webkit-transform: skewX(-80deg);
        -ms-transform: skewX(-80deg);
        transform: skewX(-80deg);
        -o-animation: outlineBefore 4s linear infinite;
        -moz-animation: outlineBefore 4s linear infinite;
        -ms-animation: outlineBefore 4s linear infinite;
        -webkit-animation: outlineBefore 4s linear infinite;
        animation: outlineBefore 4s linear infinite; }

@-o-keyframes outline {
  0% {
    -o-transform: rotate(0deg) skewX(80deg);
    -moz-transform: rotate(0deg) skewX(80deg);
    -webkit-transform: rotate(0deg) skewX(80deg);
    -ms-transform: rotate(0deg) skewX(80deg);
    transform: rotate(0deg) skewX(80deg); }
  25% {
    -o-transform: rotate(500deg) skewX(15deg);
    -moz-transform: rotate(500deg) skewX(15deg);
    -webkit-transform: rotate(500deg) skewX(15deg);
    -ms-transform: rotate(500deg) skewX(15deg);
    transform: rotate(500deg) skewX(15deg); }
  50% {
    -o-transform: rotate(1000deg) skewX(40deg);
    -moz-transform: rotate(1000deg) skewX(40deg);
    -webkit-transform: rotate(1000deg) skewX(40deg);
    -ms-transform: rotate(1000deg) skewX(40deg);
    transform: rotate(1000deg) skewX(40deg); }
  75% {
    -o-transform: rotate(1500deg) skewX(60deg);
    -moz-transform: rotate(1500deg) skewX(60deg);
    -webkit-transform: rotate(1500deg) skewX(60deg);
    -ms-transform: rotate(1500deg) skewX(60deg);
    transform: rotate(1500deg) skewX(60deg); }
  100% {
    -o-transform: rotate(2160deg) skewX(80deg);
    -moz-transform: rotate(2160deg) skewX(80deg);
    -webkit-transform: rotate(2160deg) skewX(80deg);
    -ms-transform: rotate(2160deg) skewX(80deg);
    transform: rotate(2160deg) skewX(80deg); } }
@-moz-keyframes outline {
  0% {
    -o-transform: rotate(0deg) skewX(80deg);
    -moz-transform: rotate(0deg) skewX(80deg);
    -webkit-transform: rotate(0deg) skewX(80deg);
    -ms-transform: rotate(0deg) skewX(80deg);
    transform: rotate(0deg) skewX(80deg); }
  25% {
    -o-transform: rotate(500deg) skewX(15deg);
    -moz-transform: rotate(500deg) skewX(15deg);
    -webkit-transform: rotate(500deg) skewX(15deg);
    -ms-transform: rotate(500deg) skewX(15deg);
    transform: rotate(500deg) skewX(15deg); }
  50% {
    -o-transform: rotate(1000deg) skewX(40deg);
    -moz-transform: rotate(1000deg) skewX(40deg);
    -webkit-transform: rotate(1000deg) skewX(40deg);
    -ms-transform: rotate(1000deg) skewX(40deg);
    transform: rotate(1000deg) skewX(40deg); }
  75% {
    -o-transform: rotate(1500deg) skewX(60deg);
    -moz-transform: rotate(1500deg) skewX(60deg);
    -webkit-transform: rotate(1500deg) skewX(60deg);
    -ms-transform: rotate(1500deg) skewX(60deg);
    transform: rotate(1500deg) skewX(60deg); }
  100% {
    -o-transform: rotate(2160deg) skewX(80deg);
    -moz-transform: rotate(2160deg) skewX(80deg);
    -webkit-transform: rotate(2160deg) skewX(80deg);
    -ms-transform: rotate(2160deg) skewX(80deg);
    transform: rotate(2160deg) skewX(80deg); } }
@-webkit-keyframes outline {
  0% {
    -o-transform: rotate(0deg) skewX(80deg);
    -moz-transform: rotate(0deg) skewX(80deg);
    -webkit-transform: rotate(0deg) skewX(80deg);
    -ms-transform: rotate(0deg) skewX(80deg);
    transform: rotate(0deg) skewX(80deg); }
  25% {
    -o-transform: rotate(500deg) skewX(15deg);
    -moz-transform: rotate(500deg) skewX(15deg);
    -webkit-transform: rotate(500deg) skewX(15deg);
    -ms-transform: rotate(500deg) skewX(15deg);
    transform: rotate(500deg) skewX(15deg); }
  50% {
    -o-transform: rotate(1000deg) skewX(40deg);
    -moz-transform: rotate(1000deg) skewX(40deg);
    -webkit-transform: rotate(1000deg) skewX(40deg);
    -ms-transform: rotate(1000deg) skewX(40deg);
    transform: rotate(1000deg) skewX(40deg); }
  75% {
    -o-transform: rotate(1500deg) skewX(60deg);
    -moz-transform: rotate(1500deg) skewX(60deg);
    -webkit-transform: rotate(1500deg) skewX(60deg);
    -ms-transform: rotate(1500deg) skewX(60deg);
    transform: rotate(1500deg) skewX(60deg); }
  100% {
    -o-transform: rotate(2160deg) skewX(80deg);
    -moz-transform: rotate(2160deg) skewX(80deg);
    -webkit-transform: rotate(2160deg) skewX(80deg);
    -ms-transform: rotate(2160deg) skewX(80deg);
    transform: rotate(2160deg) skewX(80deg); } }
@keyframes outline {
  0% {
    -o-transform: rotate(0deg) skewX(80deg);
    -moz-transform: rotate(0deg) skewX(80deg);
    -webkit-transform: rotate(0deg) skewX(80deg);
    -ms-transform: rotate(0deg) skewX(80deg);
    transform: rotate(0deg) skewX(80deg); }
  25% {
    -o-transform: rotate(500deg) skewX(15deg);
    -moz-transform: rotate(500deg) skewX(15deg);
    -webkit-transform: rotate(500deg) skewX(15deg);
    -ms-transform: rotate(500deg) skewX(15deg);
    transform: rotate(500deg) skewX(15deg); }
  50% {
    -o-transform: rotate(1000deg) skewX(40deg);
    -moz-transform: rotate(1000deg) skewX(40deg);
    -webkit-transform: rotate(1000deg) skewX(40deg);
    -ms-transform: rotate(1000deg) skewX(40deg);
    transform: rotate(1000deg) skewX(40deg); }
  75% {
    -o-transform: rotate(1500deg) skewX(60deg);
    -moz-transform: rotate(1500deg) skewX(60deg);
    -webkit-transform: rotate(1500deg) skewX(60deg);
    -ms-transform: rotate(1500deg) skewX(60deg);
    transform: rotate(1500deg) skewX(60deg); }
  100% {
    -o-transform: rotate(2160deg) skewX(80deg);
    -moz-transform: rotate(2160deg) skewX(80deg);
    -webkit-transform: rotate(2160deg) skewX(80deg);
    -ms-transform: rotate(2160deg) skewX(80deg);
    transform: rotate(2160deg) skewX(80deg); } }
@-o-keyframes outlineBefore {
  0% {
    -o-transform: skewX(-80deg);
    -moz-transform: skewX(-80deg);
    -webkit-transform: skewX(-80deg);
    -ms-transform: skewX(-80deg);
    transform: skewX(-80deg);
    border: solid 5px #ffffff; }
  25% {
    -o-transform: skewX(-15deg);
    -moz-transform: skewX(-15deg);
    -webkit-transform: skewX(-15deg);
    -ms-transform: skewX(-15deg);
    transform: skewX(-15deg);
    border: solid 5px #ffffff; }
  49% {
    border: solid 5px #ffffff; }
  50% {
    -o-transform: skewX(-40deg);
    -moz-transform: skewX(-40deg);
    -webkit-transform: skewX(-40deg);
    -ms-transform: skewX(-40deg);
    transform: skewX(-40deg);
    border: solid 5px #000000; }
  75% {
    -o-transform: skewX(-60deg);
    -moz-transform: skewX(-60deg);
    -webkit-transform: skewX(-60deg);
    -ms-transform: skewX(-60deg);
    transform: skewX(-60deg);
    border: solid 5px #000000; }
  100% {
    -o-transform: skewX(-80deg);
    -moz-transform: skewX(-80deg);
    -webkit-transform: skewX(-80deg);
    -ms-transform: skewX(-80deg);
    transform: skewX(-80deg);
    border: solid 5px #000000; } }
@-moz-keyframes outlineBefore {
  0% {
    -o-transform: skewX(-80deg);
    -moz-transform: skewX(-80deg);
    -webkit-transform: skewX(-80deg);
    -ms-transform: skewX(-80deg);
    transform: skewX(-80deg);
    border: solid 5px #ffffff; }
  25% {
    -o-transform: skewX(-15deg);
    -moz-transform: skewX(-15deg);
    -webkit-transform: skewX(-15deg);
    -ms-transform: skewX(-15deg);
    transform: skewX(-15deg);
    border: solid 5px #ffffff; }
  49% {
    border: solid 5px #ffffff; }
  50% {
    -o-transform: skewX(-40deg);
    -moz-transform: skewX(-40deg);
    -webkit-transform: skewX(-40deg);
    -ms-transform: skewX(-40deg);
    transform: skewX(-40deg);
    border: solid 5px #000000; }
  75% {
    -o-transform: skewX(-60deg);
    -moz-transform: skewX(-60deg);
    -webkit-transform: skewX(-60deg);
    -ms-transform: skewX(-60deg);
    transform: skewX(-60deg);
    border: solid 5px #000000; }
  100% {
    -o-transform: skewX(-80deg);
    -moz-transform: skewX(-80deg);
    -webkit-transform: skewX(-80deg);
    -ms-transform: skewX(-80deg);
    transform: skewX(-80deg);
    border: solid 5px #000000; } }
@-webkit-keyframes outlineBefore {
  0% {
    -o-transform: skewX(-80deg);
    -moz-transform: skewX(-80deg);
    -webkit-transform: skewX(-80deg);
    -ms-transform: skewX(-80deg);
    transform: skewX(-80deg);
    border: solid 5px #ffffff; }
  25% {
    -o-transform: skewX(-15deg);
    -moz-transform: skewX(-15deg);
    -webkit-transform: skewX(-15deg);
    -ms-transform: skewX(-15deg);
    transform: skewX(-15deg);
    border: solid 5px #ffffff; }
  49% {
    border: solid 5px #ffffff; }
  50% {
    -o-transform: skewX(-40deg);
    -moz-transform: skewX(-40deg);
    -webkit-transform: skewX(-40deg);
    -ms-transform: skewX(-40deg);
    transform: skewX(-40deg);
    border: solid 5px #000000; }
  75% {
    -o-transform: skewX(-60deg);
    -moz-transform: skewX(-60deg);
    -webkit-transform: skewX(-60deg);
    -ms-transform: skewX(-60deg);
    transform: skewX(-60deg);
    border: solid 5px #000000; }
  100% {
    -o-transform: skewX(-80deg);
    -moz-transform: skewX(-80deg);
    -webkit-transform: skewX(-80deg);
    -ms-transform: skewX(-80deg);
    transform: skewX(-80deg);
    border: solid 5px #000000; } }
@keyframes outlineBefore {
  0% {
    -o-transform: skewX(-80deg);
    -moz-transform: skewX(-80deg);
    -webkit-transform: skewX(-80deg);
    -ms-transform: skewX(-80deg);
    transform: skewX(-80deg);
    border: solid 5px #ffffff; }
  25% {
    -o-transform: skewX(-15deg);
    -moz-transform: skewX(-15deg);
    -webkit-transform: skewX(-15deg);
    -ms-transform: skewX(-15deg);
    transform: skewX(-15deg);
    border: solid 5px #ffffff; }
  49% {
    border: solid 5px #ffffff; }
  50% {
    -o-transform: skewX(-40deg);
    -moz-transform: skewX(-40deg);
    -webkit-transform: skewX(-40deg);
    -ms-transform: skewX(-40deg);
    transform: skewX(-40deg);
    border: solid 5px #000000; }
  75% {
    -o-transform: skewX(-60deg);
    -moz-transform: skewX(-60deg);
    -webkit-transform: skewX(-60deg);
    -ms-transform: skewX(-60deg);
    transform: skewX(-60deg);
    border: solid 5px #000000; }
  100% {
    -o-transform: skewX(-80deg);
    -moz-transform: skewX(-80deg);
    -webkit-transform: skewX(-80deg);
    -ms-transform: skewX(-80deg);
    transform: skewX(-80deg);
    border: solid 5px #000000; } }
@-o-keyframes in {
  0% {
    width: 144px;
    height: 144px;
    background: #FFFFFF; }
  40% {
    width: 0px;
    height: 0px;
    background: #FFFFFF; }
  41% {
    width: 0px;
    height: 0px;
    background: #000000; }
  50% {
    width: 144px;
    height: 144px;
    background: #000000; }
  90% {
    width: 0px;
    height: 0px;
    background: #000000; }
  91% {
    width: 0px;
    height: 0px;
    background: #FFFFFF; }
  100% {
    width: 144px;
    height: 144px;
    background: #FFFFFF; } }
@-moz-keyframes in {
  0% {
    width: 144px;
    height: 144px;
    background: #FFFFFF; }
  40% {
    width: 0px;
    height: 0px;
    background: #FFFFFF; }
  41% {
    width: 0px;
    height: 0px;
    background: #000000; }
  50% {
    width: 144px;
    height: 144px;
    background: #000000; }
  90% {
    width: 0px;
    height: 0px;
    background: #000000; }
  91% {
    width: 0px;
    height: 0px;
    background: #FFFFFF; }
  100% {
    width: 144px;
    height: 144px;
    background: #FFFFFF; } }
@-webkit-keyframes in {
  0% {
    width: 144px;
    height: 144px;
    background: #FFFFFF; }
  40% {
    width: 0px;
    height: 0px;
    background: #FFFFFF; }
  41% {
    width: 0px;
    height: 0px;
    background: #000000; }
  50% {
    width: 144px;
    height: 144px;
    background: #000000; }
  90% {
    width: 0px;
    height: 0px;
    background: #000000; }
  91% {
    width: 0px;
    height: 0px;
    background: #FFFFFF; }
  100% {
    width: 144px;
    height: 144px;
    background: #FFFFFF; } }
@keyframes in {
  0% {
    width: 144px;
    height: 144px;
    background: #FFFFFF; }
  40% {
    width: 0px;
    height: 0px;
    background: #FFFFFF; }
  41% {
    width: 0px;
    height: 0px;
    background: #000000; }
  50% {
    width: 144px;
    height: 144px;
    background: #000000; }
  90% {
    width: 0px;
    height: 0px;
    background: #000000; }
  91% {
    width: 0px;
    height: 0px;
    background: #FFFFFF; }
  100% {
    width: 144px;
    height: 144px;
    background: #FFFFFF; } }
@-o-keyframes out1 {
  0% {
    width: 0px;
    height: 0px; }
  30% {
    width: 120vw;
    height: 120vw; }
  100% {
    width: 120vw;
    height: 120vw; } }
@-moz-keyframes out1 {
  0% {
    width: 0px;
    height: 0px; }
  30% {
    width: 120vw;
    height: 120vw; }
  100% {
    width: 120vw;
    height: 120vw; } }
@-webkit-keyframes out1 {
  0% {
    width: 0px;
    height: 0px; }
  30% {
    width: 120vw;
    height: 120vw; }
  100% {
    width: 120vw;
    height: 120vw; } }
@keyframes out1 {
  0% {
    width: 0px;
    height: 0px; }
  30% {
    width: 120vw;
    height: 120vw; }
  100% {
    width: 120vw;
    height: 120vw; } }
@-o-keyframes out2 {
  0% {
    width: 0px;
    height: 0px; }
  30% {
    width: 0px;
    height: 0px; }
  60% {
    width: 120vw;
    height: 120vw; }
  100% {
    width: 120vw;
    height: 120vw; } }
@-moz-keyframes out2 {
  0% {
    width: 0px;
    height: 0px; }
  30% {
    width: 0px;
    height: 0px; }
  60% {
    width: 120vw;
    height: 120vw; }
  100% {
    width: 120vw;
    height: 120vw; } }
@-webkit-keyframes out2 {
  0% {
    width: 0px;
    height: 0px; }
  30% {
    width: 0px;
    height: 0px; }
  60% {
    width: 120vw;
    height: 120vw; }
  100% {
    width: 120vw;
    height: 120vw; } }
@keyframes out2 {
  0% {
    width: 0px;
    height: 0px; }
  30% {
    width: 0px;
    height: 0px; }
  60% {
    width: 120vw;
    height: 120vw; }
  100% {
    width: 120vw;
    height: 120vw; } }
.fade12 {
  opacity: 0 !important;
  visibility: hidden !important;
  pointer-events: none; }

/* Tabs */
.season_tabs {
  position: relative;
  min-height: 340px;
  /* This part sucks */
  clear: both;
  margin: 25px 0; }

.season_tab {
  float: left;
  clear: both;
  width: 286px;
  margin-bottom: 10px; }
  @media only screen and (max-width: 56.25em) {
    .season_tab {
      width: 100%; } }

.season_tab label {
  background: #fbfbfb;
  padding: 10px;
  border: 1px solid #e9e9e9;
  margin-left: -1px;
  font-size: 18px;
  font-weight: 600;
  vertical-align: middle;
  position: relative;
  left: 1px;
  width: 264px;
  height: 68px;
  display: table-cell;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.103) !important; }

.season_tab [type=radio] {
  display: none; }

p.listtext {
  font-size: 17px; }

.season_content {
  position: absolute;
  top: 0;
  left: 286px;
  background: white;
  right: 0;
  bottom: 0;
  padding: 2px;
  padding-left: 20px;
  padding-right: 106px;
  border: 1px solid #eeeeee;
  border-radius: 5px; }
  @media only screen and (max-width: 56.25em) {
    .season_content {
      position: relative;
      left: 0px; } }

.season_content span {
  animation: 0.5s ease-out 0s 1 slideInFromTop; }

[type=radio]:checked ~ label {
  background: #000000;
  color: #fff;
  z-index: 2; }

[type=radio]:checked ~ label ~ .season_content {
  z-index: 1; }

.boslukh {
  margin-top: -23px; }

/* Tabs Finish */

/*# sourceMappingURL=style.css.map */




.slick-dots li button:before {
  font-size: 13px !important;
}
.slick-dots{
  width: 20% !important;
  left: 45%;
}
.slick-arrow{
  display: none !important;
}



.react-tabs__tab {
  display: inline-block;
  border: none !important;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
  margin: 0 0 10px 0 !important;
}
.react-tabs__tab--selected {
  color: black;
  box-shadow: 0px -2px 0px black inset;
  border-radius:  0px !important;
}

@media only screen and (max-width: 768px){
  .slick-dots{
    width: 100% !important;
    left: initial;
  }
  /* .slick-arrow {
    display: none !important;
  } */
}

.react-tabs__tab-list {
  border-bottom: initial !important;
  margin: 0 0 0px !important;
}


.react-tabs__tab--selected {
  background: white;
  border-color: #aaa;
  color: black !important;
  border-radius: 5px 5px 0 0;
}
.react-tabs{
  color: #ffffff ;
}

.toparrow{
  height: 50px;
  width: 50px;
  position: fixed;
  z-index: 100000;
  bottom: 10%;
  right: 5%;
  border: none;
  font-size: 30px;
  background: #ffffff;
  mix-blend-mode: difference;
  border-radius: 50%;
}

.headmarg{
  margin-top: 90px;
}

/* slider */
.awssld{
  height: 100vh !important;
}
/* footer */
.foot-number{
  color: white;
}



.shivalaypho{
  border: 1px solid #00000085;
  border-radius: 20px;
}
@media only screen and (max-width: 900px){
  .shivalay{
    flex-flow: column-reverse !important;
  }
  .member-box figcaption{
    bottom: 140px;
  }
  .navbar{
    padding: 0px 20px 0 !important;
    position: initial !important;
    height: initial !important;
    margin: 15px 0 17px;
  }

  .navbar .logo{
    padding: initial;
  }
  .navbar .logo a img{
    height: 65px;
  }

  .component-systemTabs .cards .card .cardContent h2{
    font-size: 18px;
  }
  .component-systemTabs .cards .card .cardContent button{
    height: 25px;
    width: 100px;
    font-size: 13px;
    font-weight: 400;
  }

  .servi-mid{
    padding: 0 25px !important;
  }
  .tablo--1-ve-3.wow.fadeInRight {
    margin-bottom: 37%;
}
.ozellika {
  padding: 0 0 37%;
}
.component-systemTabs .cards .card .cardContent button {
  opacity: 1;
  }

  .toparrow{
    bottom: 5%;
    right: 10%;
  }
  .headmarg{
    margin-top: initial;
    height: 42vh;
  }
  .awssld{
    height: 40vh !important;
  }
  .awssld__timer {
    visibility: hidden !important;
  }

  /* service */
  .tablo{
    padding: 5% 5rem 20rem;
  }
  .h2-baslik-hizmetler-2{
    margin-top: 18%;
  }
  .team-section{
    padding: 0 0 0;
  }

}



@media only screen and (max-width: 767px){

.row{
  width: 100% !important;
  padding-left: 2%;
}
.footer{
  padding: 33px;
}
header.page-header.wow.fadeInUp{
  margin-top: initial !important;
}
/* home */
.projeler {
  padding: 0 0 16rem;
}
.home-detail{
  padding-bottom: 25%;
}
/* about su */
.ozellika {
  padding: 0 0 37%;
  margin: 23% 0 0;
}



/* srevice */
.about-card-head{
  display: none;
}
.paketler4__pr {
  padding: 0 4rem 4rem 4rem;
}
.tabloozellik {
  padding: 20rem 1rem 0;
}
/* project */
.shivalay-map-main{
  text-align: center;
}
.shivalay-map{
  width: 300px;
  height: 300px;
}
.architectural-data{
  padding-top: 40%;
}
.gallery-pro{
  font-size: 20px;
}

/* contact */
.tablo--1-ve-3.wow.fadeInRight {
  margin-bottom: 0;
}
.tablo {
  padding: 5% 5rem 0rem;
}
}

/* team head section */
.team-head-grid{
  padding: 20px;
}
.team-head-shbox{
  overflow: hidden;
  border-radius: 15px;
  width: 100%;
  transition: all .4s;
}
.team-head-shbox img{
  width: 100%;
  transition: all .5s;
}
.team-head-sh:hover{
  scale: 1.1;
  filter: brightness(.5);
}
.team-desc-sh {
  padding: 9%;
}


/* shivalaya  */
.shivalay-heights-main > .row{
  margin-top: 5%;
}
.shivalay-heights-main > .row:nth-child(1){
  margin-top: 1%;
}
.shivalay-heights-main > .row:last-child{
  margin-bottom: 3%;
}
.shivalay-details{
  color: #616161;
}
.founder_title{
  margin-top: initial;
}
.specific{
  padding-top: 0;
}